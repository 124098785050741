<div class="register-container">
  <div class="register-content card-white-shadow">
    <div class="register-header">
      <div style="margin-bottom: 24px">
        <div class="logo">
          <img src="../../../assets/img/icon.png" style="height: 64px" alt="casso logo png" />
        </div>
        <div class="logo">
          <img src="../../../assets/img/casso-logo-new.png" style="width: 64px" />
        </div>
      </div>

      <ng-container *ngIf="isPhoneNumber; then phoneNumberTmpl; else registerTmpl"></ng-container>
    </div>
  </div>

  <ng-template #registerTmpl>
    <div class="w-100 btn-login">
      <button
        style="background-color: #4285f4"
        type="button"
        mat-stroked-button
        (click)="signupWithGoogle()"
      >
        <img src="../../../assets/img/google.jpg" style="width: 26px" alt="" />
        <span style="margin-left: 16px; color: #fff"> Đăng ký bằng Google</span>
      </button>
    </div>
    <div class="w-100 btn-login" style="margin-top: 16px; margin-bottom: 16px">
      <button
        style="background-color: #2f2f2f"
        type="button"
        mat-stroked-button
        (click)="signupWithMicrosoft()"
      >
        <img src="../../../assets/img/microsoft.png" width="26px" alt="" />
        <span style="margin-left: 16px; color: #fff">Đăng ký bằng Microsoft</span>
      </button>
    </div>
    <div class="w-100 btn-login">
      <button type="button" mat-stroked-button (click)="signupWithEmail()">
        <img src="../../../assets/img/email.png" width="26px" alt="" />
        <span style="margin-left: 16px">Đăng ký bằng Email</span>
      </button>
    </div>
    <div style="text-align: center; margin-top: 32px">
      <span
        >Bằng việc đăng ký tài khoản, tôi chấp nhận
        <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
          >Chính sách bảo mật</a
        >
        và
        <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
          >Thỏa thuận sử dụng</a
        >
        của Casso</span
      >
    </div>
    <div class="re-login">
      <span>Bạn đã có tài khoản? </span>
      <a routerLink="/login">
        <span>Đăng nhập</span>
      </a>
    </div>
  </ng-template>

  <ng-template #phoneNumberTmpl>
    <div style="text-align: center; padding-bottom: 8px">
      Để hoàn tất việc đăng ký, vui lòng nhập số điện thoại và bấm Tiếp tục.
    </div>

    <form class="form-phone-number" [formGroup]="phoneForm">
      <mat-form-field appearance="outline">
        <mat-label>Số điện thoại</mat-label>
        <input
          formControlName="phone"
          matInput
          autocomplete="phone"
          (keypress)="keyPress($event)"
        />
        <mat-error *ngIf="phoneForm.get('phone').hasError('required')">
          Vui lòng nhập số điện thoại.
        </mat-error>
        <mat-error *ngIf="phoneForm.get('phone').hasError('pattern')">
          Số điện thoại không chính xác.
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Mã giới thiệu (không bắt buộc)</mat-label>
        <input formControlName="ref" matInput autocomplete="ref" />
      </mat-form-field>

      <br />
      <div class="action">
        <button class="skip" type="button" (click)="gotoLogin()" mat-button style="color: #15ab64">
          Về trang đăng nhập
        </button>
        <button
          class="submit-phone"
          [disabled]="phoneForm.invalid || shouldDisplaySpinner"
          type="button"
          (click)="nextStepRegister()"
          mat-raised-button
        >
          Tiếp tục
          <mat-spinner
            class="text-white"
            *ngIf="shouldDisplaySpinner"
            [diameter]="30"
          ></mat-spinner>
        </button>
      </div>
    </form>
  </ng-template>
</div>
