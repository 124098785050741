import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormFieldSchema, FormSchema } from '../models/bank-acc';

@Injectable({
  providedIn: 'root'
})
export class BankAccService {
  constructor() {}

  authFormSchema: FormSchema = {
    default: [
      {
        dataTypeName: 'text',
        attributeName: 'bank_username',
        attributeLabel: 'Tên đăng nhập',
        valueDefault: '',
        autocomplete: 'new-username',
        required: true,
        validators: []
      },
      {
        dataTypeName: 'password',
        attributeName: 'bank_password',
        attributeLabel: 'Mật khẩu',
        valueDefault: '',
        required: true,
        autocomplete: 'new-password',
        validators: []
      }
    ],
    vietinbank: [
      {
        dataTypeName: 'text',
        attributeName: 'customerName',
        attributeLabel: 'Họ và tên',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        dataTypeName: 'text',
        attributeName: 'idNumber',
        attributeLabel: 'CMND/CCCD hoặc MST/GPKD',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        dataTypeName: 'text',
        attributeName: 'mobileNumber',
        attributeLabel: 'Số điện thoại',
        valueDefault: '',
        required: true,
        validators: [Validators.pattern('[0-9]{10}')]
      },
      {
        dataTypeName: 'text',
        attributeName: 'accountNumber',
        attributeLabel: 'Số tài khoản',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        formType: 'check',
        dataTypeName: 'checkbox',
        attributeName: 'acceptCancelSMS',
        attributeLabel: 'Tôi đồng ý hủy dịch vụ BDSD VietinBank qua SMS',
        valueDefault: false,
        required: true,
        validators: []
      }
    ],
    bankOfficialSupportBankhub:  [
      {
        dataTypeName: 'text',
        attributeName: 'customerName',
        attributeLabel: 'Họ và tên',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        dataTypeName: 'text',
        attributeName: 'idNumber',
        attributeLabel: 'CMND/CCCD hoặc MST/GPKD',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        dataTypeName: 'text',
        attributeName: 'mobileNumber',
        attributeLabel: 'Số điện thoại',
        valueDefault: '',
        required: true,
        validators: [Validators.pattern('[0-9]{10}')]
      },
      {
        dataTypeName: 'text',
        attributeName: 'accountNumber',
        attributeLabel: 'Số tài khoản',
        valueDefault: '',
        required: true,
        validators: []
      },
      {
        formType: 'check',
        dataTypeName: 'checkbox',
        attributeName: 'acceptCancelSMS',
        attributeLabel: 'Tôi đồng ý hủy dịch vụ BDSD VietinBank qua SMS',
        valueDefault: false,
        required: true,
        validators: []
      }
    ]
  };
  taxCodeFormFieldSchema: FormFieldSchema = {
    dataTypeName: 'text',
    attributeName: 'tax_code',
    attributeLabel: 'MST doanh nghiệp',
    valueDefault: '',
    autocomplete: 'tax_code',
    required: false,
    validators: []
  };
  phoneNumberFromFieldSchema: FormFieldSchema = {
    dataTypeName: 'text',
    attributeName: 'phone_number',
    attributeLabel: 'Số điện thoại đăng ký dịch vụ',
    valueDefault: '',
    autocomplete: 'phone_number',
    required: false,
    validators: []
  };
  corporateIdFormFieldSchema: FormFieldSchema = {
    dataTypeName: 'text',
    attributeName: 'corporate_id',
    attributeLabel: 'Mã doanh nghiệp',
    valueDefault: '',
    autocomplete: 'corporate_id',
    required: false,
    validators: []
  };

  insertItemByPositon(arr, index, newItem) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index)
    ];
  }
}
