import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Column, ColumnVisibleEvent, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  TRANSACTION_COLUMNS_UNVISIBLE_CACHE_NAME,
  TRANSACTION_HIDE_COLUMNS_DEFAULT
} from 'src/app/modules/core/constant';
import { UserService } from 'src/app/modules/core/services/user.service';
import { TokenExpiredComponent } from '../../../../../shared/components/token-expired/token-expired.component';
import { StatusTransaction, Transaction } from '../../../../core/models/business';
import { BusinessService } from '../../../../core/services/business.service';
import { TransactionGridEditorComponent } from '../transaction-grid-editor/transaction-grid-editor.component';
import { TransactionViewerComponent } from '../transaction-viewer/transaction-viewer.component';

@Component({
  selector: 'app-transaction-grid',
  templateUrl: './transaction-grid.component.html',
  styleUrls: ['./transaction-grid.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TransactionGridComponent implements OnInit {
  @Input() businessId: number;
  @Input() filters: Object;
  @Input() transactions: Transaction[];
  @Input() newTransactions: Transaction[];
  @Input() limit: number;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() fromAmount: number;
  @Input() toAmount: number;
  @Input() statuses: object;
  @Input() bankErrors: object;
  @Input() page: number;
  @Input() dataSource: MatTableDataSource<Transaction>;
  @Input() bank_acc_id: string;
  @Input() eventStatus: string;
  @Input() titleGrid: string;
  @Input() isFilter: boolean;
  hideColumns: string[];

  statusTransId: StatusTransaction;
  isMobile: boolean;

  public gridApi;
  gridColumnApi: any;
  rowData: Transaction[] = [];
  gridEditorDialogRef: MatDialogRef<TransactionGridEditorComponent>;
  filterText = '';
  gridOptions: GridOptions;

  @Output() pageEvent = new EventEmitter<number>();
  currentPositionGrid: number;
  pinnedBottomRowData;
  statusBar;
  expandedRow: Transaction;

  constructor(
    private businessService: BusinessService,
    private userService: UserService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private tokenExpiredDialog: TokenExpiredComponent,
    private dialog: MatDialog
  ) {
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'avg', 'min', 'max']
          },
          align: 'center'
        }
      ]
    };

    this.gridOptions = {
      sideBar: {
        toolPanels: [
          {
            id: 'id',
            labelDefault: 'Danh sách cột',
            labelKey: 'labelKey',
            iconKey: 'iconKey',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true,
              suppressPivots: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true
            }
          }
        ]
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        editable: false,
        filter: true,
        enablePivot: false
      },
      onRowDataChanged: (event) => {
        if (this.gridApi) {
          this.filterChanged(event);
        }
      }
    };
  }

  ngOnInit() {
    this.statuses =
      this.filters != null && this.filters['statuses'] != null ? this.filters['statuses'] : null;

    if ((this.bankErrors && this.bank_acc_id) || this.statuses) {
      this.businessService
        .loadTransactions(
          this.businessId,
          this.fromDate,
          this.toDate,
          this.fromAmount,
          this.toAmount,
          this.statuses,
          this.bankErrors,
          this.page,
          this.bank_acc_id,
          this.eventStatus
        )
        .subscribe((res) => {
          if (res.error == 0) {
            this.dataSource = new MatTableDataSource(res.data.slice(0, this.limit));

            this.transactions = res.data;
            this.rowData = res.data;

            this.setStatusTransactionSource(true);

            const totalAmount = this.customAggFuncSum(
              this.transactions.map((transaction) => transaction.amount)
            );
            this.pinnedBottomRowData = this.createTransactionAtBottom(
              this.transactions.length,
              totalAmount
            );
          }
        });
    }
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'newTransactions': {
            if (this.gridApi) {
              const res = this.gridApi.applyTransaction({ add: this.newTransactions });
              this.printResult(res);
            }
            break;
          }
          case 'transactions': {
            if (changes['transactions'].previousValue)
              this.currentPositionGrid = changes['transactions'].previousValue.length;

            if (!this.dataSource) {
              this.dataSource = new MatTableDataSource([]);
            }
            if (changes['transactions'].firstChange || this.page === 0) {
              this.rowData = this.transactions;
            }

            this.dataSource.data = this.transactions;
            const totalAmount = this.customAggFuncSum(
              this.transactions.map((transaction) => transaction.amount)
            );
            this.pinnedBottomRowData = this.createTransactionAtBottom(
              this.transactions.length,
              totalAmount
            );

            this.setStatusTransactionSource(true);
          }
        }
      }
    }
  }

  transactionsFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  normalizeText(txt: string) {
    if (this.isMobile == true) {
      var MAX_LENGTH = 22;
      return txt.substr(0, MAX_LENGTH) + (txt.length > MAX_LENGTH ? '...' : '');
    } else {
      var MAX_LENGTH = 60;
      return txt.substr(0, MAX_LENGTH) + (txt.length > MAX_LENGTH ? '...' : '');
    }
  }

  clickRow(row: Transaction) {
    if (this.expandedRow == row) {
      this.expandedRow = null;
      return;
    }
    this.expandedRow = row;
  }

  getTotalAmount() {
    return this.dataSource.filteredData.reduce(
      (summ, trans) => summ + parseInt(trans.amount.toString()),
      0
    );
  }

  public columnDefs = [
    {
      headerName: 'Thao tác',
      field: 'status',
      width: 50,
      minWidth: 90,
      maxWidth: 110,
      cellStyle: {
        textAlign: 'center'
      },
      cellRenderer: 'agGroupCellRenderer',
      cellEditorFramework: TransactionViewerComponent,
      cellRendererParams: {
        innerRendererFramework: TransactionViewerComponent
      }
    },
    {
      headerName: 'Ngày diễn ra',
      field: 'when',
      cellStyle: {
        justifyContent: 'flex-end'
      },
      width: 70,
      valueFormatter: this.formatDate
    },
    {
      headerName: 'Mã GD',
      field: 'id',
      cellStyle: {
        textAlign: 'right'
      },
      width: 50
    },
    {
      headerName: 'Mã tham chiếu',
      field: 'tid',
      width: 70
    },
    {
      headerName: 'Tài khoản',
      field: 'bank_sub_acc_id',
      width: 120
    },
    {
      headerName: 'Tài khoản ảo',
      field: 'virtual_account',
      width: 120
    },
    {
      headerName: 'Tên tài khoản ảo',
      field: 'virtual_account_name',
      width: 120
    },
    {
      headerName: 'Tài khoản đối ứng',
      field: 'corresponsive_account',
      width: 120
    },
    {
      headerName: 'Tên tài khoản đối ứng',
      field: 'corresponsive_name',
      width: 120
    },
    {
      headerName: 'Tên ngân hàng đối ứng',
      field: 'corresponsive_bank_name',
      width: 120
    },
    {
      headerName: 'Mô tả',
      field: 'description',
      cellStyle: {
        textAlign: 'left'
      },
      filter: true,
      filterParams: { newRowsAction: 'keep' },
      getQuickFilterText: function (params) {
        return params.value;
      },
      pinnedRowCellRenderer: (params) => {
        return '<b>Tổng</b> (' + params.value + ')';
      }
    },
    {
      headerName: 'Giá trị',
      field: 'amount',
      width: 90,
      cellStyle: {
        textAlign: 'right'
      },
      comparator: this.amountComparator,
      cellRenderer: this.colorAmountCellRenderer,
      pinnedRowCellRenderer: this.footerAmountCellRenderer
    },
    {
      headerName: 'Số dư',
      field: 'cusum_balance',
      sortable: true,
      width: 90,
      cellStyle: {
        textAlign: 'right'
      },
      comparator: this.amountComparator,
      cellRenderer: (params) => {
        if (!params.data.cusum_balance) {
          return '';
        }
        if (params.data.cusum_balance < 0) {
          return (
            "<b style='color: #f44336'>" +
            params.data.cusum_balance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
            '</b>'
          );
        } else {
          return (
            "<b style='color: #15ab64'>" +
            params.data.cusum_balance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
            '</b>'
          );
        }
      }
    },
    {
      headerName: 'Trạng thái',
      field: 'status',
      width: 50,
      cellStyle: {
        textAlign: 'center'
      },
      cellRenderer: this.displayStatusCellRenderer
    }
  ];

  tooltipValueGetterStatus(params) {
    return params.value === 1
      ? 'Đã nhập'
      : params.value === 0
      ? 'Chưa nhập'
      : params.value === 2
      ? 'Không khớp'
      : null;
  }

  displayStatusCellRenderer(params) {
    if (params.value === 1) {
      return `<mat-icon style='color: #15ab64' class="mat-icon material-icons" role="img" aria-hidden="true" color='primary' matListIcon>done</mat-icon>`;
    } else if (params.value === 2) {
      return `<mat-icon style='color: #f44336' class="mat-icon material-icons" role="img" aria-hidden="true" color='warn' matListIcon>error_outline</mat-icon>`;
    } else if (params.value === 0) {
      return `<mat-icon style='color: #f44336' class="mat-icon material-icons" role="img" aria-hidden="true" color='warn' matListIcon>add</mat-icon>`;
    } else {
      return '';
    }
  }

  footerAmountCellRenderer(params) {
    if (params.value < 0) {
      return (
        "<b style='color: #f44336'>" +
        params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</b>'
      );
    } else {
      return (
        "<b style='color: #15ab64'>" +
        params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</b>'
      );
    }
  }

  customAggFuncSum(values) {
    let sum = 0;
    values.forEach(function (value) {
      sum += parseInt(value);
    });
    return sum;
  }

  amountComparator(number1, number2) {
    if (number1 === null && number2 === null) {
      return 0;
    }
    if (number1 === null) {
      return -1;
    }
    if (number2 === null) {
      return 1;
    }
    return number1 - number2;
  }

  colorAmountCellRenderer(params) {
    if (params.data.amount < 0) {
      return (
        "<span style='color: lightcoral'>" +
        params.data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</span>'
      );
    } else {
      return (
        "<span style='color: lightseagreen'>" +
        params.data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</span>'
      );
    }
  }

  formatDate(params: any) {
    return params.value ? moment(params.value).format('DD/MM/YYYY  hh:mm A') : null;
  }

  setStatusTransactionSource(isMobile: boolean) {
    this.businessService.currentTransactionIdStatus.subscribe((statusOfTransId) => {
      if (statusOfTransId !== null) {
        this.statusTransId = statusOfTransId;

        if (isMobile) {
          this.dataSource.data.map((transaction) => {
            if (transaction.id == this.statusTransId.transId) {
              transaction.status = this.statusTransId.status;
            }
          });
        } else {
          const itemsToUpdate = [];
          this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
            if (rowNode.id == this.statusTransId.transId) {
              const data = rowNode.data;
              data.status = this.statusTransId.status;
              itemsToUpdate.push(data);
            }
          });
          const res = this.gridApi.applyTransaction({ update: itemsToUpdate });
          this.printResult(res);
        }
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.component_context = this;
    this.setStatusTransactionSource(false);
    this.gridColumnApi.setColumnsVisible(this.getColumnsUnvisible(), false);
    params.api.sizeColumnsToFit();
  }

  getColumnsUnvisible(): string[] {
    let columns: string[] = [];
    if (this.userService.getTypeStorage(TRANSACTION_COLUMNS_UNVISIBLE_CACHE_NAME)) {
      columns = this.userService
        .getTypeStorage(TRANSACTION_COLUMNS_UNVISIBLE_CACHE_NAME)
        .split(',');
    } else {
      columns = TRANSACTION_HIDE_COLUMNS_DEFAULT;
    }

    return [...new Set(columns)];
  }

  onColumnVisible(event: ColumnVisibleEvent) {
    const allColumns: Column[] = this.gridColumnApi.getAllColumns();
    const columns = allColumns
      .filter((col) => !col.isVisible())
      .map((col) => col.getColId())
      .join(',');
    this.userService.setTypeStorage(TRANSACTION_COLUMNS_UNVISIBLE_CACHE_NAME, columns);
  }

  printResult(res) {
    if (res.add) {
      res.add;
    }
    if (res.remove) {
      res.remove;
    }
    if (res.update) {
      res.update;
    }
  }

  onFilterTextBoxChanged() {
    if (this.isMobile == false) {
      setTimeout(() => {
        this.gridApi.setQuickFilter(this.filterText);
      }, 0);
    }
  }

  getContextMenuItems(params) {
    function customParams() {
      return {
        skipHeaders: true,
        processCellCallback: function (cellParams) {
          if (cellParams && cellParams.column.colId === 'when') {
            return moment(cellParams.value).format('YYYY-MM-DD HH:mm');
          } else return cellParams.value;
        }
      };
    }
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'toolPanel',
      {
        name: 'Export',
        subMenu: [
          {
            name: 'Excel Export',
            action: () => {
              params.api.exportDataAsExcel(customParams());
            }
          },
          {
            name: 'CSV Export',
            action: () => {
              params.api.exportDataAsCsv(customParams());
            }
          }
        ]
      }
    ];
  }

  handleScrollAggrid(event) {
    const grid = document.getElementById('transAggrid');

    if (grid) {
      const gridBody = grid.querySelector('.ag-body-viewport') as any;

      if (gridBody.scrollHeight - gridBody.scrollTop === gridBody.clientHeight) {
        setTimeout(() => {
          this.page += 1;
          this.pageEvent.emit(this.page);
        }, 800);
      }
    }
  }

  filterChanged(event) {
    let sum = 0,
      nodes = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      nodes.push(node);
      sum += Number(node.data.amount);
    });
    this.pinnedBottomRowData = this.createTransactionAtBottom(nodes.length, sum);
  }

  createTransactionAtBottom(total, totalAmount) {
    return [
      {
        when: null,
        description: total,
        amount: totalAmount,
        status: null
      }
    ];
  }
}
