import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'titleCaseWord'
})
export class TitleCaseWordPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const first = value.substr(0, 1).toUpperCase();
    return first + value.substr(1);
  }
}
