import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

//app
import { UserService } from '../modules/core/services/user.service';
import { BusinessService } from '../modules/core/services/business.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AgGridMaterialDatepickerEditorComponent } from '../shared/components/aggrid/ag-grid-material-datepicker-editor/ag-grid-material-datepicker-editor.component';
import { TypeaheadEditorComponent } from './components/typeahead-editor/typeahead-editor.component';
import { TransactionViewerComponent } from '../modules/business/shared/components/transaction-viewer/transaction-viewer.component';
import { TransactionGridComponent } from '../modules/business/shared/components/transaction-grid/transaction-grid.component';
import { RuleListComponent } from '../modules/business/modules/rules/pages/rule-list/rule-list.component';
import { PredictPreviewComponent } from '../modules/business/modules/rules/pages/predict-preview/predict-preview.component';
import { RuleGridComponent } from '../modules/business/modules/rules/shared/components/rule-grid/rule-grid.component';
import { SidebarBussinessComponent } from './components/sidebar-bussiness/sidebar-bussiness.component';
import { SidebarPortfolioComponent } from './components/sidebar-portfolio/sidebar-portfolio.component';
import { TokenExpiredComponent } from './components/token-expired/token-expired.component';
import { BasicDialogComponent } from './components/basic-dialog/basic-dialog.component';
import { CongratsDialogComponent } from './components/congrats-dialog/congrats-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

import { BankAccIdPipe } from './pipes/bank-acc-id.pipe';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { QuarterPipe } from './pipes/quarter.pipe';
import { BankAccStatusPipe } from './pipes/bank-acc-status.pipe';
import { BankAccMemoPipe } from './pipes/bank-acc-memo.pipe';
import { TitleCaseWordPipe } from './pipes/title-case-word.pipe';
import { JsonPrettyPrintPipe } from './pipes/json-pretty-print.pipe';
import { FilterIsNullOrEqualPipe } from './pipes/filter-isnull-or-equal.pipe';
import { GetLinkGoBack } from './pipes/get-link-go-back.pipe';
import { TransactionEventStatusPipe } from './pipes/transaction-event-status.pipe';

//package
import { AgGridModule } from 'ag-grid-angular';
import { ReversePipe, NgPipesModule } from 'ngx-pipes';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxCurrencyModule } from 'ngx-currency';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

//Material
import { MaterialModule } from '../shared/modules/material.module';
import { SettingsService } from '../modules/core/services/settings.service';
import { BankAccService } from '../modules/core/services/bank-acc.service';

import { WindowService } from '../modules/core/services/window.service';
import { RemoveUnderscorePipe } from './pipes/remove_underscore.pipe';
import { BankAccSpeedPipe } from './pipes/bank-acc-speed.pipe';
import { BankAccSpeedColorPipe } from './pipes/bank-acc-speed-color.pipe';
import { NumberOfIntegrationPipe } from './pipes/numberOfIntegration.pipe';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true
};
@NgModule({
  declarations: [
    BreadcrumbComponent,
    TransactionGridComponent,
    RuleListComponent,
    PredictPreviewComponent,
    RuleGridComponent,
    AgGridMaterialDatepickerEditorComponent,
    SidebarBussinessComponent,
    SidebarPortfolioComponent,
    TypeaheadEditorComponent,
    TokenExpiredComponent,
    BasicDialogComponent,
    CongratsDialogComponent,
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    TransactionViewerComponent,

    BankAccIdPipe,
    TimeFromNowPipe,
    DurationPipe,
    QuarterPipe,
    BankAccStatusPipe,
    BankAccMemoPipe,
    TitleCaseWordPipe,
    JsonPrettyPrintPipe,
    FilterIsNullOrEqualPipe,
    GetLinkGoBack,
    RemoveUnderscorePipe,
    BankAccSpeedPipe,
    BankAccSpeedColorPipe,
    NumberOfIntegrationPipe,
    TransactionEventStatusPipe,

    CongratsDialogComponent,

    LoadingDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,

    //Material
    MaterialModule,

    AgGridModule.withComponents([
      AgGridMaterialDatepickerEditorComponent,
      TypeaheadEditorComponent,
      TransactionViewerComponent
    ]),
    NgPipesModule,
    NgApexchartsModule,
    NgSelectModule,
    DeviceDetectorModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    })
  ],
  exports: [
    BreadcrumbComponent,
    TransactionGridComponent,
    RuleListComponent,
    PredictPreviewComponent,
    RuleGridComponent,
    AgGridMaterialDatepickerEditorComponent,
    SidebarBussinessComponent,
    SidebarPortfolioComponent,
    TypeaheadEditorComponent,
    TokenExpiredComponent,
    BasicDialogComponent,
    CongratsDialogComponent,
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    TransactionViewerComponent,

    BankAccIdPipe,
    TimeFromNowPipe,
    DurationPipe,
    QuarterPipe,
    BankAccStatusPipe,
    BankAccMemoPipe,
    TitleCaseWordPipe,
    JsonPrettyPrintPipe,
    FilterIsNullOrEqualPipe,
    GetLinkGoBack,
    RemoveUnderscorePipe,
    BankAccSpeedPipe,
    BankAccSpeedColorPipe,
    NumberOfIntegrationPipe,
    TransactionEventStatusPipe,

    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,

    //Material
    MaterialModule,

    AgGridModule,
    NgPipesModule,
    NgApexchartsModule,
    TypeaheadModule,
    NgxCurrencyModule
  ],
  entryComponents: [TokenExpiredComponent, BasicDialogComponent, CongratsDialogComponent],
  providers: [TokenExpiredComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        UserService,
        BusinessService,
        ReversePipe,
        SettingsService,
        BankAccService,
        WindowService
      ]
    };
  }
}
