<mat-nav-list
  [ngClass]="'background-color-brand'"
  class="sidenav-content"
  [ngStyle]="{ 'height: 100%': isMobile }"
>
  <mat-accordion displayMode="flat" class="overflow-y-scroll">
    <div *ngFor="let menu of menuList; let i = index; first as isFirst">
      <a
        mat-list-item
        disableRipple
        class="flex items-center"
        (click)="closeSideNav()"
        [routerLink]="['/business', businessId, menu.route]"
        [routerLinkActive]="['active']"
        (mousedown)="$event.preventDefault()"
        #rla="routerLinkActive"
        [matTooltip]="isExpandedSideNav ? '' : menu.title"
        [matTooltipPosition]="'right'"
        [ngClass]="{ 'd-flex-column-center': !isExpandedSideNav }"
      >
        <mat-icon
          *ngIf="menu.name != 'rule' && menu.name != 'books'"
          [ngClass]="{ '!text-current': menuSelectedIndex == i }"
          >{{ menu.icon }}</mat-icon
        >
        <mat-icon
          *ngIf="menu.name == 'rule'"
          [ngClass]="{ '!text-current': menuSelectedIndex == i }"
          svgIcon="automation"
        ></mat-icon>
        <svg
          *ngIf="menu.name == 'books'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1431 21.1426L12.0003 21.1426L12.0003 11.9998L21.1431 11.9998L21.1431 21.1426Z"
            [attr.fill]="menuSelectedIndex == i ? '#84CFAC' : '#CFCFCF'"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.999 20.9998C21.999 21.552 21.5513 21.9998 20.999 21.9998L11.142 21.9998L11.142 11.1427L21.999 11.1427L21.999 20.9998ZM20.2848 20.2855L20.2848 12.857L12.8563 12.857L12.8563 20.2855L20.2848 20.2855Z"
            [attr.fill]="menuSelectedIndex == i ? '#15AB65' : '#707070'"
          />
          <path
            d="M21.1431 11.9998L12.0003 11.9998L12.0003 2.857L21.1431 2.85699L21.1431 11.9998Z"
            [attr.fill]="menuSelectedIndex == i ? '#84CFAC' : '#CFCFCF'"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.999 12.8569L11.142 12.8569L11.142 1.99991L20.999 1.9999C21.5513 1.9999 21.999 2.44762 21.999 2.99991L21.999 12.8569ZM20.2848 11.1427L20.2848 3.71417L12.8563 3.71417L12.8563 11.1427L20.2848 11.1427Z"
            [attr.fill]="menuSelectedIndex == i ? '#15AB65' : '#707070'"
          />
          <path
            d="M11.9995 21.1426L2.85675 21.1426L2.85675 11.9998L11.9995 11.9998L11.9995 21.1426Z"
            [attr.fill]="menuSelectedIndex == i ? '#84CFAC' : '#CFCFCF'"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8569 21.9998L2.9999 21.9998C2.44762 21.9998 1.9999 21.552 1.9999 20.9998L1.9999 11.1427L12.8569 11.1427L12.8569 21.9998ZM11.1427 20.2855L11.1427 12.857L3.71417 12.857L3.71417 20.2855L11.1427 20.2855Z"
            [attr.fill]="menuSelectedIndex == i ? '#15AB65' : '#707070'"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.00042 8.57129L2.00042 2.99993C2.00042 2.44765 2.44813 1.99993 3.00042 1.99993L8.57178 1.99993L8.57178 3.7142L3.71469 3.7142L3.71469 8.57129L2.00042 8.57129Z"
            [attr.fill]="menuSelectedIndex == i ? '#15AB65' : '#707070'"
          />
        </svg>
        <p
          *ngIf="isExpandedSideNav"
          class="text-sm font-normal text-gray-700 pl-2"
          [ngClass]="{ '!text-current': menuSelectedIndex == i }"
        >
          {{ menu.title }}
        </p>
      </a>
    </div>
  </mat-accordion>

  <div class="sidenav-bottom" style="margin-top: auto" *ngIf="isMobile">
    <!-- <div *ngIf="business && isExpandedSideNav" class="usage px-5 py-5">
      <ng-container *ngTemplateOutlet="usage"></ng-container>
    </div>
    <div
      *ngIf="business && !isExpandedSideNav"
      class="flex items-center justify-center px-5 py-5"
    >
      <ng-container
        *ngTemplateOutlet="
          usageProgress;
          context: { value: business.limits.transactions.percent }
        "
      ></ng-container>
    </div> -->

    <a mat-list-item (click)="onProfileMenu()" id="profile-menu" class="mb-4">
      <div class="business-brand" matListAvatar>
        <span *ngIf="!businessLogo">{{ businessName | slice : 0 : 1 }}</span>
        <img *ngIf="businessLogo" [src]="businessLogo" alt="Business logo" style="height: 32px" />
      </div>
      <span
        *ngIf="isExpandedSideNav"
        class="text-sm pl-3"
        [matTooltip]="businessName && businessName.length > 16 ? businessName : ''"
        [matTooltipPosition]="'above'"
      >
        {{
          businessName && businessName.length > 16
            ? (businessName | slice : 0 : 16) + '...'
            : businessName
        }}
      </span>
      <div
        style="visibility: hidden; position: fixed"
        [style.left]="profiletMenuPosition.x"
        [style.top]="profiletMenuPosition.y"
        [matMenuTriggerFor]="profileMenu"
      >
        <mat-menu #profileMenu="matMenu">
          <div class="business-name">{{ businessName }}</div>
          <button #profileButton mat-menu-item (click)="gotoProfile()">Hồ sơ</button>
          <button mat-menu-item (click)="gotoPortfolio(); closeSideNav()">
            Chuyển doanh nghiệp
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout.emit(true); closeSideNav()">
            <mat-icon style="color: #000000 !important; opacity: 0.6" matListIcon>lock</mat-icon>
            Đăng xuất
          </button>
        </mat-menu>
      </div>
    </a>
  </div>
</mat-nav-list>

<ng-template #usage>
  <div class="flex flex-row items-center text-base font-semibold text-gray-700">
    <mat-icon class="pr-4">account_balance_wallet</mat-icon>
    Gói {{ business.plan.name }}
  </div>

  <div class="my-2">
    <div class="flex flex-row items-center justify-between text-xs pb-1">
      <span>Giao dịch</span>
      <span
        >{{ business.limits.transactions.count }} / {{ business.limits.transactions.limit }}</span
      >
    </div>
    <mat-progress-bar
      mode="determinate"
      [value]="business.limits.transactions.percent"
    ></mat-progress-bar>

    <div class="flex flex-row items-center justify-between text-xs pt-2 pb-1">
      <span>Ngân hàng</span>
      <span
        >{{ business.limits.bank_accounts.count }} / {{ business.limits.bank_accounts.limit }}</span
      >
    </div>
    <mat-progress-bar
      mode="determinate"
      [value]="business.limits.bank_accounts.percent"
    ></mat-progress-bar>
  </div>

  <div class="flex justify-between text-[10px]">
    Hết hạn trong {{ business.usage.to_date | timeFromNow }}
    <a
      [routerLink]="['/business', business.id, 'usage-and-billing']"
      class="!text-current no-underline font-semibold"
      >Chi tiết gói</a
    >
  </div>

  <!-- Show button when plan is Free or Trial -->
  <div *ngIf="business.plan.name == 'Free' || business.plan.name == 'Trial'" class="flex pt-4">
    <button
      mat-stroked-button
      [routerLink]="['/business', business.id, 'usage-and-billing', 'plans']"
      class="hover:bg-current hover:text-white w-full"
    >
      Nâng cấp
    </button>
  </div>
</ng-template>

<ng-template #usageProgress let-value="value">
  <div
    class="usage-transaction-progress"
    [routerLink]="['/business', business.id, 'usage-and-billing']"
    [matTooltip]="(value | number) + '% số lượng giao dịch đã được sử dụng'"
    [matTooltipPosition]="'above'"
  >
    <mat-progress-spinner
      class="base-progress-spinner"
      mode="determinate"
      diameter="32"
      color="warn"
      [value]="100"
      strokeWidth="5"
    >
    </mat-progress-spinner>
    <mat-progress-spinner
      mode="determinate"
      [ngClass]="{
        'main-progress-spinner': value < 65,
        'main-progress-spinner-credit-lower': value >= 65 && value < 85,
        'main-progress-spinner-over-limit': value >= 85
      }"
      diameter="32"
      [value]="value"
      strokeWidth="5"
    >
    </mat-progress-spinner>
  </div>
</ng-template>
