import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProcessCellForExportParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AgGridMaterialDatepickerEditorComponent } from '../../../../../shared/components/aggrid/ag-grid-material-datepicker-editor/ag-grid-material-datepicker-editor.component';
import { Business, StatusTransaction, Transaction } from '../../../../core/models/business';
import { BusinessService } from '../../../../core/services/business.service';
import { BankAcc } from '../../../../core/models/business';

@Component({
  selector: 'app-transaction-grid-editor',
  templateUrl: './transaction-grid-editor.component.html',
  styleUrls: ['./transaction-grid-editor.component.css']
})
export class TransactionGridEditorComponent implements OnInit {
  form: FormGroup;
  businessId: any;
  transactionId: number;
  transaction_id: number;
  bank_sub_acc_id: string = null;

  id: number;
  transaction: Transaction;
  transactionError = 0;
  amountTransaction: number;
  totalAmount: number;
  bankAccId: any;
  rowIndex: number;
  statusTransaction: StatusTransaction;
  featuresOfBusiness: Business;
  isPartner: boolean;
  isSubtype: boolean;
  isOrderSale: boolean;

  public gridApi;
  gridColumnApi: any;
  gridOptions;
  rowSelected: any;
  bankInfor: BankAcc;

  private _subscription$: Array<Subscription> = [];

  constructor(
    private businessService: BusinessService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TransactionGridEditorComponent>
  ) {
    this.gridOptions = {
      processCellFromClipboard: (params: ProcessCellForExportParams) => {
        if (params.column.getColId() === 'amount' && typeof params.value === 'string') {
          return Number(params.value.replace(/,/g, ''));
        }
        return params.value;
      }
    };
  }

  columnDefs = [
    {
      headerName: 'Ngày diễn ra',
      field: 'happen_date',
      editable: true,
      cellStyle: {
        textAlign: 'left'
      },
      width: 90,
      suppressMenu: true,
      cellEditorFramework: AgGridMaterialDatepickerEditorComponent,
      valueFormatter: (data) => (data.value ? moment(data.value).format('DD/MM/YYYY') : null)
    },

    {
      headerName: 'Mô tả',
      field: 'description',
      editable: true,
      // width: 200,
      cellStyle: {
        textAlign: 'left'
      },
      suppressMenu: true
    },

    {
      headerName: 'Loại',
      field: 'type',
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      width: 90,
      cellEditorParams: {
        cellRenderer: this.typeCellRenderer,
        values: [101, 102, 103, 104, 105]
      },
      cellRenderer: this.typeCellRenderer,
      cellStyle: {
        textAlign: 'left'
      },
      suppressMenu: true
    },

    //copy from bussiness-financial-record : 116
    {
      headerName: 'Danh mục',
      field: 'sub_type',
      editable: true,
      width: 110,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: function (params) {
        const selectedType = params.data.type;
        // console.log(params);
        const this_component = params.api.component_context;
        const availableSubtypes = this_component.getAvailableSubTypesInEditor(selectedType);
        return {
          values: availableSubtypes
        };
      },
      cellStyle: {
        textAlign: 'left'
      },
      suppressMenu: true
    },

    {
      headerName: 'Đối tác',
      field: 'partner_code',
      editable: true,
      width: 110,
      cellStyle: {
        textAlign: 'left'
      },
      suppressMenu: true
    },
    {
      headerName: 'Mã đơn hàng',
      field: 'order_sale_id',
      editable: true,
      width: 110,
      cellStyle: {
        textAlign: 'left'
      },
      suppressMenu: true
    },

    {
      headerName: 'Giá trị',
      field: 'amount',
      editable: true,
      width: 90,
      cellStyle: {
        textAlign: 'right'
      },
      cellRenderer: this.colorAmountCellRenderer,
      suppressMenu: true
    }
  ];

  colorAmountCellRenderer(params) {
    if (typeof params.data.amount === 'string') {
      params.data.amount = Number(params.data.amount.replace(/,/g, ''));
    }

    const regexNumber = /^-?(0|[1-9]\d*)?$/;
    //TODO: prevent call api update value when wrong value not update value default
    if (regexNumber.test(params.data.amount)) {
      if (params.data.amount < 0) {
        return (
          "<span style='color: lightcoral'>" +
          params.data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
          '</span>'
        );
      } else {
        return (
          "<span style='color: lightseagreen'>" +
          params.data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
          '</span>'
        );
      }
    } else {
      return (params.data.amount = 0);
    }
  }

  formatCurrency(amount: any) {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  typeCellRenderer(params) {
    const valType = params.value;
    if (valType === 101) {
      return 'Doanh thu';
    }
    if (valType === 102) {
      return 'Chi phí';
    }
    if (valType === 103) {
      return 'Ghi nợ';
    }
    if (valType === 104) {
      return 'Nhận góp vốn';
    }
    if (valType === 105) {
      return 'Chia cổ tức';
    } else {
      return valType;
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.component_context = this;

    // console.log("Features of Business", this.featuresOfBusiness);
    this.gridColumnApi.setColumnVisible('partner_code', this.isPartner);
    this.gridColumnApi.setColumnVisible('sub_type', this.isSubtype);
    this.gridColumnApi.setColumnVisible('order_sale_id', this.isOrderSale);
    params.api.sizeColumnsToFit();
  }
  rowData = [];

  ngOnInit() {
    this.form = this.formBuilder.group({
      filename: ''
    });
    this.getTransactionId();
    // this.getTransaction();

    this.businessId = this.businessService.currentBussiness.id;
    this.businessService
      .httpGetTransactionRecords(this.businessId, this.transactionId)
      .subscribe((res) => {
        this.rowData = res.data.financials;
        this.transaction = res.data.transaction;
        this.transactionError = this.transaction.error;
        this.amountTransaction = +res.data.transaction.amount;

        this.businessService.getAllBankAcc.subscribe((bankAccs) => {
          this.bankInfor = bankAccs.find((bankAcc) => bankAcc.id === this.transaction.bank_acc_id);
        });

        if (res.data.transaction['bank_sub_acc_id'])
          this.bank_sub_acc_id = res.data.transaction['bank_sub_acc_id'];

        this.totalAmount = 0;
        for (let i = 0; i < this.rowData.length; i++) {
          this.totalAmount += Number(this.rowData[i].amount);
        }
      });
    this.getStatusFeatures();
  }

  onCellValueFinChanged(event: any) {
    const row = event.data;
    // console.log("-------- change -----", row, event)
    if (event.colDef.field === 'amount' && event.newValue !== event.oldValue) {
      this.calcTotalAmountRecord();
    }

    if (event.data.id != null && event.newValue !== event.oldValue) {
      //UPDATE ROW
      const partner_code =
        row.partner_code === null
          ? null
          : row.partner_code.trim().length > 0
          ? row.partner_code.trim()
          : null;
      const order_sale_id =
        row.order_sale_id === null
          ? null
          : row.order_sale_id.trim().length > 0
          ? row.order_sale_id.trim()
          : null;
      const sub_type =
        row.sub_type === null ? null : row.sub_type.trim().length > 0 ? row.sub_type.trim() : null;
      this.businessService
        .updateFinancialRecord(
          row.happen_date,
          row.amount,
          row.description,
          row.type,
          sub_type,
          partner_code,
          order_sale_id,
          row.id,
          this.businessId
        )
        .subscribe((res) => {
          if (res.error == 0) {
            // console.log("update success");
            this.statusTransaction = {
              status: res.data.transaction.status,
              transId: res.data.transaction.id
            };

            // console.log(this.statusTransaction);
            this.businessService.setStatusOfTransaction(this.statusTransaction);
            this.calcTotalAmountRecord();
            this.businessService.updateValueEachFieldRecord(
              event.data.id,
              event.colDef.field,
              row[event.colDef.field]
            );
          }
        });
    }
  }

  onRowClicked(event: any) {
    if (event.data.id !== undefined) {
      this.id = event.data.id;
    } else {
      // console.log("Row chua insert");
    }
    this.rowIndex = event.rowIndex;
    this.rowSelected = event.data;
  }

  insertRowFinRecord(
    businessId,
    transaction_id,
    happen_date,
    description,
    type,
    sub_type,
    partner_code,
    order_sale_id,
    amount,
    create_by,
    rowIndex
  ) {
    this.businessService
      .insertFinancialRecord(
        businessId,
        transaction_id,
        happen_date,
        description,
        type,
        sub_type,
        partner_code,
        order_sale_id,
        amount,
        create_by,
        this.bank_sub_acc_id
      )
      .subscribe((res) => {
        if (res.error == 0) {
          this.updateIdForRowInserted(rowIndex, res.data.crud.insertId);

          this.gridApi.forEachNode((row) => {
            if (row.data.id === res.data.crud.insertId) {
              this.businessService.addFinRecord([row.data], true);
            }
          });

          this.statusTransaction = {
            status: res.data.transaction.status,
            transId: res.data.transaction.id
          };
          this.businessService.setStatusOfTransaction(this.statusTransaction);
        } else {
          alert('Insert fail');
        }
      });
  }

  deleteRowFinRecord() {
    if (this.id !== null) {
      this.businessService.deleteFinancialRecord(this.businessId, this.id).subscribe((res) => {
        if (res.error == 0) {
          const removeRow = this.gridApi.applyTransaction({ remove: [this.rowSelected] });
          this.printResult(removeRow);

          this.calcTotalAmountRecord();

          this.statusTransaction = {
            status: res.data.transaction.status,
            transId: res.data.transaction.id
          };
          this.businessService.setStatusOfTransaction(this.statusTransaction);
          this.businessService.removeFinRecord(this.id);
        } else {
          alert('Delete fail');
        }
      });
    } else {
      const removeRow = this.gridApi.applyTransaction({ remove: [this.rowSelected] });
      this.printResult(removeRow);
      this.calcTotalAmountRecord();
    }
  }

  calcTotalAmountRecord() {
    let sum = 0;
    this.totalAmount = 0;
    this.gridApi.forEachNodeAfterFilter(function (node) {
      sum += Number(node.data.amount);
    });
    this.totalAmount = sum;
  }

  completeFinRecord() {
    this.gridApi.forEachNode((row) => {
      if (row.data.id == null) {
        const records = row.data;
        this.insertRowFinRecord(
          this.businessId,
          this.transactionId,
          records.happen_date,
          records.description,
          records.type,
          records.sub_type,
          records.partner_code,
          records.order_sale_id,
          records.amount,
          records.create_by,
          row.rowIndex
        );
      } else {
        const record = row.data;
        this.rowData[0] = record;
        this.businessService
          .updateFinancialRecord(
            record.happen_date,
            record.amount,
            record.description,
            record.type,
            record.sub_type,
            record.partner_code,
            record.order_sale_id,
            record.id,
            this.businessId
          )
          .subscribe((res) => {
            if (res.error == 0) {
              this.statusTransaction = {
                status: res.data.transaction.status,
                transId: res.data.transaction.id
              };
              this.businessService.setStatusOfTransaction(this.statusTransaction);
              this.calcTotalAmountRecord();
            }
          });
      }
    });

    this.dialogRef.close();
  }

  addRowFinRecord() {
    const newFinRecord = this.createNewRowFinRecord();
    this.transaction_id = this.transactionId;

    const res = this.gridApi.applyTransaction({ add: [newFinRecord] });
    this.printResult(res);
    this.calcTotalAmountRecord();
  }

  updateIdForRowInserted(nodeindex, insertedId) {
    const rowToUpdate = [];

    //find row update id based on node index
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
      let data = null;
      if (index === nodeindex) {
        data = rowNode.data;
        data.id = insertedId;
        rowToUpdate.push(data);
      }
    });

    if (rowToUpdate) {
      const res = this.gridApi.applyTransaction({ update: rowToUpdate });
      this.printResult(res);
    }
  }

  createNewRowFinRecord() {
    //TODO : USE AI TO RECOMMEND DEFAULT VALUE

    const user = localStorage.getItem('loggedUser');
    const create_by = JSON.parse(user)['id'];

    const month = new Date();
    const Month = new DatePipe('en-US').transform(month, 'yyyyMM');
    const isFirstRow = true;

    let suggestType = 101;

    const suggestMoney = this.transaction.amount - this.totalAmount;
    if (suggestMoney < 0) suggestType = 102;

    const suggestData = {
      id: null,
      transaction_id: this.transaction.id,
      happen_date: this.transaction.when,
      description: this.transaction.description,
      type: suggestType,
      sub_type: null,
      partner_code: null,
      order_sale_id: null,
      amount: suggestMoney,
      create_by: create_by
    };

    return suggestData;
  }

  printResult(res) {
    if (res.add) {
      res.add.forEach(function (rowNode) {
        // console.log("Added Row Node", rowNode);
      });
    }
    if (res.remove) {
      res.remove.forEach(function (rowNode) {
        // console.log("Removed Row Node", rowNode);
      });
    }
    if (res.update) {
      res.update.forEach(function (rowNode) {
        // console.log('Updated Row Node', rowNode);
      });
    }
  }

  getTransactionId() {
    const getTransIdSub = this.businessService.currentTransactionId.subscribe(
      (transactionId) => {
        this.transactionId = transactionId;
        // console.log(this.transactionId);
      },
      (err) => {
        console.log(err);
      }
    );
    this._subscription$.push(getTransIdSub);
  }

  getTransaction() {
    this.businessService.currentTransaction.subscribe(
      (transaction) => {
        this.transaction = transaction;
        this.amountTransaction = +transaction.amount;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAvailableSubTypesInEditor(selectedType) {
    // console.log('getAvailableSubTypesInEditor(selectedType)', selectedType);
    let avails = null;
    this.businessService.getAvaiableSubtypesByType(selectedType).subscribe((res) => {
      avails = res;
    });
    let availNames = avails.map((r) => {
      return r.sub_type;
    });

    availNames = this.removeDulicateInArrayString(availNames);
    return availNames;
  }

  removeDulicateInArrayString(array) {
    const arrayUnique = array.reduce((arrUnique, current) => {
      const item = arrUnique.find((item) => item === current);
      if (!item) return arrUnique.concat([current]);
      else return arrUnique;
    }, []);
    return arrayUnique;
  }

  getStatusFeatures() {
    this.businessService.currentFeatures.subscribe(
      (business) => {
        this.featuresOfBusiness = business;
        this.isPartner = this.featuresOfBusiness.feature_enable_partner == 0 ? false : true;
        this.isSubtype = this.featuresOfBusiness.feature_enable_subtype == 0 ? false : true;
        this.isOrderSale = this.featuresOfBusiness.feature_enable_order_sale == 0 ? false : true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  formatDate(date: any) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  ngOnDestroy(): void {
    for (const subs of this._subscription$) {
      subs.unsubscribe();
    }
  }

  onDepreciation(data) {
    const amount = this.rowData[0]['amount'];
    this.rowData[0]['amount'] =
      amount > 0
        ? Math.floor(amount / data) + (amount % data)
        : Math.ceil(amount / data) + (amount % data);
    const suggestData = this.createNewRowFinRecord();
    const newFinRecords = [];
    if (this.gridApi.getDisplayedRowCount() > 0) {
      for (let i = 1; i < data; i++) {
        newFinRecords.push({
          ...suggestData,
          type: amount > 0 ? 101 : 102,
          amount: amount > 0 ? Math.floor(amount / data) : Math.ceil(amount / data),
          happen_date: moment(this.transaction.when).add(i, 'months').format('YYYY-MM-DD HH:mm:ss')
        });
      }
    } else {
      for (let i = 0; i < data; i++) {
        newFinRecords.push({
          ...suggestData,
          amount: amount > 0 ? Math.floor(amount / data) : Math.ceil(amount / data),
          happen_date: moment(this.transaction.when).add(i, 'months').format('YYYY-MM-DD HH:mm:ss')
        });
      }
    }
    const res = this.gridApi.applyTransaction({ add: newFinRecords });
    this.printResult(res);
    this.calcTotalAmountRecord();
  }
}
