<div class="h-[60px]" *ngIf="show"></div>
<div class="absolute bottom-0 left-[-4px] right-[-2px] z-30 p-4 bg-white flex items-center border-t border-[#0000000F]" *ngIf="show">
  <div class="logo">
    <img src="../../../assets/img/casso-mascot-logo.png" alt="logo" style="width: 28px; margin-right: 14px;" />
  </div>
  <div class="flex flex-col justify-center text-[10px] font-normal">
    <div class="text-[#000000DE]">Robot tài chính doanh nghiệp</div>
    <div class="text-[#00000061]">Build {{ version }} at {{ buildDate }}</div>
  </div>
</div>
