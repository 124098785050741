<div class="reset-pass-container">
  <div class="reset-pass-content card-white-shadow">
    <div class="reset-pass-title">
      <div class="logo">
        <img src="../../../assets/img/icon.png" style="height: 64px" alt="casso icon png" />
        <img src="../../../assets/img/casso-logo-new.png" style="width: 64px" alt="casso logo png" />
      </div>
    </div>

    <div *ngIf="!isExpiredRePassToken && isResetPassword == false" class="form-reset-pass">
      <div *ngIf="!isExpiredRePassToken" class="title title-reset-pass">
        <h4 style="font-weight: 400; font-size: 24px; text-align: center">Đặt mật khẩu mới</h4>
      </div>

      <form [formGroup]="resetPassForm" autocomplete="off">
        <div class="error" *ngIf="isSimilarOldPass">
          Mật khẩu mới không được trùng với mật khẩu cũ.
        </div>

        <div class="div">
          <mat-form-field appearance="outline">
            <mat-label>Nhập mật khẩu</mat-label>
            <input
              [type]="hide ? 'password' : 'text'"
              formControlName="newPW"
              matInput
              name="newPassword"
              autocomplete="off"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>

            <mat-error *ngIf="resetPassForm.hasError('required', ['newPw'])"
              >Mật khẩu không được bỏ trống</mat-error
            >
            <mat-error
              *ngIf="
                !resetPassForm.hasError('required', ['newPw']) &&
                resetPassForm.hasError('passInvalid', ['newPW'])
              "
            >
              Mật khẩu phải có ít nhât 8 ký tự và kết hợp chữ thường, in hoa và số
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" style="margin-top: 8px">
            <mat-label>Xác nhận mật khẩu</mat-label>
            <input
              [type]="hide ? 'password' : 'text'"
              formControlName="confirmNewPW"
              matInput
              name="confirmPW"
              autocomplete="off"
            />
            <mat-error *ngIf="resetPassForm.hasError('required', ['confirmNewPW'])"
              >Xác nhận mật khẩu không được bỏ trống</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="
              resetPassForm.hasError('passwordnotmatch') &&
              resetPassForm.get('confirmNewPW').touched
            "
            class="msg-error"
            style="padding-left: 8px"
          >
            Mật khẩu không khớp!
          </div>
        </div>

        <button
          class="btn-submit-pw"
          [disabled]="resetPassForm.invalid"
          mat-raised-button
          color="primary"
          (click)="newPassword()"
        >
          Đặt lại mật khẩu
        </button>
      </form>
    </div>
    <div *ngIf="isExpiredRePassToken" class="res-pass-token-expired">
      <p>
        Đường dẫn đã hết hạn. Vui lòng chọn <a routerLink="/forgot-password">quên mật khẩu</a> để
        gửi lại email.
      </p>
    </div>
    <div *ngIf="isResetPassword" class="res-pass-token-expired">
      <p style="margin: 10px 0">
        Thay đổi mật khẩu thành công. Về trang <a routerLink="/login">đăng nhập</a>.
      </p>
    </div>
  </div>
</div>
