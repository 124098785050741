<div class="register-container">
  <div class="register-content card-white-shadow">
    <div class="register-header">
      <div style="margin-bottom: 16px">
        <div class="logo">
          <img src="../../../assets/img/icon.png" style="height: 64px" alt="casso logo png" />
        </div>
        <div class="logo">
          <img src="../../../assets/img/casso-logo-new.png" style="width: 64px" />
        </div>
      </div>
    </div>
    <div *ngIf="isRegister" class="form-register">
      <div class="title title-register">
        <h4 style="font-weight: 400; margin: 16px 0 10px; text-align: center">Đăng ký tài khoản</h4>
      </div>
      <div *ngIf="messge.length > 0" style="color: #a5140c; font-weight: 400; margin-bottom: 5px">
        {{ messge }}
      </div>

      <form [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off">
        <mat-form-field>
          <input
            type="email"
            name="email"
            formControlName="email"
            matInput
            placeholder="Email *"
            autocomplete="off"
          />
          <mat-error *ngIf="registerForm.hasError('required', ['email'])"
            >Email không được để trống</mat-error
          >
          <mat-error
            *ngIf="
              !registerForm.hasError('required', ['email']) &&
              registerForm.hasError('emailInvalid', ['email']) &&
              registerForm.get('email').touched
            "
            >Email không hợp lệ!</mat-error
          >
          <mat-error
            *ngIf="
              registerForm.hasError('emailExists', ['email']) && registerForm.get('email').touched
            "
            >Email đã được đăng ký! Vui lòng sử dụng email khác.</mat-error
          >
        </mat-form-field>
        <br />

        <div class="section-name">
          <mat-form-field id="lastname">
            <input
              type="text"
              formControlName="lastname"
              matInput
              placeholder="Họ và đệm *"
              autocomplete="off"
            />
            <mat-error *ngIf="registerForm.hasError('required', ['lastname'])"
              >Họ không được để trống</mat-error
            >
          </mat-form-field>
          <br />

          <mat-form-field id="firstname">
            <input
              type="text"
              formControlName="firstname"
              matInput
              placeholder="Tên *"
              autocomplete="off"
            />
            <mat-error *ngIf="registerForm.hasError('required', ['firstname'])"
              >Tên không được để trống</mat-error
            >
          </mat-form-field>
          <br />
        </div>

        <mat-form-field>
          <input
            type="password"
            formControlName="password"
            matInput
            placeholder="Mật khẩu *"
            autocomplete="new-password"
          />

          <mat-error *ngIf="registerForm.hasError('required', ['password'])"
            >Mật khẩu không được để trống</mat-error
          >
          <mat-error
            *ngIf="
              !registerForm.hasError('required', ['password']) &&
              registerForm.get('password').touched &&
              registerForm.hasError('passInvalid', ['password'])
            "
            >Mật khẩu bắt buộc phải lớn hơn 8 ký tự, bao gồm chữ thường, chữ in hoa và
            số!</mat-error
          >
        </mat-form-field>
        <br />

        <mat-form-field>
          <mat-label>Số điện thoại</mat-label>
          <input
            type="text"
            name="phone"
            formControlName="phoneNumber"
            matInput
            autocomplete="off"
          />
          <mat-error *ngIf="registerForm.hasError('required', ['phoneNumber'])"
            >Số điện thoại không được để trống</mat-error
          >
        </mat-form-field>
        <br />

        <mat-form-field>
          <mat-label>Mã giới thiệu (không bắt buộc)</mat-label>
          <input type="text" name="ref_id" formControlName="ref_id" matInput autocomplete="off" />
        </mat-form-field>
        <div style="text-align: center">
          <span
            >Bằng việc đăng ký tài khoản, tôi chấp nhận
            <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
              >Chính sách bảo mật</a
            >
            và
            <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
              >Thỏa thuận sử dụng</a
            >
            của Casso</span
          >
        </div>
        <br />
        <button
          class="btn-signup"
          mat-raised-button
          color="primary"
          [disabled]="registerForm.invalid || shouldDisplaySpinner"
        >
          Đăng ký
          <mat-spinner *ngIf="shouldDisplaySpinner" [diameter]="28"></mat-spinner>
        </button>
        <br />
      </form>
    </div>

    <div *ngIf="!isRegister" class="register-msg-success">
      <p style="padding: 0 24px">
        Đăng ký thành công. Vui lòng kiểm tra email đăng ký để kích hoạt tài khoản.
      </p>
    </div>

    <div class="re-login">
      <span *ngIf="isRegister">Bạn đã có tài khoản? </span>
      <a routerLink="/login"
        ><span *ngIf="isRegister">Đăng nhập</span>
        <span *ngIf="!isRegister">Về trang đăng nhập</span></a
      >
    </div>
  </div>
</div>
