<mat-toolbar color="primary">
  Nhập giao dịch tài chính {{ bankInfor ? ' - ' + bankInfor.bank.short_name : '' }}
</mat-toolbar>
<div class="inforTransaction" *ngIf="transaction">
  <div class="left detail-group-left">
    <div>
      <p id="tid">Mã tham chiếu: {{ transaction.tid }}</p>
      <p style="float: right">{{ transaction.when | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
    </div>
    <br />
    <div class="detail-transaction">
      <p>{{ transaction.description }}</p>
    </div>
  </div>
  <div class="right detail-group-right">
    <div class="compare">
      <p>GT: {{ formatCurrency(transaction.amount) }}</p>
      <p>DD: {{ formatCurrency(totalAmount) }}</p>
    </div>
    <div class="compare iconCompare">
      <mat-icon matListIcon color="{{ transaction.amount == totalAmount ? '' : 'warn' }}">{{
        transaction.amount == totalAmount ? 'done' : 'error_outline'
      }}</mat-icon>
    </div>
  </div>
  <div>
    <p *ngIf="transactionError == 401" style="color: red">
      Giao dịch ngân hàng này đã được quản trị viên xóa. Nguyên nhân là lỗi từ phía ngân hàng gây
      nên sự trùng lặp. Vui lòng không nhập bất cứ giao dịch tài chính nào cho giao dịch ngân hàng
      này.
    </p>
    <p *ngIf="transactionError == 402" style="color: red">
      Giao dịch ngân hàng này đã được một quản trị viên thêm vào thủ công. Hãy cẩn trọng với những
      giao dịch ngân hàng nhập thủ công. Chỉ được thêm giao dịch thủ công khi xác nhận được lỗi
      thiếu giao dịch từ phía ngân hàng.
    </p>
  </div>
</div>

<ag-grid-angular
  #agGrid
  style="width: 100%; height: calc(100% - 150px)"
  class="ag-theme-balham"
  resizable="true"
  [gridOptions]="gridOptions"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)"
  (cellValueChanged)="onCellValueFinChanged($event)"
  (rowClicked)="onRowClicked($event)"
  [enableRangeSelection]="true"
>
</ag-grid-angular>
<div class="btnActions">
  <button
    mat-raised-button
    class="btnFinish"
    mat-button
    mat-dialog-close
    (click)="completeFinRecord()"
  >
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" class="icon"/></svg> -->
    Hoàn tất
  </button>
  <button mat-raised-button class="btnFinish" mat-button mat-dialog-close>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg> -->
    Bỏ qua
  </button>
  <button class="btnAdd" mat-button (click)="addRowFinRecord()">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" class="icon" />
    </svg>
    Thêm dòng
  </button>
  <button class="btnDepreciation" mat-button [matMenuTriggerFor]="depreciationMenu">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M7.5,21H2V9h5.5V21z M14.75,3h-5.5v18h5.5V3z M22,11h-5.5v10H22V11z" class="icon" />
    </svg>
    Khấu hao
  </button>
  <button class="btnDelete" mat-button (click)="deleteRowFinRecord()">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"
        class="icon"
      />
    </svg>
    Xóa dòng
  </button>
  <mat-menu #depreciationMenu="matMenu">
    <button mat-menu-item (click)="onDepreciation(12)">Khấu hao 12 tháng</button>
    <button mat-menu-item (click)="onDepreciation(24)">Khấu hao 24 tháng</button>
    <button mat-menu-item (click)="onDepreciation(36)">Khấu hao 36 tháng</button>
    <button mat-menu-item (click)="onDepreciation(48)">Khấu hao 48 tháng</button>
  </mat-menu>
</div>
