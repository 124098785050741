<h4
  *ngIf="transactions && transactions.length > 0 && titleGrid"
  style="font-weight: 200; font-size: 20px"
>
  {{ titleGrid }}
</h4>
<div *ngIf="isMobile">
  <mat-card
    *ngIf="isFilter && transactions && transactions.length > 0"
    class="filter-card"
    style="margin-top: 5px; border-radius: 5px; padding: 5px 10px"
  >
    <mat-form-field>
      <input
        matInput
        (keyup)="transactionsFilter($event.target.value)"
        placeholder="Lọc nhanh ..."
        autocomplete="off"
      />
    </mat-form-field>
  </mat-card>

  <table
    mat-table
    *ngIf="transactions && transactions.length > 0"
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>Thời gian</th>
      <td mat-cell *matCellDef="let row">
        <span class="mat-caption">{{ row.when | date: 'dd/MM/yyyy' }}</span
        ><br />
        <span class="mat-caption">{{ row.when | date: 'shortTime' }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="narrative">
      <th mat-header-cell *matHeaderCellDef>Nội dung</th>
      <td mat-cell *matCellDef="let row">
        <span class="mat-caption">{{ normalizeText(row.description) }} </span>
      </td>
      <td mat-footer-cell *matFooterCellDef><b>Tổng</b></td>
    </ng-container>

    <ng-container matColumnDef="moneyChanged">
      <th mat-header-cell *matHeaderCellDef>Giá trị</th>
      <td mat-cell *matCellDef="let row">
        <span
          class="mat-body-1"
          [ngStyle]="{ color: row.amount >= 0 ? 'lightseagreen' : 'lightcoral' }"
          >{{ row.amount | currency: 'VND' }}
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span
          class="mat-body-1"
          [ngStyle]="{ color: getTotalAmount() >= 0 ? 'lightseagreen' : 'lightcoral' }"
        >
          {{ getTotalAmount() | currency: 'VND' }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Trạng thái</th>
      <td mat-cell *matCellDef="let row">
        <!-- <mat-icon color='{{row.status==1?"primary":row.status==2?"warn":"primary"}}' matListIcon>{{row.status==1?"done":row.id==transactionId?"done":row.status==2?"highlight_off":"error_outline"}}</mat-icon> -->
        <mat-icon color="primary" *ngIf="row.status == 1" matListIcon>done</mat-icon>
        <mat-icon color="warn" *ngIf="row.status == 2" matListIcon>error_outline</mat-icon>
        <mat-icon color="warn" *ngIf="row.status == 0" matListIcon>add</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="4">
        <div [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
          <app-transaction-viewer
            *ngIf="row == expandedRow"
            [transaction]="row"
          ></app-transaction-viewer>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['time', 'narrative', 'moneyChanged', 'status']"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['time', 'narrative', 'moneyChanged', 'status']"
      (click)="clickRow(row)"
      class="transaction-row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row2; columns: ['expandedDetail']"
      class="transaction-detail-row"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['time', 'narrative', 'moneyChanged']"></tr>
  </table>
</div>

<div class="section-table-record" *ngIf="isMobile == false">
  <mat-card
    *ngIf="isFilter && transactions && transactions.length > 0"
    style="margin: 5px 0; border-radius: 5px; padding: 5px 10px"
  >
    <input
      matInput
      [(ngModel)]="filterText"
      (keyup)="onFilterTextBoxChanged()"
      placeholder="Lọc nhanh ..."
      name="quick-filter"
    />
  </mat-card>

  <ag-grid-angular
    *ngIf="transactions && transactions.length > 0"
    id="transAggrid"
    #agGrid
    style="width: 100%; height: 150px"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [pinnedBottomRowData]="pinnedBottomRowData"
    (filterChanged)="filterChanged($event)"
    (columnVisible)="onColumnVisible($event)"
    [statusBar]="statusBar"
    [animateRows]="true"
    [enableRangeSelection]="true"
    [suppressChangeDetection]="true"
    [getContextMenuItems]="getContextMenuItems"
    (bodyScroll)="handleScrollAggrid($event)"
  >
  </ag-grid-angular>
</div>
