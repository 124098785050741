import { Pipe, PipeTransform } from '@angular/core';
import { BANK_IDS_SUPPORT_BALANCE_UPDATE } from 'src/app/modules/core/constant';
import { BankAcc } from 'src/app/modules/core/models/business';

const speeds = {
  immediately: {
    color: '#15ab64'
  },
  veryfast: {
    color: '#15ab64'
  },
  fast: {
    color: '#15ab64'
  },
  slow: {
    color: '#15ab64'
  },
  veryslow: {
    color: 'gray'
  },
  limited: {
    color: 'gray'
  },
  free: {
    color: 'gray'
  }
};

@Pipe({
  name: 'bankAccSpeedColor'
})
export class BankAccSpeedColorPipe implements PipeTransform {
  BANK_IDS_SUPPORT_BALANCE_UPDATE = BANK_IDS_SUPPORT_BALANCE_UPDATE;
  transform(bankAcc: BankAcc, args?: any): any {
    if (this.BANK_IDS_SUPPORT_BALANCE_UPDATE.includes(bankAcc.bank.id)) return speeds.immediately.color;

    const speedTitle = speeds[bankAcc.bank.speed];

    return speedTitle ? speedTitle.color : speeds.free.color;
  }
}
