import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { PLAN_NAME } from 'src/app/modules/core/constant';
import { Business, EPlan } from 'src/app/modules/core/models/business';

@Component({
  selector: 'app-sidebar-bussiness',
  templateUrl: './sidebar-bussiness.component.html',
  styleUrls: ['./sidebar-bussiness.component.css']
})
export class SidebarBussinessComponent {
  @Input() business: Business;
  @Input() menuList: any;
  @Input() isExpandedSideNav: boolean;
  @Input() sidenav: MatSidenav;
  @Input() isMobile: boolean;
  @Input() businessName: string;
  @Input() businessId: number;
  @Input() businessLogo: string;
  @Input() menuSelectedIndex: number;

  @Output() logout = new EventEmitter<boolean>();

  PLANS: any;

  showMeWhenUrlIs = '/business';
  profiletMenuPosition = { x: '0px', y: '0px' };
  expandedHeight = '48px';

  @ViewChild(MatMenuTrigger) profileMenu: MatMenuTrigger;
  @ViewChild('profileButton') profileButton: ElementRef;

  constructor(private router: Router) {
    this.PLANS = EPlan;
  }

  onProfileMenu() {
    const profileMenuSelector = document.querySelector('#profile-menu') as any;

    if (profileMenuSelector) {
      this.profiletMenuPosition.x = profileMenuSelector.offsetWidth + 4 + 'px';
      this.profiletMenuPosition.y =
        profileMenuSelector.offsetHeight + profileMenuSelector.offsetTop + 'px';
      this.profileMenu.openMenu();
      this.profileButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
    }
  }

  gotoPortfolio() {
    this.router.navigate(['portfolio']);
  }

  gotoProfile() {
    this.router.navigate(['/business', this.businessId, 'profile']);
  }

  closeSideNav() {
    if (this.isMobile) {
      this.sidenav.close();
    } else {
      this.sidenav.close();
      this.sidenav.open();
    }
  }

  gotoFirstPageOfMenu(menu) {
    const routeLinkFirst = menu[0];
    this.menuSelectedIndex = this.menuList.findIndex((item) => item.name === menu.name);
    this.router.navigate(['/business', this.businessId, routeLinkFirst.route]);
  }

  commingSoon() {
    window.alert('Tính năng đang phát triển!');
  }
}
