import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Predict } from 'src/app/modules/core/models/business';
import { BusinessService } from '../../../../../../core/services/business.service';

@Component({
  selector: 'app-rule-grid',
  templateUrl: './rule-grid.component.html',
  styleUrls: ['./rule-grid.component.css']
})
export class RuleGridComponent implements OnInit {
  @Input() data: Predict[] = [];
  @Input() idfillter: any;
  @Output() deleteIdFilter = new EventEmitter();
  businessId: number;
  public gridApi;
  columnDefs;
  getRowHeight;
  value_Filter: any;
  rowData: Predict[] = [];
  isMobile: boolean;
  @ViewChild('table') table: MatTable<any>;
  dataSource: MatTableDataSource<any>;
  displayedColumns = [
    'transaction.when',
    'transaction.description',
    'transaction.amount',
    'matches'
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private businessService: BusinessService,
    private deviceService: DeviceDetectorService
  ) {
    this.columnDefs = [
      {
        headerName: 'Thời gian',
        field: 'transaction.when',
        filter: true,
        width: 100,
        suppressMenu: true,
        cellStyle: {
          textAlign: 'left'
        },
        valueFormatter: (data) => (data.value ? moment(data.value).format('DD/MM/YYYY') : null)
      },

      {
        headerName: 'Nội dung',
        field: 'transaction.description',
        filter: true,
        width: 380,
        editable: false,
        suppressMenu: true,
        cellStyle: {
          textAlign: 'left'
        },
        tooltip: (params) => (params.value != null ? params.value : null)
      },

      {
        headerName: 'Số tiền',
        field: 'transaction.amount',
        filter: true,
        width: 150,
        editable: false,
        suppressMenu: true,
        cellStyle: {
          textAlign: 'right'
        },
        cellRenderer: this.customValueAmount
      },
      {
        headerName: 'Áp dụng quy tắc',
        field: 'matches',
        filter: false,
        editable: false,
        width: 380,
        suppressMenu: true,
        cellStyle: {
          textAlign: 'left'
        },
        cellRenderer: this.customValueMemo.bind(this)
      }
    ];

    this.getRowHeight = function (params) {
      return params.data.rowHeight;
    };
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isMobile = this.deviceService.isMobile();
    if (changes.hasOwnProperty('data') && this.data) {
      this.data.forEach((row) => {
        row['rowHeight'] = row.matches ? 28 * row.matches.length : 28;
      });
    }
    if (changes.hasOwnProperty('idfillter') && this.idfillter) {
      const types = this.businessService.FINANCIAL_TYPES_VN;
      let matches;
      if (this.idfillter.id) {
        if (this.data.length > 0) {
          this.data.forEach((row) => {
            row.matches.forEach((match) => {
              if (match.id == this.idfillter.id) {
                this.rowData.push(row);
                match.actions.forEach((action) => {
                  const actionHtml = `
                    <div>
                      <span class='memo'># ${this.idfillter['id']}: ${this.idfillter['memo']}</span>
                      ${action.type ? `[ <span class='type'>${types[action.type]}</span>` : ''}
                      ${action.sub_type ? `<span class='sub-type'>${action.sub_type}</span>` : ''}
                      ${action.partner ? ` <span class='partner'>${action.partner}</span>` : ''}
                      ${
                        action.date_modifier
                          ? ` <span class='date-modifier'>${
                              this.businessService['CONFIG_MODIFIER'][action.date_modifier]
                            }</span> ]`
                          : ''
                      }
                    </div>
                  `;
                  matches = actionHtml;
                });
                this.value_Filter = `<div> ${matches} </div>`;
              } else {
                this.idfillter.actions.forEach((action) => {
                  const actionHtml = `
                    <div>
                      <span class='memo'># ${this.idfillter['id']}: ${this.idfillter['memo']}</span>
                      ${action.type ? `[ <span class='type'>${types[action.type]}</span>` : ''}
                      ${action.sub_type ? `<span class='sub-type'>${action.sub_type}</span>` : ''}
                      ${action.partner ? ` <span class='partner'>${action.partner}</span>` : ''}
                      ${
                        action.date_modifier
                          ? ` <span class='date-modifier'>${
                              this.businessService['CONFIG_MODIFIER'][action.date_modifier]
                            }</span> ]`
                          : ''
                      }
                    </div>
                  `;
                  matches = actionHtml;
                });
                this.value_Filter = `<div> ${matches} </div>`;
              }
            });
          });
        } else {
          this.idfillter.actions.forEach((action) => {
            const actionHtml = `
                <div>
                  <span class='memo'># ${this.idfillter['id']}: ${this.idfillter['memo']}</span>
                  ${action.type ? `[ <span class='type'>${types[action.type]}</span>` : ''}
                  ${action.sub_type ? `<span class='sub-type'>${action.sub_type}</span>` : ''}
                  ${action.partner ? ` <span class='partner'>${action.partner}</span>` : ''}
                  ${
                    action.date_modifier
                      ? ` <span class='date-modifier'>${
                          this.businessService['CONFIG_MODIFIER'][action.date_modifier]
                        }</span> ]`
                      : ''
                  }
                </div>
              `;
            matches = actionHtml;
          });
          this.value_Filter = `<div> ${matches} </div>`;
        }
      }
    } else {
      this.rowData = this.data;
    }

    this.dataSource = new MatTableDataSource(this.rowData);
  }
  customAmountMobile(amount) {
    if (amount < 0) {
      return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }
  customApplyRule(data) {
    const types = this.businessService.FINANCIAL_TYPES_VN;
    const matches = [];
    if (data) {
      data.forEach((match) => {
        const actions = match.actions;
        actions.forEach((action) => {
          const actionHtml = `
          <div>
          <span class='memo'># ${match.id}: ${match.memo}</span>
          ${action.type ? `[ <span class='types'>${types[action.type]}</span>` : ''}
          ${action.sub_type ? `<span class='sub-type'>${action.sub_type}</span>` : ''}
          ${action.partner ? ` <span class='partner'>${action.partner}</span>` : ''}
          ${
            action.date_modifier
              ? ` <span class='date-modifier'>${
                  this.businessService['CONFIG_MODIFIER'][action.date_modifier]
                }</span> ]`
              : ''
          }
        </div>`;
          matches.push(actionHtml);
        });
      });
      return `<div> ${matches.join('')} </div>`;
    } else {
      return ``;
    }
  }
  customValueAmount(params) {
    if (params.value < 0) {
      return (
        "<b style='color: #f44336'>" +
        params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</b>'
      );
    } else {
      return (
        "<b style='color: #15ab64'>" +
        params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +
        '</b>'
      );
    }
  }

  customValueMemo(params) {
    const types = this.businessService.FINANCIAL_TYPES_VN;
    const matches = [];
    if (params.value) {
      params.value.forEach((match) => {
        const actions = match.actions;
        actions.forEach((action) => {
          const actionHtml = `
          <div>
            <span style='color: #15ab64;font-weight: 600;'># ${match.id}: ${match.memo}</span>
            ${
              action.type
                ? `[ <span style='color: #000;font-weight: 600;'>${types[action.type]}</span>`
                : ''
            }
            ${
              action.sub_type
                ? `<span style='color: #190be8;font-weight: 600;'>${action.sub_type}</span>`
                : ''
            }
            ${
              action.partner
                ? ` <span style='color: #b80f94;font-weight: 600;'>${action.partner}</span>`
                : ''
            }
            ${
              action.date_modifier
                ? ` <span style='color: #b301ee;font-weight: 600;'>${
                    this.businessService['CONFIG_MODIFIER'][action.date_modifier]
                  }</span> ]`
                : ''
            }
          </div>
          `;
          matches.push(actionHtml);
        });
      });
      return `<div> ${matches.join('')} </div>`;
    } else {
      return ``;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.component_context = this;
    params.api.sizeColumnsToFit();
  }
  onRemoveFilter() {
    this.idfillter = null;
    this.rowData = this.data;
    this.deleteIdFilter.emit(this.idfillter);
  }
}
