import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { TokenExpiredComponent } from 'src/app/shared/components/token-expired/token-expired.component';
import { UserService } from './user.service';

declare let ClientJS: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  private deviceId = 'unspecified';

  constructor(
    private userService: UserService,
    private router: Router,
    private tokenExpiredDialog: TokenExpiredComponent
  ) {
    try {
      const clientJs = new ClientJS();
      this.deviceId =
        (clientJs.getBrowser() != null ? clientJs.getBrowser() : '') +
        '.' +
        clientJs.getFingerprint();
      this.deviceId = this.deviceId.toLowerCase();
    } catch (e) {
      console.error('cant get device id', e);
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.tokenExpiredDialog.openDialog();
      this.userService.clearLoggedUser();
      return EMPTY;
    }
    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ withCredentials: true, headers: request.headers.set('Content-Type', 'application/json') });
    }
    if (!request.headers.has('responseType')) {
      request = request.clone({ withCredentials: true, headers: request.headers.set('responseType', 'text') });
    }
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
    }
    const isAccessTokenExpired = this.userService.checkLoggedUser();
    const isRefreshTokenExpired = this.userService.checkRefreshToken();

    if (isAccessTokenExpired && !isRefreshTokenExpired) {
      return next.handle(this.addAuthenticationToken(request))
        .pipe(catchError(x => this.handleAuthError(x)));
    }

    if (isAccessTokenExpired && isRefreshTokenExpired) {
      if (!this.refreshTokenInProgress) {
        const user_id = this.userService.getLoggedUser().id;
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.userService.refresnToken(user_id, this.deviceId).pipe(
          switchMap((res) => {
            if (res.error === 0) {
              const user = this.userService.getLoggedUser();
              this.userService.setLoggedUser({ ...user, ...res.data });
              this.refreshTokenSubject.next(res.data.access_token);
              return next.handle(this.addAuthenticationToken(request));
            } else if (res.error === 301) {
              this.tokenExpiredDialog.openDialog();
              this.userService.clearLoggedUser();
              this.userService.signOut().subscribe();
            }
          }),
          finalize(() => (this.refreshTokenInProgress = false)),
          catchError(x => this.handleAuthError(x))
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap(() => {
            return next.handle(this.addAuthenticationToken(request))
              .pipe(catchError(x => this.handleAuthError(x)));
          })
        );
      }
    } else {
      return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
    }
  }

  addAuthenticationToken(request) {
    return request.clone({ withCredentials: true });
  }
}
