import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLinkGoBack'
})
export class GetLinkGoBack implements PipeTransform {
  transform(value: any[], index: number, field: string | undefined): any {
    let routeLink = '';
    if (field) {
      for (let i = index; i < value.length; i++) {
        if (value[i].queryParams) {
          return { [field]: value[i].queryParams };
        }
      }
      return null;
    }

    for (let i = 0; i < value.length - (index + 1); i++) {
      routeLink += '../';
    }
    return routeLink;
  }
}
