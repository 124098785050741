import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../models/business';
import {
  ApiKeyResponse,
  ApiKeysResponse,
  ConfigIntegration,
} from '../models/settings';

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}
  configIntegration: ConfigIntegration[];

  createApiKey(businessId: number, data: Object = {}): Observable<ApiKeyResponse> {
    const apiUrl = `/business/${businessId}/api-keys`;
    return this.http.post<ApiKeyResponse>(environment.api_host + apiUrl, data);
  }

  getApiKeys(businessId: number): Observable<ApiKeysResponse> {
    const apiUrl = `/business/${businessId}/api-keys`;
    return this.http.get<ApiKeysResponse>(environment.api_host + apiUrl);
  }

  deleteApiKey(businessId: number, apiKeyId: number): Observable<BasicResponse> {
    const apiUrl = `/business/${businessId}/api-keys/${apiKeyId}`;
    return this.http.delete<ApiKeysResponse>(environment.api_host + apiUrl);
  }

  updateApiKey(businessId: number, apiKeyId: number, data: Object): Observable<BasicResponse> {
    const apiUrl = `/business/${businessId}/api-keys/${apiKeyId}`;
    return this.http.put<ApiKeysResponse>(environment.api_host + apiUrl, data);
  }
}
