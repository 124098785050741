import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  constructor() {}

  createWindow(url: string, name = 'Window', width = 500, height = 600, left = 0, top = 0) {
    if (url == null) {
      return null;
    }

    const options = `width=${width},height=${height},left=${left},top=${top}`;

    return window.open(url, name, options);
  }
}
