import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonprettyprint'
})
export class JsonPrettyPrintPipe implements PipeTransform {
  transform(json: any): any {
    let data;

    if (!json) return 'Không chứa nội dung';

    try {
      if (typeof json === 'object') {
        data = json;
      } else {
        data = JSON.parse(json);
      }
    } catch (error) {
      return `${json.replace(/\n/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;')}`;
    }

    data = JSON.stringify(data, undefined, 4)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return data.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      (match) => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }

        return `<span class="${cls}"> ${match} </span>`;
      }
    );
  }
}
