import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../modules/core/services/user.service';

@Component({
  selector: 'app-register-email',
  templateUrl: './register-email.component.html',
  styleUrls: ['./register-email.component.css']
})
export class RegisterEmailComponent implements OnInit {
  isMessage: false;
  messge = '';
  registerForm: FormGroup;
  isRegister: boolean;
  shouldDisplaySpinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private angulartics2: Angulartics2,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email, this.validateEmail]),
        this.userService.emailValidatorForm(true)
      ],
      firstname: ['', [Validators.required, Validators.maxLength(128)]],
      lastname: ['', [Validators.required, Validators.maxLength(128)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      password: ['', [Validators.required, this.validateStrengthPassword]],
      ref_id: [
        this.getCookie('ref_code') ||
          this.userService.getTypeStorage('gift') ||
          this.userService.getTypeStorage('voucher') ||
          this.userService.getTypeStorage('ref')
      ]
    });
    this.isRegister = true;
  }

  register() {
    this.messge = '';

    if (this.registerForm.invalid) {
      this.toastrService.info('Thông tin đăng nhập không chính xác.');
      return;
    }
    this.shouldDisplaySpinner = true;

    const { email, firstname, lastname, phoneNumber, password, ref_id } = this.registerForm.value;
    const pass_hash = CryptoJS.SHA512(password).toString();
    const userProperties = { email, firstname, lastname, zip: 100 };
    if (phoneNumber && phoneNumber != 0 && phoneNumber.length > 0) {
      userProperties['phone'] = phoneNumber;
    }

    this.userService
      .register(email, firstname, lastname, phoneNumber, pass_hash, ref_id)
      .subscribe((res) => {
        this.shouldDisplaySpinner = false;

        if (res.error == 0) {
          this.angulartics2.setUserProperties.next(userProperties);
          this.isRegister = false;
        } else {
          this.angulartics2.setUserProperties.next(userProperties);
          this.messge = res.message;
        }
      });
  }

  validateEmail(controls: AbstractControl) {
    const email = controls.value;
    const regexEmail =
      /^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,4}|[0-9]{1,3})\]?$/;
    return regexEmail.test(email) ? null : { emailInvalid: true };
  }

  validateStrengthPassword(controls: AbstractControl) {
    const password = controls.value;
    const regexStrPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    return regexStrPass.test(password) ? null : { passInvalid: true };
  }
  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
