import { Injectable, Pipe, PipeTransform } from '@angular/core';

//example: *ngFor="let it of its | filter : 'name' : 'value or variable'"
@Pipe({
  name: 'filterIsNullOrEqual'
})
export class FilterIsNullOrEqualPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter((item) => !item[field] || item[field] === value);
  }
}
