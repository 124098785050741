import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  FinancialRecord,
  PredictFieldProb,
  PredictInfo,
  Transaction
} from '../../../../core/models/business';
import { BusinessService } from '../../../../core/services/business.service';
import { TransactionGridEditorComponent } from '../transaction-grid-editor/transaction-grid-editor.component';

@Component({
  selector: 'app-transaction-viewer',
  templateUrl: './transaction-viewer.component.html',
  styleUrls: ['./transaction-viewer.component.css']
})
export class TransactionViewerComponent implements OnInit {
  transactionId: number;
  @Input() transaction: Transaction;
  finRecords: FinancialRecord[];
  fileNameDialogRef: MatDialogRef<TransactionGridEditorComponent>;
  isMobile: boolean;

  data: any;
  params: any;

  constructor(
    private businessService: BusinessService,
    private dialog: NgDialogAnimationService,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    if (this.isMobile) {
      this.transactionId = this.transaction.id;
      this.businessService.setTransaction(this.transactionId);
      this.businessService.transaction(this.transaction);
    }
  }

  agInit(params) {
    if (!this.isMobile) {
      this.params = params;
      this.data = params.value;
      this.transaction = params.data;
    }
  }

  lookupTypeName(typeId) {
    if (!typeId) return '';
    return this.businessService.FINANCIAL_TYPES[typeId];
  }

  get getTransactionId() {
    // console.log(this.transaction.id);
    // console.log(this.transaction);
    this.transactionId = this.transaction.id;
    return this.transactionId;
  }
  openFileDialog(transaction: Transaction) {
    if (!this.isMobile) {
      this.transaction = transaction;
      this.transactionId = transaction.id;
      this.businessService.setTransaction(this.transactionId);
      this.businessService.transaction(transaction);
    }

    this.fileNameDialogRef = this.dialog.open(TransactionGridEditorComponent, {
      disableClose: false,
      width: '920px',
      height: '100vh',
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { duration: 300, easing: 'ease-in-out', endDelay: 300 }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 300 }
        }
      },
      position: { rowEnd: '0' },
      panelClass: 'right-side-panel'
    });

    this.fileNameDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.businessService.addFinRecord(result.data, true);
      }
    });
  }

  getBestCandidate(candidates: PredictFieldProb[]) {
    if (candidates.length == 0) return null;
    if (candidates.length == 1) return candidates[0].val;

    const candidate = candidates.reduce((prev, current) =>
      prev.prob > current.prob ? prev : current
    );
    return candidate.val;
  }

  makeFincialRecordFromPredict(predict: PredictInfo) {
    const financialRecord: FinancialRecord = new FinancialRecord();

    financialRecord.amount = this.getBestCandidate(predict.predict_fields.amounts);
    financialRecord.description = this.getBestCandidate(predict.predict_fields.descriptions);
    financialRecord.happen_date = this.getBestCandidate(predict.predict_fields.happen_dates);
    financialRecord.sub_type = this.getBestCandidate(predict.predict_fields.sub_types);
    financialRecord.type = this.getBestCandidate(predict.predict_fields.types);
    financialRecord.partner_code = null;
    return [financialRecord];
  }

  autoImport() {
    this.businessService.predictTransactionImporting(this.transaction.id).subscribe((res) => {
      if (res.error == 0) {
        const predict = res.data;
        // console.log(predict);

        // console.log ("BEGIN AUTO INSERT");
        const financialRecords = this.makeFincialRecordFromPredict(predict);
        //TODO: insert all
        const financialRecord = financialRecords[0];
        this.businessService
          .insertFinancialRecord(
            this.businessService.currentBussiness.id,
            this.transaction.id,
            financialRecord.happen_date,
            financialRecord.description,
            financialRecord.type,
            financialRecord.sub_type,
            financialRecord.partner_code,
            financialRecord.order_sale_id,
            financialRecord.amount,
            null,
            this.transaction.bank_sub_acc_id
          )
          .subscribe((res) => {
            if (res.error == 0) {
              // console.log("insert success");
              this.businessService.setStatusOfTransaction({
                status: 1,
                transId: this.transaction.id
              });
            } else {
              console.error(res);
            }
          });
      }
    });
  }
}
