import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("SHI_International_Corp_-_USA__on_behalf_of_COLGATE__MultiApp_6Devs7_June_2020__MTU5MTQ4NDQwMDAwMA==8b6496bd559839df6c9ff807a6392b25");
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  