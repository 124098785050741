<div style="margin-top: 20px; margin-bottom: 20px" *ngIf="idfillter">
  <div class="icon-remote">
    <button mat-button style="border: 1px solid #ccc; padding: 0 10px" (click)="onRemoveFilter()">
      <div class="filter" [innerHTML]="value_Filter"></div>
      <mat-icon class="remove" aria-label="Side nav toggle icon">close</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="rowData != null && !isMobile">
  <ag-grid-angular
    #agGrid
    id="aggrid"
    style="width: 100%; height: calc(100vh - 112px)"
    class="ag-theme-balham"
    resizable="true"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    sortable="true"
    filter="true"
    suppressScrollOnNewData="true"
    [enableRangeSelection]="true"
    [getRowHeight]="getRowHeight"
    [animateRows]="true"
  >
  </ag-grid-angular>
</div>
<div *ngIf="rowData != null && isMobile == true" class="example-container">
  <mat-table matSort cdkDropList #table [dataSource]="dataSource" class="example-box">
    <ng-container matColumnDef="transaction.when">
      <mat-header-cell *matHeaderCellDef> Thời gian </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.transaction.when | date: 'dd/MM/yyyy' }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="transaction.description">
      <mat-header-cell *matHeaderCellDef> Nội dung </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.transaction.description }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="transaction.amount">
      <mat-header-cell *matHeaderCellDef> Tiền </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        [ngStyle]="{
          color: element.transaction.amount < 0 ? '#f44336' : '#15ab64',
          'font-weight': '700'
        }"
      >
        {{ customAmountMobile(element.transaction.amount) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="matches">
      <mat-header-cell *matHeaderCellDef> Áp dụng quy tắc</mat-header-cell>
      <mat-cell *matCellDef="let element" [innerHTML]="customApplyRule(element.matches)">
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<div *ngIf="rowData == null">
  <div>Chưa có giao dịch nào áp dụng quy tắc</div>
  <div [innerHTML]="value_Filter"></div>
</div>
