import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HammerGestureConfig } from '@angular/platform-browser';
import { AuthInterceptor } from './services/auth.interceptor';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { velocity: 0.4, threshold: 20 }, // override default settings,
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    DatePipe,
    {
      provide: HammerGestureConfig,
      useClass: MyHammerConfig
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule {}
