<div class="content-dialog" class="max-w-[360px]">
  <div class="header-dialog">
    <div
      *ngIf="data.type === 'delete-bank-acc'"
      class="flex flex-col items-center w-[314px]"
      style="background-image: url('../../../../assets/img/bg-select-bank-type.png')"
    >
      <img src="../../../../assets/svg/bank-off.svg" class="my-[30px] w-6 ml-[3px]" />
      <strong class="text-base font-medium">{{ data.title }}</strong>
    </div>
    <div
      *ngIf="data.type === 'delete-business'"
      class="flex flex-col items-center w-[314px]"
      style="background-image: url('../../../../assets/img/bg-select-bank-type.png')"
    >
      <img src="../../../../assets/svg/delete-business.svg" class="my-[30px] w-6 ml-[3px]" />
      <strong class="text-base font-medium">{{ data.title }}</strong>
    </div>
    <div
      *ngIf="data.type === 'stop'"
      class="flex flex-col items-center w-[314px]"
      style="background-image: url('../../../../assets/img/bg-select-bank-type.png')"
    >
      <img src="../../../../assets/svg/bank-minus.svg" class="my-[30px] w-6 ml-[3px]" />
      <strong class="text-base font-medium">{{ data.title }}</strong>
    </div>
    <div
      *ngIf="data.type === 'maintenance'"
      class="flex flex-col items-center w-[314px] relative"
      style="background-image: url('../../../../assets/img/bg-select-bank-type.png')"
    >
      <img src="../../../../assets/svg/bank-maintenance.svg" class="my-[30px] w-6 ml-[3px]" />
      <mat-icon mat-dialog-close class="cursor-pointer absolute right-[-24px] top-2 text-[#8C8D92]"
        >close</mat-icon
      >
      <strong class="text-base font-medium text-[#D32F2F]">{{ data.title }}</strong>
    </div>
    <div
      *ngIf="data.type === 'warning'"
      class="flex flex-col items-center w-[314px]"
      style="background-image: url('../../../../assets/img/bg-select-bank-type.png')"
    >
      <span class="material-icons-outlined my-[30px] ml-[2px] text-[#8C8D92]"> info </span>
      <strong class="text-base font-medium">{{ data.title }}</strong>
    </div>
    <strong *ngIf="!data.type" class="text-base font-medium">{{ data.title }}</strong>
  </div>
  <mat-divider class="w-full !mt-4"></mat-divider>

  <div *ngIf="data.content.length > 0" mat-dialog-content>
    <p [innerHTML]="data.content"></p>

    <div *ngIf="data.isValidate" class="content-confirm-action-remove">
      <form [formGroup]="formConfirmDelete">
        <mat-form-field class="confirm-bankAcc-number" appearance="outline">
          <input
            #confirm
            type="text"
            formControlName="confirm"
            matInput
            cdkFocusInitial
            autocomplete="off"
          />
        </mat-form-field>
        <div class="flex">
          <button mat-stroked-button mat-dialog-close class="flex-shrink-0 !mr-2">Hủy bỏ</button>
          <button
            class="flex-1"
            [disabled]="formConfirmDelete.invalid"
            color="warn"
            mat-raised-button
            [mat-dialog-close]="data.data"
            cdkFocusInitial
          >
            Tôi hiểu điều này, xóa {{ data.data.label }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="data.isBasic" mat-dialog-actions style="align-items: center; justify-content: center">
    <button
      class="flex-shrink-0 mr-8"
      *ngIf="data.isCancel"
      mat-stroked-button
      (click)="onNoClick()"
    >
      {{ data.type === 'warning' ? 'Hủy' : 'Hủy bỏ' }}
    </button>
    <button
      class="flex-1 bg-current text-white"
      *ngIf="data.isBasic"
      mat-button
      [mat-dialog-close]="data.data"
      cdkFocusInitial
    >
      {{ data.type === 'warning' ? 'Xóa' : ' Đồng ý' }}
    </button>
  </div>
</div>
