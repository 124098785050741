import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankAccId'
})
export class BankAccIdPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return (
      value
        .slice(0, -4)
        .replace(/(\d)(?=(\d{4})+$)/g, '$1 ')
        .replace(/[0-9a-zA-Z]/g, '*')
        .trim() +
      ' ' +
      value.slice(-4)
    );
  }
}
