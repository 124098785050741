<div class="content-dialog d-flex-column-center">
  <div class="header-dialog">
    <h1 mat-dialog-title>Chúc mừng</h1>
  </div>
  <div class="content d-flex-column-center" mat-dialog-content>
    <div class="congrats-icon">
      <mat-icon [inline]="true">check_circle</mat-icon>
    </div>
    <div class="message">
      {{ data.message }}
    </div>
  </div>

  <div mat-dialog-actions style="align-items: center; justify-content: center">
    <button mat-raised-button (click)="gotoDashboard()" class="btn-color-brand">
      Về trang chủ
    </button>
  </div>
</div>
