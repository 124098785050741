// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_host: 'https://dev.api.table.casso.vn',
  api_vietqr: 'https://api.vietqr.io',
  api_vietinBank_oauth2: 'https://webdemo.vietinbank.vn',
  vietinBank_client_id: 'khaianh1',
  zalo_permission_url: 'https://oauth.zaloapp.com/v4/permission',
  zalo_app_id: '362765557882983952',
  zalo_state: 'casso_zalo',
  google_sheet_oauth_scope:
    'email openid https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets',
  google_sheet_oauth_client_id:
    '883301520408-2p5spvbc3ravjnc8irufn8sp1dlaqquk.apps.googleusercontent.com',
  google_sheet_oauth_response: 'code',
  google_sheet_oauth_prompt: 'consent',
  google_sheet_oauth_uri: 'https://accounts.google.com/o/oauth2/v2/auth',
  lark_app_id: 'cli_a47f097d40f89009',
  lark_base_uri: 'https://open.larksuite.com/open-apis/authen/v1/index',
  casso_public_url: 'https://dev.public.casso.vn',
  microsoft_excel_oauth_scope: 'files.readwrite.all user.read offline_access',
  microsoft_excel_oauth_client_id: 'ad9f167b-2d3c-48bc-966c-cc5f92343bff',
  microsoft_excel_oauth_response: 'code',
  microsoft_excel_oauth_url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  bank_logo_url: 'https://img.bankhub.dev/rounded/',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
