import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auto-open-menu',
  template: `
    <div
      id="id"
      [matMenuTriggerFor]="menu"
      #menuTrigger="matMenuTrigger"
      (mouseenter)="mouseEnter(menuTrigger)"
      (mouseleave)="mouseLeave(menuTrigger)"
    >
      <ng-content select="[trigger]"></ng-content>
    </div>
    <div
      style="visibility: hidden; position: fixed"
      [style.left]="profiletMenuPosition.x"
      [style.top]="profiletMenuPosition.y"
      [matMenuTriggerFor]="menu"
      #menuTrigger2="matMenuTrigger"
      (mouseenter)="mouseEnter(menuTrigger2)"
      (mouseleave)="mouseLeave(menuTrigger2)"
    ></div>
    <mat-menu #menu="matMenu" [hasBackdrop]="false" xPosition="before" yPosition="above">
      <div (mouseenter)="mouseEnter(menuTrigger)" (mouseleave)="mouseLeave(menuTrigger)">
        <ng-content select="[content]"></ng-content>
      </div>
    </mat-menu>
  `
})
export class AutoOpenMenuComponent {
  timedOutCloser;
  @Input() id: string;
  profiletMenuPosition: any = { x: '0px', y: '0px' };

  constructor() {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const profileMenuSelector = document.querySelector(`#id`) as any;

    if (profileMenuSelector) {
      this.profiletMenuPosition.x = profileMenuSelector.offsetWidth + 4 + 'px';
      this.profiletMenuPosition.y =
        profileMenuSelector.offsetHeight + profileMenuSelector.offsetTop + 'px';
    }
    console.log(this.profiletMenuPosition);
  }
  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }
}
