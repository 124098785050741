import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { BusinessService } from 'src/app/modules/core/services/business.service';
import { UserService } from 'src/app/modules/core/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPassForm: FormGroup;

  key: string;
  private sub: any;
  isExpiredRePassToken: boolean;
  isResetPassword = false;
  isSimilarOldPass = false;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private businessService: BusinessService
  ) {}

  ngOnInit() {
    if (this.userService.checkLoggedUser()) {
      this.router.navigate(['portfolio']);
    }

    this.resetPassForm = this.formBuilder.group(
      {
        newPW: ['', Validators.compose([Validators.required, this.validateStrengthPassword])],
        confirmNewPW: ['', Validators.compose([Validators.required, this.validateStrengthPassword])]
      },
      {
        validator: this.comparePassword
      }
    );
    this.sub = this.activateRoute.queryParams.subscribe((params) => {
      if (params['key'] != null) {
        this.key = params['key'];
      }
      // console.log(this.key)
    });

    this.userService.validateRePassToken(this.key).subscribe((res) => {
      // console.log(res)
      if (res.error == 0) {
        this.isExpiredRePassToken = false;
      } else {
        this.isExpiredRePassToken = true;
      }
    });
  }

  newPassword() {
    const pass_hash = CryptoJS.SHA512(this.resetPassForm.value.newPW).toString();
    // console.log(pass_hash)
    this.userService.newPassword(pass_hash, this.key).subscribe((res) => {
      if (res.error === 0) {
        this.isResetPassword = true;
        if (this.userService.checkLoggedUser()) {
          this.logOut();
        }
      } else if (res.error === 305) {
        this.isSimilarOldPass = true;
      } else {
        alert('reset password fail');
      }
    });
  }

  comparePassword = (c: AbstractControl) => {
    this.isSimilarOldPass = false;
    const v = c.value;
    const ok1 = v.newPW === v.confirmNewPW;
    return ok1
      ? null
      : {
          passwordnotmatch: true
        };
  };

  validateStrengthPassword(controls: AbstractControl) {
    const password = controls.value;
    const regexStrPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    return regexStrPass.test(password) ? null : { passInvalid: true };
  }

  logOut(): void {
    this.userService.signOut().subscribe();
    const user = this.userService.getLocalLoggedUser();
    user.access_token_expired = new Date(moment().subtract(2, 'hour').format());
    this.userService.setLocalLoggedUser(user);
    this.userService.clearLoggedUser();
    this.businessService.resetCurrentBussiness();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
