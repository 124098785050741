import { RulePanelApplyComponent } from './../rule-panel-apply/rule-panel-apply.component';
import { NgDialogAnimationService, MatDialogConfig } from 'ng-dialog-animation';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { BusinessService } from '../../../../../../core/services/business.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, TAB } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
@Component({
  selector: 'app-rule-panel',
  templateUrl: './rule-panel.component.html',
  styleUrls: ['./rule-panel.component.css']
})
export class RulePanelComponent implements OnInit {
  businessId: any;
  banks: any;
  form: FormGroup;
  formcoditionarr: FormArray;
  formactionarr: FormArray;
  checked = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, TAB];
  rule_logic: any;
  list_sub_types: any = [];
  sub_types = [];
  val_memo = '';
  keyVal: Observable<string[]>;
  isInsert: boolean;
  private debounce = 400;
  @ViewChild('inputChips') inputChips: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  isInsertDisabled = false;
  isEditDisabled = false;
  private unSubObservables: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuiler: FormBuilder,
    private businessService: BusinessService,
    public dialogRef: MatDialogRef<RulePanelComponent>,
    public dialog: NgDialogAnimationService,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.checked = true;
    this.businessId = this.businessService.currentBussiness.id;
    this.getBankAccs(this.businessId);

    this.businessService.ruleConfig.subscribe((res) => {
      if (res && res.hasOwnProperty('codition')) {
        this.rule_logic = this.cloneObject(res);
      } else {
        this.businessService.getRuleConfig(this.businessId).subscribe((res) => {
          if (res.error === 0) {
            this.businessService.setRuleConfig(res.data);
          }
        });
      }
    });
    this.rule_logic.date_modifier = this.convertRuleLogic(
      this.rule_logic.date_modifier,
      'date_modifier'
    );
    this.rule_logic.scope = this.convertRuleLogic(this.rule_logic.scope, 'scope');
    this.rule_logic.types = this.convertRuleLogic(this.rule_logic.types, 'types');
    this.rule_logic.codition.amount.comparer = this.convertRuleLogic(
      this.rule_logic.codition.amount.comparer,
      'amount'
    );
    this.rule_logic.codition.description.comparer = this.convertRuleLogic(
      this.rule_logic.codition.description.comparer,
      'description'
    );

    if (this.item.data) {
      if (this.item.data.id) {
        this.isInsert = false;
      } else {
        this.isInsert = true;
      }
    } else {
      this.isInsert = true;
    }

    if (this.item.data) {
      this.fromConfig(this.item.data);
      this.setValueCondition(this.item.data.coditions);
      this.setValueAction(this.item.data.actions);
    } else {
      this.fromConfig();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes.hasOwnProperty('data'));
  }

  getBankAccs(businessId: number): void {
    const getAllBankAccSub = this.businessService.getAllBankAcc.subscribe((res) => {
      if (res === null) {
        this.businessService.getBankAccs(businessId).subscribe((res) => {
          if (res.error == 0) {
            this.businessService.setBankAccs(res.data);
          }
        });
      } else {
        this.banks = res;
      }
    });

    this.unSubObservables.push(getAllBankAccSub);
  }

  fromConfig(item: any = { status: 1 }) {
    const config = {
      round: [item.round || 0],
      memo: [item.memo || ''],
      scope_money: [item.scope_money || 'all'],
      scope_bank_acc: [item.scope_bank_acc || 'all'],
      codition_use_and: [item.codition_use_and || 1],
      conditions: this.formBuiler.array([this.createCondition()]),
      actions: this.formBuiler.array([this.createAction()]),
      action_split_use_percent: [item.action_split_use_percent || 1]
    };
    this.form = this.formBuiler.group(config);
  }
  onKey(evt) {
    const index = this.form.value['conditions'].findIndex(
      (condition) => condition.field === 'description'
    );
    if (index >= 0) {
      ((this.form.get('conditions') as FormArray).at(index).get('val') as FormArray).push(
        this.initName(evt.target.value.trim())
      );
    }
  }
  //start set from value codition
  get conditionControls() {
    return this.form.get('conditions')['controls'];
  }

  createCondition(): FormGroup {
    return this.formBuiler.group({
      field: new FormControl('description', [Validators.required]),
      comparer: new FormControl('include_any', [Validators.required]),
      val: new FormArray([])
    });
  }

  setValueCondition(item: any = []) {
    const formArray = new FormArray([]);
    for (const x of item) {
      formArray.push(
        this.formBuiler.group({
          field: x.field || new FormControl('', [Validators.required]),
          comparer: x.comparer || new FormControl('', [Validators.required]),
          val: x.field === 'amount' ? new FormControl(x.val) : this.formBuiler.array(x.val)
        })
      );
    }
    this.form.setControl('conditions', formArray);
  }

  addCondition(): void {
    this.formcoditionarr = this.form.get('conditions') as FormArray;
    this.formcoditionarr.push(this.createCondition());
  }

  removeCondition(i: number) {
    (this.form.get('conditions') as FormArray).removeAt(i);
    // this.formcoditionarr.removeAt(i);
  }

  initName(name: string): FormControl {
    return this.formBuiler.control(name);
  }

  addValCondition(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    if (value.trim() || value.trim() !== '') {
      ((this.form.get('conditions') as FormArray).at(index).get('val') as FormArray).push(
        this.initName(value.trim())
      );

      this.form.get('conditions').markAsDirty();
    }
    if (input) {
      input.value = '';
    }
  }

  removeValCondition(val: string, i: number, conditionIndex: number): void {
    ((this.form.get('conditions') as FormArray).at(i).get('val') as FormArray).removeAt(
      conditionIndex
    );

    this.form.get('conditions').markAsDirty();
  }

  changeSelect(event, index) {
    switch (event.value) {
      case 'amount': {
        const conditions = this.form.get('conditions') as FormArray;
        const condition = conditions.at(index) as FormGroup;
        condition.setControl('val', new FormControl());
        break;
      }
      case 'description': {
        const conditions = this.form.get('conditions') as FormArray;
        const condition = conditions.at(index) as FormGroup;
        condition.setControl('val', this.formBuiler.array([]));
        break;
      }
      default:
        break;
    }
  }
  //end set codition
  //start set actions

  get ActionControls() {
    return this.form.get('actions')['controls'];
  }

  createAction(): FormGroup {
    return this.formBuiler.group({
      date_modifier: new FormControl('happen_date', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      sub_type: new FormControl('', [Validators.required, Validators.pattern(/^[^,]*$/)]),
      partner_code: new FormControl(''),
      price: new FormControl('')
    });
  }

  setValueAction(items: any = []) {
    const formArray = new FormArray([]);
    for (const x of items) {
      formArray.push(
        this.formBuiler.group({
          date_modifier: x.date_modifier,
          type: x.type,
          sub_type: x.sub_type,
          partner_code: x.partner_code,
          price: x.price
        })
      );
    }
    this.form.setControl('actions', formArray);
  }

  addAction(): void {
    this.formactionarr = this.form.get('actions') as FormArray;
    this.formactionarr.push(this.createAction());
  }

  removeAction(i: number) {
    this.formactionarr.removeAt(i);
  }

  getDataText(id) {
    let subTypes;
    const datatype = [];
    const data_list = [];
    this.businessService.availableSubtypes$.subscribe((res) => {
      subTypes = res.map((subtype) => {
        const data = { sub_type: subtype.sub_type, type: subtype.type };
        return data;
      });
      subTypes.forEach((data) => {
        if (data.sub_type != null) datatype.push(data);
      });
    });
    for (let i = 0; i < datatype.length; i++) {
      if (datatype[i].type == id) {
        data_list.push(datatype[i]);
      }
    }
    this.list_sub_types = data_list;
  }

  onInsertRule() {
    this.isInsertDisabled = true;

    this.businessService.addRule(this.form.value, this.businessId).subscribe((res) => {
      this.isInsertDisabled = false;

      if (res.error === 0) {
        if (this.checked == true) {
          this.openRulePanelApplyDialog({
            data: res.data.crud.insertId,
            check: this.checked,
            event: 'insert'
          });
        } else {
          this.businessService.extendRules([res.data.currRule], false);
        }

        this.dialogRef.close(true);
      } else {
        this.toastrService.error('add rule error');
      }
    });
  }

  onEditRule() {
    this.isEditDisabled = true;
    this.dialogRef.close(true);
    const dirtyValues = this.getDirtyValues(this.form);

    if (this.isObjectEmpty(dirtyValues)) {
      if (this.checked == true) {
        this.openRulePanelApplyDialog({ data: this.item.data.id, check: this.checked });
        this.isEditDisabled = false;
      }
    } else {
      this.businessService
        .editRule(this.getDirtyValues(this.form), this.businessId, this.item.data.id)
        .subscribe((res) => {
          this.isEditDisabled = false;

          if (res.error === 0) {
            if (this.checked == true) {
              this.openRulePanelApplyDialog({
                data: this.item.data.id,
                check: this.checked,
                event: 'update'
              });
            }
          } else {
            this.toastrService.error('Cập nhật quy tắc thất bại!');
          }
        });
    }
  }

  openRulePanelApplyDialog(data: Object = {}) {
    const previewDialog = this.dialog.open(
      RulePanelApplyComponent,
      this.getDialogConfig({
        ...data
      })
    );

    previewDialog.afterClosed().subscribe((result) => {
      if (result && result.event === 'back') {
        this.dialog.open(RulePanelComponent, this.getDialogConfig(result.data));
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent, index: number): void {
    this.inputChips.nativeElement.value = '';
    const option = event.option;
    const value = option.value;
  }

  getFullValCondition(e) {
    // console.log(e.target);
    this.keyVal = e.target.value;
  }
  changeApply(evt) {
    this.checked = evt.checked;
  }

  getDialogConfig(data) {
    const dialogConfig: MatDialogConfig = {};

    dialogConfig['data'] = { ...data };
    dialogConfig.disableClose = false;
    dialogConfig.animation = {
      to: 'left',
      incomingOptions: {
        keyframeAnimationOptions: {
          duration: 300,
          easing: 'ease-in-out',
          endDelay: 300
        }
      },
      outgoingOptions: {
        keyframeAnimationOptions: { easing: 'ease-in-out', duration: 300 }
      }
    };
    dialogConfig.panelClass = 'medium-right-side-panel';
    dialogConfig.position = { rowEnd: '0' };

    return dialogConfig;
  }
  convertRuleLogic(logic, type) {
    const data = [];
    if (type == 'date_modifier') {
      for (let i = 0; i < Object.keys(logic).length; i++) {
        data.push({
          val: Object.keys(logic)[i],
          label: this.rule_logic.date_modifier[Object.keys(logic)[i]].label
        });
      }
    }
    if (type == 'scope') {
      for (let i = 0; i < Object.keys(this.rule_logic.scope).length; i++) {
        data.push({
          val: Object.keys(this.rule_logic.scope)[i],
          label: this.rule_logic.scope[Object.keys(this.rule_logic.scope)[i]]
        });
      }
    }
    if (type == 'types') {
      for (let i = 0; i < Object.keys(this.rule_logic.types).length; i++) {
        data.push({
          val: Number(Object.keys(this.rule_logic.types)[i]),
          label: this.rule_logic.types[Object.keys(this.rule_logic.types)[i]]
        });
      }
    }
    if (type == 'amount') {
      for (let i = 0; i < Object.keys(this.rule_logic.codition.amount.comparer).length; i++) {
        data.push({
          val: Object.keys(this.rule_logic.codition.amount.comparer)[i],
          label:
            this.rule_logic.codition.amount.comparer[
              Object.keys(this.rule_logic.codition.amount.comparer)[i]
            ].text
        });
      }
    }
    if (type == 'description') {
      for (let i = 0; i < Object.keys(this.rule_logic.codition.description.comparer).length; i++) {
        data.push({
          val: Object.keys(this.rule_logic.codition.description.comparer)[i],
          label:
            this.rule_logic.codition.description.comparer[
              Object.keys(this.rule_logic.codition.description.comparer)[i]
            ].text
        });
      }
    }

    return data;
  }

  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  cloneObject(obj) {
    const clone = {};

    for (const i in obj) {
      if (typeof obj[i] == 'object' && obj[i] != null) clone[i] = this.cloneObject(obj[i]);
      else clone[i] = obj[i];
    }
    return clone;
  }

  ngOnDestroy(): void {
    this.unSubObservables.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
