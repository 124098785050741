import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BusinessService } from '../../../../../core/services/business.service';
@Component({
  selector: 'app-predict-preview',
  templateUrl: './predict-preview.component.html',
  styleUrls: ['./predict-preview.component.css']
})
export class PredictPreviewComponent implements OnInit {
  businessId: number;
  public rowData: [] = [];

  transactionsMatch = [];
  transactionsUnmatch = [];
  lengthAll;
  @Input() idFillter: any;
  @Output() deleteFilter = new EventEmitter();
  selectedIndex = 0;
  value_Filler: any = [];
  isEmptyPreview: boolean;
  isProgressBar: boolean;

  constructor(private businessService: BusinessService) {}

  ngOnInit(): void {
    this.businessId = this.businessService.currentBussiness.id;
    this.isProgressBar = true;
    this.businessService.getPredictReview(this.businessId).subscribe((res) => {
      this.isProgressBar = false;
      if (res.data.length <= 0) {
        this.isEmptyPreview = true;
      }

      this.rowData = res.data;
      this.rowData = res.data.map((row) => {
        return row.matches.length > 0 ? row : { ...row, matches: null };
      });

      this.rowData.forEach((rule) => {
        if (rule['matches']) {
          this.transactionsMatch.push(rule);
        } else {
          this.transactionsUnmatch.push(rule);
        }
      });
      this.transactionsMatch.forEach((data) => {
        const matches = data.matches;
        matches.forEach((element) => {
          if (element.id == this.idFillter) {
            this.value_Filler = element;
          }
        });
      });
      if (this.idFillter) {
        this.selectedIndex = 1;
      } else {
        this.selectedIndex = 0;
      }
    });
  }

  deleteIdFilter(evt) {
    this.idFillter = evt;
    this.deleteFilter.emit(evt);
  }
  selectedTabChange(evt: MatTabChangeEvent) {
    if (evt.index != 1) {
      this.idFillter = null;
      this.deleteFilter.emit(null);
      this.value_Filler = null;
    }
  }
}
