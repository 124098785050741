<div class="login-container">
  <div class="login-content card-white-shadow">
    <div class="login-title">
      <div style="margin-bottom: 24px">
        <div class="logo">
          <img src="../../../assets/img/icon.png" style="height: 64px" alt="casso logo png" />
        </div>
        <div class="logo">
          <img src="../../../assets/img/casso-logo-new.png" style="width: 64px" />
        </div>
      </div>
    </div>
    <ng-container *ngIf="checkLogin == 0 && !isRegister; then first; else second"></ng-container>

    <ng-template #first>
      <div class="form-content">
        <div class="w-100 btn-login">
          <button
            style="background-color: #4285f4"
            type="button"
            mat-stroked-button
            (click)="signInWithGoogle()"
          >
            <img src="../../../assets/img/google.jpg" width="26px" alt="" />
            <span style="margin-left: 16px; color: #fff"> Đăng nhập bằng Google</span>
          </button>
        </div>
        <div class="w-100 btn-login" style="margin-top: 16px">
          <button
            style="background-color: #2f2f2f"
            type="button"
            mat-stroked-button
            (click)="signInWithMicrosoft()"
          >
            <img src="../../../assets/img/microsoft.png" width="26px" alt="" />
            <span style="margin-left: 16px; color: #fff">Đăng nhập bằng Microsoft</span>
          </button>
        </div>
        <div class="oauth">
          <div class="label">
            <span>Hoặc</span>
          </div>
        </div>
        <div class="error">
          <div *ngIf="isLoginFail" class="login-error">
            <span> Mật khẩu hoặc email đã nhập không chính xác.<br />Vui lòng thử lại</span>
          </div>
          <div *ngIf="isNotActive">
            <ng-container *ngTemplateOutlet="notActive"></ng-container>
          </div>
          <div *ngIf="errorInvalid" class="login-error">{{ errorInvalid }}</div>
        </div>

        <form class="form-login" [formGroup]="loginForm">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput autocomplete="email" />
            <mat-error *ngIf="loginForm.hasError('required', ['email'])"
              >Email không được để trống</mat-error
            >
            <mat-error
              *ngIf="
                !loginForm.hasError('required', ['email']) &&
                loginForm.hasError('emailInvalid', ['email'])
              "
              >Email không hợp lệ!</mat-error
            >
          </mat-form-field>
          <br />

          <mat-form-field appearance="outline">
            <mat-label>Mật khẩu</mat-label>
            <input
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              matInput
              autocomplete="current-password"
            />
            <mat-error *ngIf="loginForm.hasError('required', ['password'])"
              >Mật khẩu không được để trống</mat-error
            >
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon style="color: #555">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
          </mat-form-field>
          <mat-error class="text-sm" *ngIf="wrong_password_tried >= 3 && wrong_password_tried < 5"
            >Bạn đã nhập sai mật khẩu {{ wrong_password_tried }} lần. Nếu nhập sai mật khẩu 5 lần
            tài khoản của bạn sẽ bị khóa.
          </mat-error>
          <br />
          <button
            class="login"
            type="submit"
            (click)="login()"
            [disabled]="lock"
            mat-raised-button
            color="primary"
          >
            Đăng nhập
            <mat-spinner *ngIf="lock" [diameter]="30"></mat-spinner>
          </button>
        </form>
        <div class="btn-forget-pw">
          <a id="resetPassword" routerLink="/forgot-password">Quên mật khẩu</a>
        </div>
        <div class="btn-register align-item-center">
          <span>Bạn chưa có tài khoản? </span>
          <a id="register" routerLink="/register"> &nbsp;Đăng ký</a>
        </div>
      </div>
    </ng-template>

    <ng-template #second>
      <div style="margin-bottom: 24px" *ngIf="!isRegister">
        <div
          style="display: flex; align-items: center; justify-content: center"
          *ngIf="userService.getLocalLoggedUser().name"
        >
          <span>Chào</span>
          <span style="padding: 0 8px; font-weight: 600">{{
            userService.getLocalLoggedUser().name
          }}</span>
          <span>quay trở lại</span>
        </div>
      </div>

      <div class="error">
        <div *ngIf="isLoginFail" class="login-error">
          <span>Mật khẩu hoặc email đã nhập không chính xác.<br />Vui lòng thử lại</span>
        </div>
        <div *ngIf="isNotActive">
          <ng-container *ngTemplateOutlet="notActive"></ng-container>
        </div>
        <div *ngIf="errorInvalid" class="login-error">{{ errorInvalid }}</div>
      </div>

      <div *ngIf="!isRegister">
        <div
          class="w-100 btn-login"
          *ngIf="userService.getLocalLoggedUser()['provider'] == 'google'"
        >
          <button
            style="background-color: #4285f4"
            type="button"
            mat-stroked-button
            (click)="signInWithGoogle()"
          >
            <img src="../../../assets/img/google.jpg" width="26px" alt="" />
            <span style="margin-left: 16px; color: #fff">Tiếp tục đăng nhập bằng Google</span>
          </button>
        </div>
        <div
          class="w-100 btn-login"
          style="margin-top: 16px"
          *ngIf="userService.getLocalLoggedUser()['provider'] == 'microsoft'"
        >
          <button
            style="background-color: #2f2f2f"
            type="button"
            mat-stroked-button
            (click)="signInWithMicrosoft()"
          >
            <img src="../../../assets/img/microsoft.png" width="26px" alt="" />
            <span style="margin-left: 16px; color: #fff">Tiếp tục đăng nhập bằng Microsoft</span>
          </button>
        </div>
        <div
          class="w-100 btn-login"
          style="margin-top: 16px"
          *ngIf="userService.getLocalLoggedUser()['provider'] == 'email'"
        >
          <form class="form-login" [formGroup]="loginForm">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput autocomplete="email" />
              <mat-error *ngIf="loginForm.hasError('required', ['email'])"
                >Email không được để trống</mat-error
              >
              <mat-error
                *ngIf="
                  !loginForm.hasError('required', ['email']) &&
                  loginForm.hasError('emailInvalid', ['email'])
                "
              >
                Email không hợp lệ!
              </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field appearance="outline">
              <mat-label>Mật khẩu</mat-label>
              <input
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                matInput
                autocomplete="current-password"
              />
              <mat-error *ngIf="loginForm.hasError('required', ['password'])"
                >Mật khẩu không được để trống</mat-error
              >
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon style="color: #555">{{
                  hide ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
            </mat-form-field>
            <mat-error class="text-sm" *ngIf="wrong_password_tried >= 3 && wrong_password_tried < 5"
              >Bạn đã nhập sai mật khẩu {{ wrong_password_tried }} lần. Nếu nhập sai mật khẩu 5 lần
              tài khoản của bạn sẽ bị khóa.
            </mat-error>
            <br />
            <button
              class="login"
              type="submit"
              (click)="login()"
              [disabled]="lock"
              mat-raised-button
              color="primary"
            >
              Đăng nhập
              <mat-spinner *ngIf="lock" [diameter]="30"></mat-spinner>
            </button>
          </form>
        </div>
        <div *ngIf="userService.getLocalLoggedUser()['provider'] == 'email'" class="btn-forget-pw">
          <a id="resetPassword" routerLink="/forgot-password">Quên mật khẩu</a>
        </div>
        <div class="oauth">
          <div class="label">
            <span>Hoặc</span>
          </div>
        </div>
        <div class="w-100 btn-login">
          <button type="button" mat-stroked-button (click)="signInWithOrther()">
            <span>Đăng nhập bằng tài khoản khác</span>
          </button>
        </div>
        <div class="btn-register align-item-center">
          <span>Bạn chưa có tài khoản? </span>
          <a id="register" routerLink="/register">&nbsp;Đăng ký</a>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="isRegister == true">
      <div style="text-align: center; padding-bottom: 8px">
        Email này chưa được đăng ký trên hệ thống. Nếu bạn muốn đăng ký tài khoản với email này thì
        vui lòng nhập số điện thoại và bấm nút Đăng ký.
      </div>

      <form class="form-login" [formGroup]="phoneForm">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Số điện thoại</mat-label>
            <input
              formControlName="phone"
              matInput
              autocomplete="phone"
              (keypress)="keyPress($event)"
            />
            <mat-error *ngIf="phoneForm.get('phone').hasError('required')">
              Vui lòng nhập số điện thoại.
            </mat-error>
            <mat-error *ngIf="phoneForm.get('phone').hasError('pattern')">
              Số điện thoại không chính xác.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Mã giới thiệu (không bắt buộc)</mat-label>
            <input formControlName="ref" matInput autocomplete="ref" />
          </mat-form-field>
        </div>
        <div style="text-align: center; padding-bottom: 16px">
          <span>
            Bằng việc đăng ký tài khoản, tôi chấp nhận
            <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
              >Chính sách bảo mật</a
            >
            và
            <a target="_blank" href="https://casso.vn/thoa-thuan-su-dung-phan-mem/"
              >Thỏa thuận sử dụng</a
            >
            của Casso
          </span>
        </div>
        <div class="action">
          <button class="skip" type="button" (click)="skip()" mat-button style="color: #15ab64">
            Về trang đăng nhập
          </button>
          <button
            class="submit-phone"
            [disabled]="phoneForm.invalid || shouldDisplaySpinner"
            type="button"
            (click)="signUp()"
            mat-raised-button
          >
            Đăng ký
            <mat-spinner *ngIf="shouldDisplaySpinner" [diameter]="30"></mat-spinner>
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<ng-template #notActive>
  <div class="login-error">
    Tài khoản chưa được kích hoạt.
    <button
      style="color: #15ab64; margin-top: 4px"
      mat-raised-button
      (click)="resendActivationEmail()"
    >
      Gửi lại mail kích hoạt
    </button>
  </div>
</ng-template>
