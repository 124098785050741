import { Pipe, PipeTransform } from '@angular/core';
import { BANK_ACCOUNT_CONNECT_STATUS } from 'src/app/modules/core/constant';
import { BankAcc } from 'src/app/modules/core/models/business';

@Pipe({
  name: 'bankAccStatus'
})
export class BankAccStatusPipe implements PipeTransform {
  transform(value: BankAcc, args?: any): any {
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.PAUSED) {
      return 'Sai mật khẩu';
    }
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.CONNECTED) {
      return 'Đang kết nối';
    }
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.UNPAID) {
      return 'Chưa thanh toán';
    }
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.CLOSED) {
      return 'Tạm dừng';
    }
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.OTP_REQUIRED) {
      return 'Yêu cầu OTP';
    }
    if (value.connect_status === BANK_ACCOUNT_CONNECT_STATUS.TURN_OFF_LOGIN_FROM_WEB) {
      return 'Tắt đăng nhập';
    }

    return 'Ngừng kết nối';
  }
}
