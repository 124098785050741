import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarBussinessComponent } from '../sidebar-bussiness/sidebar-bussiness.component';

@Component({
  selector: 'app-sidebar-portfolio',
  templateUrl: './sidebar-portfolio.component.html',
  styleUrls: ['./sidebar-portfolio.component.css']
})
export class SidebarPortfolioComponent extends SidebarBussinessComponent {
  showMeWhenUrlIs = '/portfolio';
  @Input() isExpandedSideNav: boolean;
  @Input() sidenav: MatSidenav;
  @Input() isMobile: boolean;
  @Output() logout = new EventEmitter<boolean>();

  closeSideNav() {
    if (this.isMobile) {
      this.sidenav.close();
    } else {
      this.sidenav.close();
      this.sidenav.open();
    }
  }
}
