<div class="loadding-data">
  <mat-progress-bar *ngIf="isProgressBar" mode="indeterminate"></mat-progress-bar>
</div>
<div class="example-container" *ngIf="data.length > 0">
  <mat-table
    matSort
    cdkDropList
    #table
    [dataSource]="dataSource"
    [cdkDropListData]="dataSource"
    (cdkDropListDropped)="onListDrop($event)"
    class="example-box px-2 pb-2 shadow rounded"
    [cdkDropListDisabled]="dragDisabled"
  >
    <ng-container matColumnDef="drop">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let element" style="cursor: move">
        <span (mousedown)="dragDisabled = false" class="material-icons-outlined text-[#15ab64]">
          rule
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.id }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="memo">
      <mat-header-cell *matHeaderCellDef> Tên </mat-header-cell>
      <mat-cell *matCellDef="let element" style="cursor: pointer" (click)="onFillterID(element)">
        <u>{{ element.memo }}</u>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="condition">
      <mat-header-cell *matHeaderCellDef> Nếu </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.condition_detail }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Thì </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.action_detail }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="matched">
      <mat-header-cell *matHeaderCellDef> Áp dụng </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.matched == null ? 0 : element.matched }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Trạng thái </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle
          ngDefaultControl
          [checked]="element.status === 1 ? true : false"
          (change)="onChange($event, element)"
        >
        </mat-slide-toggle>
        <!-- {{(element.status)==1?"Bật":"Tắt"}} -->
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef> Thao tác </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="h-6 border-0 border-[#0000000F] border-l border-solid"></div>
        <button
          class="btn-30"
          (click)="onInsRule(element)"
          mat-icon-button
          matTooltip="Sửa"
          matTooltipPosition="above"
        >
          <span class="material-icons-outlined hover:text-current text-[#BDBDBD]"> edit </span>
        </button>
        <button
          class="btn-30"
          (click)="onRemove(element)"
          mat-icon-button
          matTooltip="Xóa"
          matTooltipPosition="above"
        >
          <span class="material-icons-outlined hover:text-red-500 text-[#BDBDBD]"> delete </span>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag [cdkDragData]="row"></mat-row>
  </mat-table>
</div>
<div
  class="w-100 text-center bg-white rounded-lg"
  style="
    border: 1px dashed #0000001f;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  "
  *ngIf="isEmptyRule"
>
  <div style="padding-top: 20px">Bạn chưa nhập quy tắc nào!</div>
</div>
<div [ngClass]="isEmptyRule ? 'flex flex-col items-center' : ''">
  <div class="fixed-action-btn">
    <button class="!px-8 !py-0.5" mat-flat-button keyboard-create (click)="onInsRule()">
      Thêm quy tắc
    </button>
  </div>
  <div class="note flex items-center mt-4">
    <span class="material-icons-outlined text-current mr-2"> info </span>
    <span
      >Lưu ý: Mỗi giao dịch chỉ được xử lý bằng một quy tắc, là quy tắc đầu tiên khớp từ trên xuống
      dưới</span
    >
  </div>
</div>
