import { Intergration } from './settings';

export abstract class BasicResponse {
  error: number;
  message: string;
  data: any;
}

export class Business {
  id: number;
  name: string;
  full_name: string;
  tax_code: string;
  address: string;
  invoice_email: string;
  website: string;
  logo: string;
  business_share_id: number;
  amount: number;
  stock_price: number;
  total_stock: number;
  stock_value: number;
  deposited_total: number;
  estimated_charged: number;
  last_charged_time: Date;
  balance: number;
  free_balance: number;
  free_balance_expired: Date;
  feature_enable_partner: number;
  feature_enable_subtype: number;
  feature_enable_order_sale: number;
  integrated_discord_enable: number;
  integrated_discord_webhook_url: string;
  integrated_email: string;
  integrated_email_enable: number;
  integrated_slack_enable: number;
  integrated_slack_webhook_url: string;
  integrated_telegram_chat_id: string;
  integrated_telegram_enable: number;
  integrated_telegram_secure_token: string;
  integrated_webhook_enable: number;
  integrated_webhook_token: string;
  integrated_webhook_url: string;
  integrated_send_only_income: number;
  integrated_send_balance: number;
  role;
  embedded_enable: number;
  embedded_guid: string;
  embeded_config_json: string;
  plan_id: number;
  plan_option: number;
  subscribed_from: Date;
  subscribed_to: Date;
  usage_period_id: number;
  payment_method: string;
  plan?: Plan;
  limits?: {
    [x: string]: Limit;
  };
  usage?: UsagePeriod;
  product_avalable_table: string;
}

export class Plan {
  id: number;
  name: string;
  is_offical: number;
  description: string;
  limit_bank_account: number;
  limit_integration: number;
  created_at: Date;
}

export interface Limit {
  count: number;
  limit: number;
  percent: number;
}

export interface UsagePeriod {
  id: number;
  business_id: number;
  from_date: Date;
  to_date: Date;
  credit_used: number;
  plan_id: number;
  plan_option: number;
  paid: number;
}

export interface Pricing {
  plan_id: number;
  option: number;
  price: number; //monthly
  price_discount: number; //annually;
  differenceTwoPlan?: DifferenceTwoPlan;
  months?: number;
  duration?: string;
  voucher: VoucherDiscount;
  refundPlan?: RefundPlan;
}

export interface DifferenceTwoPlan {
  months: number;
  remainingAmount: number;
  durationSubscribed: string;
  pricePerMonth: number;
}

export interface RefundPlan {
  priceCurrPlan: number;
  percentRefund: number;
  amountRefund: number;
}

export class BusinessResponse {
  error: number;
  message: string;
  data: BusinessInfo;
}

export class BusinessInfo {
  business: Business;
  // availableMonths: AvailableMonth[];
  availableSubtypes: AvailableSubtype[];
  availablePartners: AvailablePartner[];
}

export class AvailableMonth {
  month_report: number;
  records: number;
}
export class AvailableSubtype {
  sub_type: string;
  type: number;
  uses: number;
}
export class AvailablePartner {
  partner_code: string;
  type: number;
  sub_type: string;
  uses: number;
}

export class BusinessIndirect {
  holderId: number;
  holderName: string;
  holds: Business[];
}

export class BusinessGroup {
  direct: Business[];
  indirect: BusinessIndirect[];
}

export class BusinessFilters {
  start: Date;
  end: Date;
}

export class PortfolioResponse {
  error: number;
  message: string;
  data: BusinessGroup;
}

export class Transaction {
  id: number;
  tid: string;
  bank_acc_id: number;
  description: string;
  amount: number;
  cusum_balance: number;
  when: Date;
  error: number;
  status: number;
  error_report_by: number;
  bank_sub_acc_id: string;
}

export class TransactionsResponse {
  error: number;
  message: string;
  data: Transaction[];
}

export class FinancialRecord {
  id: number;
  happen_date: Date;
  month_report: number;
  description: string;
  type: number;
  sub_type: string;
  partner_code: string;
  order_sale_id: string;
  amount: number;
  transaction_id: number;
  cusum_balance: number;
}

export class TransactionResponse {
  error: number;
  message: string;
  data: TransactionInfo;
}

export class TransactionInfo {
  financials: FinancialRecord[];
  transaction: Transaction;
}

export class FinancialRecordsResponse {
  error: number;
  message: string;
  data: FinancialRecord[];
}
export class SumFinancialRecord {
  type: number;
  sub_type: string;
  sum: number;
}

export class SumFinancialRecords {
  types: SumFinancialRecord[];
  subtypes: SumFinancialRecord[];
}
export class SumFinancialRecordsResponse {
  error: number;
  message: string;
  data: SumFinancialRecords;
}

export class selectMonth {
  idx: number;
  month: number;
  year: number;
}
export class member {
  id: number;
  amount: number;
  email: string;
  name: string;
  holderUserId: number;
  holderBusinessId: number;
  role: number;
}
export class membersResponse {
  error: number;
  message: string;
  data: member[];
}
export class lastSync {
  last_sync: Date;
}
export class lastSyncResponse {
  error: number;
  message: string;
  data: lastSync[];
}
export class Crud {
  affectedRows: any;
  fieldCount: any;
  changedRows: number;
  info: string;
  insertId: any;
  serverStatus: any;
  warningStatus: any;
}
export class CrudResponse {
  error: number;
  message: string;
  data: { crud: Crud; transaction: Transaction };
}
export class OnlyCrudResponse {
  error: number;
  message: string;
  data: Crud;
}

export class createIntegrationData {
  affectedRows: any;
  fieldCount: any;
  changedRows: number;
  info: string;
  insertId: any;
  serverStatus: any;
  warningStatus: any;
  inviteCode: any;
}

export class createIntegrationResponse {
  error: number;
  message: string;
  data: createIntegrationData;
}

export class BankAccInsertInfo {
  bank_credential_id: number;
  bank_sub_accs: Crud[];
}
export class BankAccsCrudResponse {
  error: number;
  message: string;
  data: BankAccInsertInfo;
}
export class PredictFieldProb {
  val: any;
  prob: number;
}
export class PredictFieldInfo {
  types: PredictFieldProb[];
  sub_types: PredictFieldProb[];
  descriptions: PredictFieldProb[];
  happen_dates: PredictFieldProb[];
  amounts: PredictFieldProb[];
}

export class PredictInfo {
  predict_fields: PredictFieldInfo;
  predict_objects: any[];
}

export class ImportingPredictResponse {
  error: number;
  message: string;
  data: PredictInfo;
}
export class PublishReportDasboard {
  error: number;
  message: string;
  data: any[];
}

export class StatusTransaction {
  status: number;
  transId: number;
}

export class BankAcc {
  id: number;
  fs_uuid: string;
  bank_credential_id: number;
  business_id: number;
  bank_id: number;
  bank_user_name: string;
  bank_password: string;
  provider: string;
  bank_account_name: string;
  beginning_setting_date: Date;
  account_number: string;
  sync_interval_seconds: any;
  connect_status: number;
  last_sync: Date;
  beginning_balance: number;
  beginning_txn_date: Date;
  balance: number;
  credit_txn_total: number;
  credit_txn_amount: number;
  debit_txn_total: number;
  debit_txn_amount: number;
  ending_txn_date: Date;
  ending_balance: number;
  memo: string;
  'bank': Bank;
}
export class Bank {
  id: number;
  code_name: string;
  short_name: string;
  full_name: string;
  bank_name: string;
  abbreviation_name: string;
  bankhub_service_name: string;
  bank_type: string;
  image_logo: any;
  image_card: any;
  image_cover: any;
  active: number;
  color: string;
  speed: string;
  sync_method: string;
  note: string;
  linear_color: string;
  maintenance: Maintenance;
}

export class Maintenance {
  name: string;
  announcement: string;
  isMaintenance: boolean;
}

export class BankAccsResponse {
  error: number;
  message: string;
  data: BankAcc[];
}

export class LifeTimeReportRecord {
  segmentx: any;
  segmenty: any;
  sum_total: number;
  amount_total: number;
}

export class LifeTimeReportResponse {
  error: number;
  message: string;
  data: LifeTimeReportRecord[];
}

export class BankSubAcc {
  subAccId: string;
  beginDate: string;
  balance: number;
  currency: string;
}

export class CheckBankAccResponse {
  error: number;
  message: string;
  data: {
    subAccs: BankSubAcc[];
    requestId?: string;
  };
}
export class BankAccInfo {
  subAccId: string;
  position: number;
  account: string;
  balance: number;
  accountName?: string;
  accountDesc?: string;
}

export class TaskDetail {
  'bankAcc': number;
  'result': string;
  'task_id': string;
  'sub_acc_id': string;
}

export class TaskSyncResponse {
  error: number;
  message: string;
  data: TaskDetail[];
}

export class SyncBankAcc {
  bankAcc: number;
  inserted: number;
  error: number;
  result: string;
  status: string;
  transactions: any;
}

export class SyncBankAccsResponse {
  error: number;
  message: string;
  data: SyncBankAcc[];
}

export class SyncBankAccResponse {
  error: number;
  result: string;
  message: string;
  data: SyncBankAcc;
}

export class Response {
  error: number;
  message: string;
  data: any;
}

export class BankResponse {
  error: number;
  message: string;
  data: Bank[];
}

export class DateOption {
  label: string;
  subtract: number;
  value: string;
  type: string;
  isHighlight: boolean;
}

export class Rule {
  action_detail: string;
  action_split_use_percent: number;
  actions: any;
  business_id: number;
  codition_use_and: number;
  coditions: any;
  condition_detail: string;
  created: string;
  created_by: number;
  id: number;
  last_matched: number;
  matched: number;
  memo: string;
  order: number;
  round: number;
  scope_bank_acc: string;
  scope_money: string;
  status: number;
}

export class RuleResponse {
  error: number;
  message: string;
  data: Rule;
}

export class ReorderRulesResponse {
  error: number;
  message: string;
  data: DataReorderRule;
}

export class RuleCrud {
  id: number;
  result: Crud;
}
export class DataReorderRule {
  resultReorder: RuleCrud[];
  rulesReorder: Rule[];
}
export class PredictRules {
  error: number;
  message: string;
  data: any;
}
export class DeleteRule {
  error: number;
  message: string;
  data: any;
}
export class RuleConfig {
  error: number;
  message: string;
  data: any;
}
export class AddRuleResponse {
  error: number;
  message: string;
  data: {
    crud: Crud;
    currRule: Rule;
  };
}

export class EditRule {
  error: number;
  message: string;
  data: any;
}
export class Predict {
  matches: any;
  transaction: Transaction;
  rowHeight: number;
}

export class PredictReview {
  error: number;
  message: string;
  data: any;
}
export class ReportResponse {
  error: number;
  data: any;
}

export class Cashflow {
  segmentTime: number;
  segmentValue: string;
  count: number;
}

export class CashflowResponse {
  error: number;
  message: string;
  data: {
    maxCashflow: Cashflow[];
    minCashflow: Cashflow[];
  };
}
export class SuggestSaving {
  segmentValue: string;
  segmentTime: string;
}
export class SuggestSavingResponse extends BasicResponse {
  data: SuggestSaving[];
}

export interface BankAccPlan {
  plan: string;
  pricing: string;
  trial: string;
  label: string;
  perunit: string;
  value: number;
  charges: string[];
  features: string[];
  active: boolean;
}

export interface AuthorizationCodeResponse extends BasicResponse {
  data: {
    authorization_code: string;
  };
}

export interface BankAccRegisterResponse extends BasicResponse {
  data: {
    requestId?: string;
  };
}

export interface Event {
  id: number;
  transaction_id: number;
  webhook_id: number;
  schedule: Date;
  reschedule: Date;
  retried: number;
  status: string;
  created_at: Date;
  updated_at: Date;
}

export interface WebhookLog {
  id: number;
  event_id: number;
  event_status: string;
  http_status: number;
  error_message: string;
  body: string;
  response_time: Date;
  created_at: Date;
  event_reschedule: string;
}

export interface Paging<T> {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  prevPage: number;
  nextPage: number;
  records: T[];
}

export interface ActivitiesResponse extends BasicResponse {
  data: Paging<WebhookLog>;
}

export interface TransactionEvent {
  event_id: number;
  event_status: string;
  status: string;
  tid: string;
  description: string;
  when: Date;
  http_status: number;
  error_message: string;
  body: string;
  response_time: Date;
}
export interface TransactionEventsResponse extends BasicResponse {
  data: TransactionEvent[];
}

export interface PageTransactionEventsResponse extends BasicResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  records: TransactionEvent[];
}
export interface PricingResponse extends BasicResponse {
  data: Pricing;
}

export interface Order {
  id: number;
  planId: number;
  option: number;
  pricing: number;
  credit: number;
  duration: number;
  paidStatus: number;
  monthsSubscribed: number;
  usagePeriodResult?: Crud;
  newUsage: {
    plan?: Plan;
    limits?: {
      [x: string]: Limit;
    };
    usage?: UsagePeriod;
  };
  checkoutUrl?: string;
}

export interface OrderResponse extends BasicResponse {
  data: Order;
}

export interface IPaymentPeriod {
  price: number;
  months: number;
  value: string;
}

export interface IPaymentPeriods {
  annually: IPaymentPeriod;
  monthly: IPaymentPeriod;
}

export enum EPlan {
  Free = 1,
  Starter,
  Pro,
  Team,
  Company,
  Trial
}

export interface ILink {
  id: string;
  source: string;
  target: string;
}

export interface INode {
  id: string;
  label: string;
  bankAcc?: BankAcc;
  integration?: Intergration;
}

export interface IConnectionResponse extends BasicResponse {
  data: {
    links: ILink[];
    nodes: INode[];
  };
}

export interface CardLinkAlePayResponse {
  error: number;
  message: string;
  data: {
    url: string;
    code: string;
    message: string;
  };
}

export interface CreditCard {
  id: number;
  user_id: number;
  business_id: number;
  card_number: string;
  card_holder_email: string;
  card_holder_name: string;
  card_expire_month: string;
  card_expire_year: string;
  payment_method: string;
  bank_code: string;
  is_default: number;
}

export interface ListCreditCardsResponse {
  error: number;
  message: string;
  data: CreditCard[];
}

export interface CreditCardResponse {
  error: number;
  message: string;
  data: CreditCard;
}

export interface OneClickPaymentAlepayResponse {
  error: number;
  message: string;
  data: {
    code: string;
    message: string;
    checkoutUrl?: string;
    orderCode?: string;
    success?: boolean;
    transactionCode?: string;
  };
}

export interface GgSheetsConfig {
  step: string;
  featureName: string;
  extendField: string;
  title: string;
  isFinishStep: boolean;
  lstValue: ValueGgSheet[];
  cacheValue?: ValueGgSheet[];
  valueLabel?: string;
  displaySearchPanel?: boolean;
  disableOpen?: boolean;
  page?: {
    count: number;
    disableLoadMore: boolean;
    isLoading?: boolean;
  };
  refresh?: {
    isLoading: boolean;
    disable?: boolean;
  };
}

export interface MSExcelsConfig {
  step: string;
  featureName: string;
  extendField: string;
  title: string;
  isFinishStep: boolean;
  lstValue: ValueGgSheet[];
  cacheValue?: ValueGgSheet[];
  valueLabel?: string;
  displaySearchPanel?: boolean;
  disableOpen?: boolean;
  page?: {
    count: number;
    disableLoadMore: boolean;
    isLoading?: boolean;
  };
  refresh?: {
    isLoading: boolean;
    disable?: boolean;
  };
}

export interface ValueGgSheet {
  id: string;
  name: string;
  owner?: boolean;
}

export interface MetadataGoogleSheet {
  drive: {
    label: string;
  };
  spreadsheet: {
    label: string;
  };
  worksheet: {
    label: string;
  };
}

export interface MetadataMicrosoftExcel {
  drive: {
    label: string;
  };
  spreadsheet: {
    label: string;
  };
  worksheet: {
    label: string;
  };
}

export interface QuantityAccountAuthentication {
  total: number;
  channel: string;
  logo: string;
}

export interface AuthenticationAccount {
  id: number;
  account_name: string;
  label: string;
  isChecking?: boolean;
  showConnect?: boolean;
  connect?: boolean;
}

export interface LarkBaseConfig {
  step: string;
  featureName: string;
  extendField: string;
  title: string;
  isFinishStep: boolean;
  lstValue: ValueGgSheet[];
  cacheValue?: ValueGgSheet[];
  valueLabel?: string;
  displaySearchPanel?: boolean;
  disableOpen?: boolean;
  page?: {
    count: number;
    disableLoadMore: boolean;
    isLoading?: boolean;
  };
  refresh?: {
    isLoading: boolean;
    disable?: boolean;
  };
}

export interface MetadataLarkBase {
  drive: {
    label: string;
  };
  spreadsheet: {
    label: string;
  };
  worksheet: {
    label: string;
  };
}
export interface VoucherDiscount {
  code: string;
  description: string;
  discount_rate: number;
  discount_max: number;
}

export interface ReportMonthsResponse extends BasicResponse {
  data: AvailableMonth[];
}
