export class User {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  name: string;
  phone_number: string;
  password_hash: string;
  invited: number;
  partner_type: number;
  partner_level: number;
  ref_id: string;
  reward_first_paid_ratio: number;
  reward_lifetime_ratio: number;
  active: number;
  create_time: Date;
  last_join: Date;
  access_token: string;
  access_token_expired: Date;
  wrong_password_tried: number;
  business_id_lastest: number;
  amount_bank_connect: number;
  payout_bank_name: string;
  payout_bank_account_number: number;
  payout_bank_account_name: string;
  payout_money_threshold: number;
  status: number;
}

export class UserLoginResonse {
  error: number;
  message: string;
  data: User;
}
export class UserExists {
  email: string;
  id: number;
}
export class TypeStorage {
  name: string;
  id: number;
}

export class UserExistsResponse {
  error: number;
  message: string;
  data: UserExists;
  existsMember: number;
}

export class ValidEmailResponse {
  error: number;
  message: string;
  data: {
    validEmail: boolean;
  };
}

export class UserRegisterResonse {
  error: number;
  message: string;
  data: any;
}

export class Response {
  error: number;
  message: string;
  data: any;
}
export class UserLoginMSA {
  displayName: string;
  email: string;
  avatar: string;
  timeZone: string;
}
