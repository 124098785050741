import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  Response,
  TypeStorage,
  User,
  UserLoginResonse,
  UserRegisterResonse,
  ValidEmailResponse
} from '../models/user';
@Injectable()
export class UserService {
  private loggedUser: User;
  private status_chose: TypeStorage[];
  private apiURL = '/user/login'; // URL to web api
  private ACCESS_TOKEN_MUST_RENEW_WHEN_LIVE_LOWER = 20 * 60;

  constructor(private http: HttpClient) {}

  login(email, passwordHash, device_id): Observable<UserLoginResonse> {
    const data = { email: email, password_hash: passwordHash, device_id: device_id };
    return this.http.post<UserLoginResonse>(environment.api_host + this.apiURL, data);
  }

  loginGoogle(
    token,
    device_id,
    phone_number: string = null,
    ref_id: string = null
  ): Observable<UserLoginResonse> {
    const body = { token, device_id, phone_number, ref_id };
    return this.http.post<UserLoginResonse>(environment.api_host + `/user/login-google`, body);
  }

  registerGoogle(email, firstname, lastname): Observable<UserRegisterResonse> {
    const data = { email, firstname, lastname };
    const apiURL = `/user/register-google`;
    console.log(data);

    // this.http.post<UserRegisterResonse>(environment.api_host + apiURL, data)
    return;
  }

  loginMicrosoft(
    token,
    device_id,
    phone_number: string = null,
    ref_id: string = null
  ): Observable<UserLoginResonse> {
    const data = { token, device_id, phone_number, ref_id };
    return this.http.post<UserLoginResonse>(environment.api_host + '/user/login-microsoft', data);
  }

  updatePayout(data, id): Observable<Response> {
    const apiURL = `/user/${id}`;
    return this.http.patch<Response>(environment.api_host + apiURL, data);
  }
  getRewardHistory(userId): Observable<Response> {
    return this.http.get<Response>(environment.api_host + `/user/${userId}/reward`);
  }
  resendActivation(email): Observable<Response> {
    const data = { email };
    return this.http.post<Response>(environment.api_host + `/user/resend-activation`, data);
  }
  getUserInfo(userId): Observable<Response> {
    return this.http.get<Response>(environment.api_host + `/user/${userId}`);
  }

  setLoggedUser(user) {
    this.loggedUser = user;
    sessionStorage.setItem('loggedUser', JSON.stringify(user));

    const localLoggedUser = {
      id: user.id,
      name: user.name,
      access_token_expired: user.access_token_expired,
      provider: user.provider
    };

    this.setLocalLoggedUser(localLoggedUser);
    // console.log("GHI COOOKIE");
  }

  clearLoggedUser() {
    sessionStorage.removeItem('loggedUser');
    sessionStorage.removeItem('logged');
    this.loggedUser = null;
  }

  clearLoggedUserVariable() {
    this.loggedUser = null;
  }

  getLoggedUser(): User {
    return JSON.parse(sessionStorage.getItem('loggedUser'));
  }

  setLocalLoggedUser(user) {
    localStorage.setItem('loggedUser', JSON.stringify(user));
  }

  getLocalLoggedUser() {
    return JSON.parse(localStorage.getItem('loggedUser'));
  }

  checkLoggedUser() {
    if (this.loggedUser != null) return true;
    this.loadUserFromLocalStorage();
    if (this.loggedUser != null) return true;
    return false;
  }
  loadUserFromLocalStorage() {
    const jsonLocalUser: string = localStorage.getItem('loggedUser');
    // console.log("Load COOOKIE", jsonLocalUser);
    if (jsonLocalUser == '' || jsonLocalUser == null) return;
    const user = new User();
    Object.assign(user, JSON.parse(jsonLocalUser));
    // console.log("user.access_token_expired", user.access_token_expired);
    if (new Date(user.access_token_expired) < new Date()) {
      // console.log("user.access_token_expired < new Date() => LOGIN LAI THOI")
      this.loggedUser = null;
      //TODO: auto refesh token.
    } else {
      const jsonUser: string = sessionStorage.getItem('loggedUser');
      if (jsonUser?.length) {
        Object.assign(user, JSON.parse(jsonUser));
        this.loggedUser = user;
        this.setLoggedUser(user);
      }
    }
    // console.log("loadUserFromLocalStorage", user)
  }

  validateEmail(email: string): Observable<ValidEmailResponse> {
    const apiURL = `/user/auth/validate-email/${email}/`;
    return timer(100).pipe(
      switchMap(() => {
        if (this.checkLoggedUser() && email === this.loggedUser.email) {
          return of({ error: 205, message: 'Email trùng với email sở hữu tài khoản', data: null });
        }
        return this.http.get<ValidEmailResponse>(environment.api_host + apiURL);
      })
    );
  }

  emailValidatorForm(isExist: boolean): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.validateEmail(control.value).pipe(
        map((res) => {
          if (res.error == 0) {
            if (isExist) {
              return res.data.validEmail ? { emailExists: true } : null;
            } else {
              return res.data.validEmail ? null : { emailNotExists: true };
            }
          } else if (res.error === 205) {
            return { owner: true };
          } else {
            return { email: true };
          }
        })
      );
    };
  }

  register(
    email,
    firstname,
    lastname,
    phoneNumber,
    password_hash,
    ref_id
  ): Observable<UserRegisterResonse> {
    const data = { email, firstname, lastname, phoneNumber, password_hash, ref_id };
    const apiURL = `/user/register`;
    return this.http.post<UserRegisterResonse>(environment.api_host + apiURL, data);
  }

  sendEmailGetLinkRePass(email: string): Observable<Response> {
    const data = {
      email: email
    };
    const apiURL = `/user/reset-password`;

    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  validateRePassToken(key: string): Observable<Response> {
    const data = {
      key: key
    };
    const apiURL = `/user/valid-reset-password-token`;

    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  newPassword(pass: string, key: string): Observable<Response> {
    const data = {
      newPassword: pass,
      rePassToken: key
    };
    const apiURL = `/user/new-password`;

    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  changePassword(currPassword: string, newPassword: string): Observable<Response> {
    const data = {
      currPassword: currPassword,
      newPassword: newPassword
    };
    const apiURL = `/user/change-password`;

    return this.http.put<Response>(environment.api_host + apiURL, data);
  }

  refresnToken(userId: number, device_id: string): Observable<Response> {
    const data = { userId, device_id };
    const apiURL = `/user/refresh-token`;
    return this.http.put<Response>(environment.api_host + apiURL, data);
  }

  checkRefreshToken() {
    if (this.checkLoggedUser()) {
      const expired = this.getLocalLoggedUser().access_token_expired;
      if (
        expired &&
        Date.now() < new Date(expired).getTime() &&
        Date.now() >
          new Date(expired).getTime() - this.ACCESS_TOKEN_MUST_RENEW_WHEN_LIVE_LOWER * 1000
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  // kiet add
  setTypeStorage(typeName, indexId) {
    localStorage.setItem(typeName, indexId);
  }
  getTypeStorage(typeName) {
    return localStorage.getItem(typeName);
  }

  signOut() {
    return this.http.get<Response>(environment.api_host + '/user/logout');
  }
}
