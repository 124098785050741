import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'transactionEventStatus'
})
export class TransactionEventStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value == 'FAILED') {
      return 'Thất bại';
    } else {
      return 'Thành công';
    }
  }
}
