import { Component, OnInit, ViewChildren, ViewContainerRef } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-typeahead-editor',
  templateUrl: './typeahead-editor.component.html',
  styleUrls: ['./typeahead-editor.component.css']
})
export class TypeaheadEditorComponent implements OnInit {
  private params: any;
  public typeaheadcontrol;
  selected: string;
  availableSubtypes: string[];

  @ViewChildren('option', { read: ViewContainerRef }) public optionsQueryList;
  ngOnInit() {}

  ngAfterViewInit() {
    this.typeaheadcontrol = this.optionsQueryList.toArray()[0];

    setTimeout(() => {
      this.typeaheadcontrol.element.nativeElement.focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.selected = params.value;

    this.availableSubtypes = [];
    this.availableSubtypes = params.values;
    // console.log(this.availableSubtypes);
  }

  isPopup(): boolean {
    return true;
  }

  getValue(): any {
    return this.selected;
  }

  /**
   * Ham nay co nhiem vu chan het tat ca cac bubble su kien gui len thang cha
   * trong luc typeahead dang xu ly.
   * @param event
   */
  onKeyDown(event): void {
    // console.log("onkeydown con");
    // event.preventDefault();
    const key = event.which || event.keyCode;
    event.stopPropagation();
    if (key == 13) {
      setTimeout(
        function () {
          this.params.stopEditing();
        }.bind(this),
        100
      );
    }
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    setTimeout(
      function () {
        this.params.stopEditing();
      }.bind(this),
      100
    );
  }
}
