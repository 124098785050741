import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { versionInfo } from '../version-info';
import { EXPAND_MENU } from './modules/core/constant';
import { Business } from './modules/core/models/business';
import { BusinessService } from './modules/core/services/business.service';
import { UserService } from './modules/core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Casso';
  business: Business;
  href: any;
  businessId: any;
  isBusiness: boolean;
  isPortfolio: boolean;
  isAlepayCallback = false;

  urlStartBusiness = '/business';
  urlPortfolio = '/portfolio';

  businessName: string;
  businessLogo: string;

  isExpandedSideNav = true;
  optionSideNav: any = {};
  isSizeMobile = false;
  version: any = '0.0.0';
  buildDate: any = '';
  widthAppContainer: number;

  arrayPageHideFooter = [
    'login',
    'register',
    'forgot-password',
    'reset-password',
    'usage-and-billing/alepay/callback'
  ];
  arrayPageHideHeader = [
    'login',
    'register',
    'forgot-password',
    'reset-password',
    'usage-and-billing/alepay/callback',
    'portfolio'
  ];

  arrayPageNotRedirectLogin = ['/login', '/register', '/forgot-password', '/reset-password'];
  pageHideFooterAndSideNav = '/usage-and-billing/alepay/callback';
  showFooter: boolean;
  showHeader: boolean;
  ref: any;
  private shouldPassCheckForOauth2Redirect = false;

  @ViewChild('drawer', { static: true }) public sidenav: MatSidenav;

  menuList: any[] = [
    { name: 'dashboard', title: 'Tổng quan', icon: 'dashboard', route: 'dashboard' },
    { name: 'bankAcc', title: 'Ngân hàng', icon: 'account_balance', route: 'bankAcc' },
    { name: 'books', title: 'Sổ thu chi', icon: 'local_atm', route: 'financial-records' },
    { name: 'rule', title: 'Quy tắc nhập sổ', svgIcon: 'automation', route: 'rule' },
    { name: 'reports', title: 'Báo cáo', icon: 'request_page', route: 'reports' },
    { name: 'feature', title: 'Tính năng', icon: 'extension', route: 'features' },
  ];

  menuSelectedIndex = 0;
  APP_OVERVIEW_PAGE = 'apps/overview';
  SIDEBAR_WIDTH = 268;
  static preUrl = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessService: BusinessService,
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private angulartics2: Angulartics2,
    private location: Location
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit() {
    this.router.events.subscribe(async (val) => {
      if (
        val instanceof NavigationStart &&
        (val.url.match('#access_token') ||
          val.url.match('usage-and-billing/alepay/callback') ||
          val.url.match('code='))
      ) {
        this.shouldPassCheckForOauth2Redirect = true;
      }

      if (val instanceof NavigationEnd) {
        this.updateTabGroup();
        this.updateBusiness();
        this.showFBLiveChat(val.url);
        this.menuSelectedIndex = this.getMenuIndex(val.url);
        this.showFooter = this.checkUrlShowFooter(val.url);
        this.showHeader = this.checkUrlShowHeader(val.url);
        this.angulartics2.pageTrack.next({ path: this.location.path() });

        if (this.userService.getTypeStorage(EXPAND_MENU)) {
          this.isExpandedSideNav =
            this.userService.getTypeStorage(EXPAND_MENU) === 'true' ? true : false;
        }
        // Collapse sidebar for app overview page
        if (val.url.includes(this.APP_OVERVIEW_PAGE)) {
          this.isExpandedSideNav = false;
        }
        window.scrollTo(0, 0);
        await this.getUserIfLogged();
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['ref']) {
        this.userService.setTypeStorage('ref', params['ref']);
        localStorage.removeItem('gift');
        localStorage.removeItem('voucher');
      }
      if (params['gift']) {
        this.userService.setTypeStorage('gift', params['gift']);
        localStorage.removeItem('ref');
        localStorage.removeItem('voucher');
      }
      if (params['voucher']) {
        this.userService.setTypeStorage('voucher', params['voucher']);
        localStorage.removeItem('gift');
        localStorage.removeItem('ref');
      }
    });

    AppComponent.preUrl = window.location.pathname + window.location.search;

    setTimeout(async () => {
      if (!this.userService.checkLoggedUser()) {
        if (this.checkPageRedirectLogin()) {
          this.router.navigate(['login']);
        }
      } else if (this.shouldPassCheckForOauth2Redirect) {
        console.log('DONT CHECK ROUTE!');
      } else {
        if (this.userService.getLoggedUser()?.business_id_lastest) {
          this.router.navigate(['/business', this.userService.getLoggedUser().business_id_lastest]);
        } else {
          this.router.navigate(['portfolio']);
        }
      }
    }, 0);

    this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((result) => {
      const screenWidth = window.innerWidth;

      if ((result.matches && (this.isPortfolio || this.isBusiness)) || screenWidth <= 960) {
        this.isSizeMobile = true;
        this.isExpandedSideNav = true;
        this.optionSideNav = {
          mode: 'over',
          isDisableClose: false,
          isOpened: false,
          isFixed: false
        };
      } else {
        this.isSizeMobile = false;
        this.isExpandedSideNav = true;

        if (this.userService.getTypeStorage(EXPAND_MENU)) {
          this.isExpandedSideNav =
            this.userService.getTypeStorage(EXPAND_MENU) === 'true' ? true : false;
        }
        this.optionSideNav = {
          mode: 'side',
          isDisableClose: true,
          isOpened: true,
          isFixed: true
        };
      }
    });

    // Set width app content when menu expanded and user resizing
    fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.widthAppContainer = window.innerWidth - this.SIDEBAR_WIDTH;
      });

    this.version = versionInfo['tag'];
    this.buildDate = versionInfo['buildDate'];
  }

  updateTabGroup() {
    this.businessId = this.router.url.split('/')[2];
    this.href = this.router.url.split('/')[1];
    if (this.router.url.match('/usage-and-billing/alepay/callback')) {
      this.isBusiness = false;
      this.isPortfolio = false;
      this.isAlepayCallback = true;
    } else {
      this.isBusiness = this.router.url.startsWith('/business');
      this.isPortfolio = this.router.url.startsWith('/portfolio');
    }
  }

  gotoFirstPageOfMenu(menu) {
    const routeLinkFirst = menu[0];
    this.router.navigate(['/business', this.businessId, routeLinkFirst.route]);
  }

  updateBusiness() {
    if (this.businessService.currentBussiness) {
      this.business = this.businessService.currentBussiness;
      this.businessName = this.businessService.currentBussiness.name;
      this.businessId = this.businessService.currentBussiness.id;
      this.businessLogo = this.businessService.currentBussiness.logo;
    }
  }

  closeSideNav() {
    if (this.isSizeMobile) {
      this.sidenav.close();
    } else {
      this.sidenav.close();
      this.sidenav.open();
    }
  }

  expandSideNavAndFitWidth() {
    this.isExpandedSideNav = !this.isExpandedSideNav;
    this.widthAppContainer = window.innerWidth - this.SIDEBAR_WIDTH;
    this.businessService.setExpandedMenu(this.isExpandedSideNav);
    this.userService.setTypeStorage(EXPAND_MENU, this.isExpandedSideNav);
  }

  checkUrlShowFooter(url) {
    if (url === '/' || url.includes('/?')) {
      return false;
    }

    const result = [];
    for (let i = 0; i < this.arrayPageHideFooter.length; i++) {
      const page = this.arrayPageHideFooter[i];
      if (url.includes(page)) {
        result.push(page);
      }
    }
    return result.length === 0;
  }

  checkUrlShowHeader(url) {
    if (url === '/' || url.includes('/?')) {
      return false;
    }

    const result = [];
    for (let i = 0; i < this.arrayPageHideHeader.length; i++) {
      const page = this.arrayPageHideHeader[i];
      if (url.includes(page)) {
        result.push(page);
      }
    }
    return result.length === 0;
  }

  checkPageRedirectLogin() {
    const result = [];
    for (let i = 0; i < this.arrayPageNotRedirectLogin.length; i++) {
      const page = this.arrayPageNotRedirectLogin[i];
      if (this.router.url.startsWith(page)) {
        result.push(page);
        break;
      }
    }
    return result.length === 0;
  }

  logOut(): void {
    this.userService.signOut().subscribe();
    const user = this.userService.getLocalLoggedUser();
    user.access_token_expired = new Date(moment().subtract(2, 'hour').format());
    this.userService.setLocalLoggedUser(user);
    this.userService.clearLoggedUser();
    this.businessService.resetCurrentBussiness();
    this.router.navigate(['login']);
    AppComponent.preUrl = '';
  }

  showFBLiveChat(url) {
    if (this.checkUrlShowFooter(url) && url !== '/') {
      if (document.getElementById('fb-root')) {
        document.getElementById('fb-root').style.display = 'block';
      }
    } else {
      if (document.getElementById('fb-root')) {
        document.getElementById('fb-root').style.display = 'none';
      }
    }
  }

  getMenuIndex(url) {
    const currRoute = url.split('?')[0].split('/')[3];
    const index = this.menuList.findIndex((menu) =>
      menu.route === currRoute
    );
    return index !== -1 ? index : this.menuSelectedIndex;
  }

  commingSoon() {
    window.alert('Tính năng đang phát triển!');
  }

  getUser(userId): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getUserInfo(userId).subscribe((res) => {
        resolve(res);
      });
    });
  }

  async getUserIfLogged(): Promise<void> {
    const checkLogged = this.userService.checkLoggedUser();
    const userLocalStorage = this.userService.getLocalLoggedUser();
    if (
      !checkLogged &&
      userLocalStorage?.id &&
      new Date(userLocalStorage.access_token_expired) > new Date()
    ) {
      const userResponse = await this.getUser(userLocalStorage.id);
      if (userResponse.error == 0) {
        Object.assign(userLocalStorage, userResponse.data);
        this.userService.setLoggedUser(userLocalStorage);
        if (userResponse.data?.business_id_lastest) {
          this.router.navigate(['/business', userResponse.data.business_id_lastest]);
        } else {
          this.router.navigate(['portfolio']);
        }
      }
    }
  }
}
