<div *ngIf="!isMobile">
  <div class="h-[46px]" *ngIf="show"></div>
  <div
    class="absolute top-0 left-[-3px] right-[-2px] z-30 px-4 py-2 bg-white flex justify-end items-center border-b border-[#0000000F]"
    *ngIf="show"
  >
    <a (click)="onProfileMenu()" class="flex items-center cursor-pointer">
      <div class="text-[#000000DE] text-base font-normal">{{ businessName }}</div>
      <div class="mx-4">
        <span
          class="text-white bg-current rounded-full w-7 h-7 flex items-center justify-center border border-[#0000000F] shadow"
          *ngIf="!businessLogo"
          >{{ businessName | slice : 0 : 1 }}</span
        >
        <img *ngIf="businessLogo" [src]="businessLogo" class="w-[28px] rounded-full" alt="logo" />
      </div>
    </a>

    <a mat-list-item id="header-profile-menu" class="mb-4">
      <div
        style="visibility: hidden; position: fixed"
        [style.left]="profiletMenuPosition.x"
        [style.top]="profiletMenuPosition.y"
        [matMenuTriggerFor]="profileMenu"
      >
        <mat-menu #profileMenu="matMenu">
          <button #profileButton mat-menu-item (click)="gotoDashboard()" class="font-semibold">
            {{ businessName }}
          </button>
          <button mat-menu-item (click)="gotoPortfolio(); closeSideNav()">
            Chuyển doanh nghiệp
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout.emit(true); closeSideNav()">
            <mat-icon style="color: #000000 !important; opacity: 0.6" matListIcon>lock</mat-icon>
            Đăng xuất
          </button>
        </mat-menu>
      </div>
    </a>
  </div>
</div>
