<div class="expired-dialog-content">
  <div class="dialog-header">
    <div class="expired-problem">
      <mat-icon id="icon-error" matListIcon>error_outline</mat-icon>
      <div>RẤT TIẾC!</div>
    </div>
  </div>
  <div class="dialog-body">Phiên đăng nhập hết hạn</div>
  <div class="dialog-footer">
    <button
      mat-raised-button
      color="warn"
      (click)="redirectLogin()"
      [disabled]="shouldDisableButtonRedirectLogin"
    >
      Về Trang đăng nhập
    </button>
  </div>
</div>
