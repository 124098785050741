import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '../../../modules/core/services/user.service';

@Component({
  selector: 'app-token-expired',
  templateUrl: './token-expired.component.html',
  styleUrls: ['./token-expired.component.css']
})
export class TokenExpiredComponent {
  isDialog = false;
  shouldDisableButtonRedirectLogin = false;

  constructor(
    protected tokenExpiredDialogRef: MatDialogRef<TokenExpiredComponent>,
    protected dialog: MatDialog,
    protected router: Router,
    protected userService: UserService
  ) {}

  redirectLogin():void {
    this.shouldDisableButtonRedirectLogin = true;
    this.userService.clearLoggedUser();
    this.userService.signOut().subscribe();
    this.router.navigate(['login']);
    this.isDialog = false;
    this.tokenExpiredDialogRef.close(`${this.isDialog}`);
  }
  closeDialog():void {
    this.isDialog = false;
    this.tokenExpiredDialogRef.close(`${this.isDialog}`);
  }
  openDialog():void {
    if (!this.isDialog) {
      this.isDialog = true;

      this.tokenExpiredDialogRef = this.dialog.open(TokenExpiredComponent, {
        hasBackdrop: true,
        height: '350px',
        width: '580px',
        disableClose: true,
        panelClass: 'token-expired-dialog-container'
      });

      const afterClosedSubscribe = this.tokenExpiredDialogRef.afterClosed()
        .subscribe(() => {
          this.shouldDisableButtonRedirectLogin = false;
          this.isDialog = false;
          afterClosedSubscribe.unsubscribe();
        });
    }
  }
}
