import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Angulartics2 } from 'angulartics2';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/modules/core/models/user';
import { UserService } from '../../modules/core/services/user.service';
declare let ClientJS: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  phoneForm: FormGroup;
  device_id = 'unspecified';
  OAuthSettings = {
    appId: 'a79dcc84-fc66-4833-b477-ffd6733a5408',
    redirectUri: '',
    scopes: ['user.read']
  };
  isPhoneNumber: boolean;
  provider: string;
  authToken: string;
  shouldDisplaySpinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: SocialAuthService,
    private angulartics2: Angulartics2,
    private router: Router,
    private msalService: MsalService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    if (baseUrl.includes('localhost') || baseUrl.includes('casso.vn')) {
      this.OAuthSettings.redirectUri = baseUrl;
    }

    this.phoneForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      ref: [
        this.getCookie('ref_code') ||
          this.userService.getTypeStorage('gift') ||
          this.userService.getTypeStorage('voucher') ||
          this.userService.getTypeStorage('ref')
      ]
    });

    try {
      const clientJs = new ClientJS();
      this.device_id =
        (clientJs.getBrowser() != null ? clientJs.getBrowser() : '') +
        '.' +
        clientJs.getFingerprint();
      this.device_id = this.device_id.toLowerCase();
    } catch (e) {
      this.toastrService.error('Không lấy được dervice id');
    }
  }

  signupWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
      this.provider = 'google';
      this.authToken = res.authToken;
      this.loginGoogle(res.authToken);
    });
  }

  loginGoogle(authToken, phone_number: string = null) {
    this.userService.loginGoogle(authToken, this.device_id, phone_number).subscribe((userRes) => {
      this.shouldDisplaySpinner = false;
      if (userRes.error == 0) {
        this.trackingUser(userRes.data);
        this.userService.setLoggedUser({ ...userRes.data, provider: 'google' });
        this.gotoBusinessLatest();
      } else if (userRes.error === 301) {
        this.isPhoneNumber = true;
      } else {
        this.toastrService.error(userRes.message);
      }
    });
  }

  async getTokenMicrofost() {
    this.msalService.acquireTokenSilent(this.OAuthSettings).subscribe(
      (data) => {
        this.authToken = data.accessToken;
        this.loginMicrosoft(data.accessToken);
      },
      (error) => {
        console.log(error);
        this.msalService.acquireTokenPopup(this.OAuthSettings).subscribe((data) => {
          this.authToken = data.accessToken;
          this.loginMicrosoft(data.accessToken);
        });
      }
    );
  }

  loginMicrosoft(authToken, phone_number: string = null) {
    this.userService.loginMicrosoft(authToken, this.device_id, phone_number).subscribe((res) => {
      this.shouldDisplaySpinner = false;
      if (res.error == 0) {
        this.trackingUser(res.data);
        this.userService.setLoggedUser({ ...res.data, provider: 'microsoft' });
        this.gotoBusinessLatest();
      } else if (res.error === 301) {
        this.isPhoneNumber = true;
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  signupWithMicrosoft() {
    this.msalService.loginPopup(this.OAuthSettings).subscribe(
      (data) => {
        this.provider = 'microsoft';
        this.getTokenMicrofost();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  nextStepRegister() {
    this.shouldDisplaySpinner = true;
    switch (this.provider) {
      case 'google':
        this.loginGoogle(this.authToken, this.phoneForm.value['phone']);
        break;
      case 'microsoft':
        this.loginMicrosoft(this.authToken, this.phoneForm.value['phone']);
        break;
      default:
        break;
    }
  }

  gotoBusinessLatest() {
    if (this.userService.getLoggedUser().business_id_lastest) {
      this.router.navigate(['business/' + this.userService.getLoggedUser().business_id_lastest]);
      return;
    } else {
      this.router.navigate(['portfolio']);
    }
  }

  trackingUser(userRes: User) {
    const userProperties = {
      email: userRes.email,
      zip: userRes.amount_bank_connect + 100
    };

    if (userRes.firstname && userRes.firstname.length > 0) {
      userProperties['firstname'] = userRes.firstname;
      userProperties['lastname'] = userRes.lastname;
    } else {
      userProperties['firstname'] = userRes.name;
    }

    if (userRes.phone_number && userRes.phone_number.toString().length > 1) {
      userProperties['phone'] = userRes.phone_number;
    }

    this.angulartics2.setUserProperties.next(userProperties);
  }

  signupWithEmail() {
    this.router.navigate(['register-email']);
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  gotoLogin() {
    this.router.navigate(['login']);
  }
}
