import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const date = moment(value);

    if (date.isValid()) {
      return date.locale('vi').startOf('minute').fromNow();
    }
    return 'Chưa xác định';
  }
}
