import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/core/services/user.service';

@Component({
  selector: 'app-get-link-reset-password',
  templateUrl: './get-link-reset-password.component.html',
  styleUrls: ['./get-link-reset-password.component.css']
})
export class GetLinkResetPasswordComponent implements OnInit {
  sendMailForm: FormGroup;
  isEmailExist: boolean;
  isSendMailSuccess = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.userService.checkLoggedUser()) {
      this.router.navigate(['portfolio']);
    }

    this.sendMailForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email, this.validateEmail]),
        this.userService.emailValidatorForm(false)
      ]
    });
  }

  validateEmail(controls: AbstractControl) {
    const email = controls.value;
    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email) ? null : { emailInvalid: true };
  }

  sendMailResetPass() {
    if (this.sendMailForm.invalid) {
      return;
    }

    const email = this.sendMailForm.value.email;

    this.userService.sendEmailGetLinkRePass(email).subscribe((res) => {
      if (res.error === 0) {
        // console.log("SEND MAIL", res)
        this.isSendMailSuccess = true;
      }
    });
  }
}
