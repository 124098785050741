<div class="loadding-data">
  <mat-progress-bar *ngIf="isProgressBar" mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="rowData.length > 0">
  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="selectedTabChange($event)">
    <mat-tab type="button">
      <ng-template mat-tab-label>Tất cả giao dịch ({{ rowData.length }})</ng-template>
      <ng-template matTabContent>
        <div class="mt-4"></div>
        <app-rule-grid  [data]="rowData"></app-rule-grid>
      </ng-template>
    </mat-tab>
    <mat-tab type="button">
      <ng-template mat-tab-label>Khớp ({{ transactionsMatch.length }})</ng-template>
      <ng-template matTabContent>
        <div class="mt-4"></div>
        <app-rule-grid 
          [data]="transactionsMatch"
          [idfillter]="idFillter"
          (deleteIdFilter)="deleteIdFilter($event)"
        ></app-rule-grid>
      </ng-template>
    </mat-tab>
    <mat-tab type="button">
      <ng-template mat-tab-label>Chưa khớp ({{ transactionsUnmatch.length }})</ng-template>
      <ng-template matTabContent>
        <div class="mt-4"></div>
        <app-rule-grid  [data]="transactionsUnmatch"></app-rule-grid>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div
  class="w-100 text-center"
  style="
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  "
  *ngIf="isEmptyPreview"
>
  <div style="padding-top: 20px">Không có giao dịch nào được áp dụng!</div>
</div>
