import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';
const moment = _moment;
@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let duration = '';
    const durationObj = moment.duration(moment().diff(moment(value), 'minutes'), 'minutes');
    if (durationObj.get('years') > 0) duration += durationObj.get('years') + ' năm ';
    if (durationObj.get('months') > 0) duration += durationObj.get('months') + ' tháng ';
    if (durationObj.get('days') > 0) duration += durationObj.get('days') + ' ngày';
    if (duration.length === 0 && durationObj.get('months') === 0 && durationObj.get('years') === 0)
      duration = 'Hôm nay';
    return duration;
  }
}
