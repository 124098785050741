import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { ToastrService } from 'ngx-toastr';
import { BasicDialogComponent } from '../../../../../../shared/components/basic-dialog/basic-dialog.component';
import { BusinessService } from '../../../../../core/services/business.service';
import { RulePanelComponent } from '../../shared/components/rule-panel/rule-panel.component';
@Component({
  selector: 'app-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.css']
})
export class RuleListComponent implements OnInit {
  dragDisabled = true;
  businessId: number;
  data: any = [];
  rule: any = [];
  isProgressBar: boolean;
  isStatus: boolean;
  @ViewChild('table') table: MatTable<any>;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['drop', 'id', 'memo', 'condition', 'action', 'matched', 'status', 'active'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() valueChange = new EventEmitter();
  @Input() deletefilter: any;

  isEmptyRule: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: NgDialogAnimationService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.businessId = this.businessService.currentBussiness.id;
    this.getPredictRules(this.businessId);

    this.businessService.rules$.subscribe((rules) => {
      this.data = rules;
      this.dataSource = new MatTableDataSource(rules);
    });
    // this.valueChange = this.deletefilter
  }

  getPredictRules(businessId) {
    this.isProgressBar = true;
    this.businessService.getPredictRules(businessId).subscribe((res) => {
      if (res.error == 0) {
        if (res.data.length <= 0) {
          this.isEmptyRule = true;
        }
        this.isProgressBar = false;
        this.isStatus = true;
        this.businessService.setRules(res.data);
      }
    });
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    this.dragDisabled = true;
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.table.renderRows();

    const currRule = event.container.data['filteredData'].find(
      (rule, index) => index === event.currentIndex
    );

    this.businessService
      .updatePredictRules(event.currentIndex, currRule['id'], this.businessId)
      .subscribe((res) => {
        if (res.error == 0) {
          this.dataSource.data = res.data.rulesReorder;
          this.dataSource._updateChangeSubscription();
        }
      });
  }

  onChange(value, data) {
    data.status = value.checked ? 1 : 0;

    this.businessService
      .editRule({ status: data.status }, this.businessId, data.id)
      .subscribe((res) => {
        // console.log(res);
        if (res.error == 0) {
          // this.getPredictRules(this.businessId)
          this.toastrService.success('Cập nhật trạng thái thành công!');
          this.dataSource._updateChangeSubscription();
        } else {
          this.toastrService.error('Cập nhật trạng thái thất bại');
        }
      });
  }

  onInsRule(data = null) {
    const dialog = this.dialog.open(RulePanelComponent, {
      data: { data: data },
      disableClose: false,
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { duration: 300, easing: 'ease-in-out', endDelay: 300 }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 300 }
        }
      },
      position: { rowEnd: '0' },
      panelClass: 'medium-right-side-panel'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        // this.getPredictRules(this.businessId)
        // this.toastrService.success("Add rule success !")
        this.dataSource.data = this.dataSource.data.filter((rule) => rule.id != result.id);
        this.dataSource._updateChangeSubscription();
      }
    });
  }

  onRemove(item) {
    const data = item;
    const dialog = this.dialog.open(BasicDialogComponent, {
      data: {
        data: data,
        title: 'Xóa Quy Tắc',
        type: 'warning',
        content: `
        <div class="flex flex-col items-center text-center text-sm text-[#00000099] leading-[18px]">
          <span>Bạn có thật sự muốn xóa quy tắc:</span>
          <span class="text-[#000000DE]">${data.memo}</span>
          <div class='border border-solid rounded-lg border-[#0000000F] bg-[#F8F8F8] mt-4 p-2 flex flex-col' >
            <span class="text-[#000000DE]">Lưu ý:</span>
            Thao tác không hoàn lại. Vui lòng cân nhắc trước khi quyết định xóa
          </div>
        </div>
        `,
        isCancel: true,
        isBasic: true
      },
      panelClass: 'select-bank-type',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.businessService.deleteRule(result.id, this.businessId).subscribe((res) => {
          if (res.error == 0) {
            this.getPredictRules(this.businessId);
            this.toastrService.success('Xóa quy tắc thành công!');
            this.dataSource.data = this.dataSource.data.filter((rule) => rule.id != result.id);
            this.dataSource._updateChangeSubscription();
          } else {
            this.toastrService.error('Xóa quy tắc thất bại');
          }
        });
      }
    });
  }
  onFillterID(element) {
    this.valueChange.emit(element);
  }
}
