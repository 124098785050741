import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberOfIntegration'
})
export class NumberOfIntegrationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value >= 20 && value < 50) return "20+";
    if (value >= 50 && value < 100) return "50+";
    if (value >= 100 && value < 200) return "100+";
    if (value >= 200 && value < 500) return "200+";
    if (value >= 500 && value < 1000) return "500+";
    if (value >= 1000 && value < 2000) return "1000+";
    if (value >= 2000) return "2000+";
  }
}
