import { Pipe, PipeTransform } from '@angular/core';
import { BANK_IDS_SUPPORT_BALANCE_UPDATE } from 'src/app/modules/core/constant';
import { Bank } from 'src/app/modules/core/models/business';

const speeds = {
  immediately: {
    title: 'Tức thời'
  },
  veryfast: {
    title: '2 phút'
  },
  fast: {
    title: '5 phút'
  },
  slow: {
    title: '6 phút'
  },
  veryslow: {
    title: '20 phút'
  },
  limited: {
    title: '1 tiếng'
  },
  free: {
    title: '6 tiếng'
  }
};

@Pipe({
  name: 'bankAccSpeed'
})
export class BankAccSpeedPipe implements PipeTransform {
  BANK_IDS_SUPPORT_BALANCE_UPDATE = BANK_IDS_SUPPORT_BALANCE_UPDATE;
  transform(bank: Bank, args?: any): any {
    if (this.BANK_IDS_SUPPORT_BALANCE_UPDATE.includes(bank.id)) return speeds.immediately.title;

    const speedTitle = speeds[bank.speed];

    return speedTitle ? speedTitle.title : speeds.free.title;
  }
}
