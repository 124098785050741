<div>
  <div class="loadding-data w-100">
    <!-- <mat-progress-bar *ngIf="isProgressBar" mode="indeterminate"></mat-progress-bar> -->
    <mat-spinner *ngIf="isProgressBar"></mat-spinner>
  </div>
  <div *ngIf="rowData.length > 0">
    <div style="display: flex; align-items: center">
      <button mat-icon-button type="button" (click)="back()" style="margin-right: 10px">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h3>Quy tắc: {{ rule.memo }}</h3>
    </div>
    <div style="padding-bottom: 10px">
      <span>Có {{ rowData.length }} giao dịch đã tải về có thể áp dụng quy tắc này</span>
    </div>

    <div class="rule-panel-apply-content" style="width: 100%; overflow-y: auto">
      <app-rule-grid [data]="rowData"></app-rule-grid>
    </div>

    <div class="dialog-bottom">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 10px 20px 10px 10px;
        "
      >
        <div class="btn-cancel">
          <button mat-stroked-button type="button" mat-dialog-close>HỦY</button>
        </div>
        <div class="btn-save">
          <button mat-button type="button" keyboard-save (click)="onApplyRule()">Áp Dụng</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="rowData.length <= 0 && !isProgressBar">
    <div class="no-data">
      <span>Giao dịch đã áp dung hoặc không có giao dịch nào tải về chưa áp dụng</span>
      <div style="margin-left: 20px">
        <button mat-raised-button type="button" mat-dialog-close>HỦY</button>
      </div>
    </div>
  </div>
</div>
