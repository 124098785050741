import { Pipe, PipeTransform } from '@angular/core';
import { BankAcc } from 'src/app/modules/core/models/business';

@Pipe({
  name: 'bankAccMemo'
})
export class BankAccMemoPipe implements PipeTransform {
  transform(value: BankAcc, shouldShortenName = false): string {
    if (value.memo) {
      return value.memo;
    }
    const maxLengthCanShowName = 22;

    if (value.bank_account_name) {
      return value.bank_account_name.length > maxLengthCanShowName && shouldShortenName
        ? `${value.bank_account_name.slice(0, maxLengthCanShowName)}...`
        : value.bank_account_name;
    }

    return `${value.bank.abbreviation_name}${value.account_number.slice(-4)}`;
  }
}
