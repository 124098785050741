import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser';
import { Angulartics2Module } from 'angulartics2';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { HttpClientModule, HTTP_INTERCEPTORS } from '../../node_modules/@angular/common/http';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { AutoOpenMenuComponent } from './modules/business/shared/components/auto-open-menu/auto-open-menu.component';
import { CoreModule } from './modules/core/core.module';
import { GetLinkResetPasswordComponent } from './pages/get-link-reset-password/get-link-reset-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterEmailComponent } from './pages/register-email/register-email.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    GetLinkResetPasswordComponent,
    ResetPasswordComponent,
    RegisterEmailComponent,
    AutoOpenMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    CoreModule,
    SharedModule.forRoot(),
    Angulartics2Module.forRoot(),
    SocialLoginModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: 'a79dcc84-fc66-4833-b477-ffd6733a5408', // This is your client ID
          authority: 'https://login.microsoftonline.com/common' // This is your tenant ID
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: true // set to true for IE 11
        },
        system: {
          loggerOptions: {
            loggerCallback: () => {},
            piiLoggingEnabled: false
          }
        }
      }),
      {
        interactionType: InteractionType.Popup, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read', 'openid', 'profile']
        },
        loginFailedRoute: '/login-failed'
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]])
      }
    )
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '711682487769-dv4888p907ksh092a46eiseb390ao6jr.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'automation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/automation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'affiliate',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/affiliate-marketing.svg')
    );
  }
}
