import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Business } from 'src/app/modules/core/models/business';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() show: boolean;
  @Input() businessName: string;
  @Input() businessLogo: string;
  @Input() business: Business;
  @Input() sidenav: MatSidenav;
  @Input() isMobile: boolean;
  @Input() businessId: number;
  @Output() logout = new EventEmitter<boolean>();

  PLANS: any;

  showMeWhenUrlIs = '/business';
  profiletMenuPosition = { x: '0px', y: '0px' };
  expandedHeight = '48px';

  @ViewChild(MatMenuTrigger) profileMenu: MatMenuTrigger;
  @ViewChild('profileButton') profileButton: ElementRef;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    //
  }

  onProfileMenu() {
    const profileMenuSelector = document.querySelector('#header-profile-menu') as any;
    const position = profileMenuSelector.getBoundingClientRect();
    if (profileMenuSelector) {
      this.profiletMenuPosition.x = position.x + 4 + 'px';
      this.profiletMenuPosition.y = position.y + 20 + 'px';
      this.profileMenu.openMenu();
      this.profileButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused');
    }
  }

  gotoPortfolio() {
    this.router.navigate(['portfolio']);
  }

  gotoDashboard() {
    this.router.navigate(['/business', this.businessId, 'dashboard']);
  }

  closeSideNav() {
    if (this.isMobile) {
      this.sidenav.close();
    } else {
      this.sidenav.close();
      this.sidenav.open();
    }
  }
}
