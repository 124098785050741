import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../modules/core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const checkLogged = this.userService.checkLoggedUser();
    const userLocalStorage = this.userService.getLocalLoggedUser();
    if (
      window.location.pathname.startsWith('/reset-password') ||
      (!checkLogged &&
        (!userLocalStorage?.id || new Date(userLocalStorage.access_token_expired) < new Date()))
    ) {
      return false;
    }
    return true;
  }
}
