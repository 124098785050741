import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Angulartics2 } from 'angulartics2';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../modules/core/services/user.service';
import { User } from './../../modules/core/models/user';
declare let ClientJS: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  OAuthSettings = {
    appId: 'a79dcc84-fc66-4833-b477-ffd6733a5408',
    redirectUri: '',
    scopes: ['user.read']
  };
  device_id = 'unspecified';
  loginForm: FormGroup;
  phoneForm: FormGroup;
  public lock = false;
  public isLoginFail = false;
  public isNotActive = false;
  hide = true;
  errorInvalid: string;
  checkLogin: number;
  subscription: any;
  isRegister: boolean;
  isPhoneNumber: boolean;
  userId: number;
  provider: string;
  authToken: string;
  shouldDisplaySpinner = false;
  wrong_password_tried = 0;

  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private angulartics2: Angulartics2,
    private authService: SocialAuthService,
    private msalService: MsalService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.isRegister = false;
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, this.validateEmail])],
      password: ['', Validators.required]
    });
    this.phoneForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      ref: [
        this.getCookie('ref_code') ||
          this.userService.getTypeStorage('gift') ||
          this.userService.getTypeStorage('voucher') ||
          this.userService.getTypeStorage('ref')
      ]
    });

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    if (baseUrl.includes('localhost') || baseUrl.includes('casso.vn')) {
      this.OAuthSettings.redirectUri = baseUrl;
    }

    if (JSON.parse(localStorage.getItem('loggedUser'))) {
      this.checkLogin = 1;
    } else {
      this.checkLogin = 0;
    }

    this.gotoBusinessLastest();

    //kiem tra da login truoc do roi.
    try {
      const clientJs = new ClientJS();
      this.device_id =
        (clientJs.getBrowser() != null ? clientJs.getBrowser() : '') +
        '.' +
        clientJs.getFingerprint();
      this.device_id = this.device_id.toLowerCase();
    } catch (e) {
      this.toastrService.error('Không lấy được dervice id');
    }
  }

  get getFormLogin() {
    return this.loginForm.controls;
  }

  login() {
    if (this.lock) {
      this.toastrService.error('Không đc bấm đăng nhập nhiều lần. Vui lòng đợi');
      return;
    }

    if (this.loginForm.invalid) {
      this.errorInvalid = 'Thông tin đăng nhập chưa chính xác';

      setTimeout(() => {
        this.errorInvalid = null;
      }, 3000);
      return;
    }

    this.isLoginFail = false;
    this.lock = true;
    this.isNotActive = false;

    const EMAIL = this.getFormLogin.email.value,
      pass = this.getFormLogin.password.value,
      pass_hash = CryptoJS.SHA512(pass).toString();

    //goi len server de login.
    this.userService.login(EMAIL, pass_hash, this.device_id).subscribe((userRes) => {
      this.lock = false;
      if (userRes.error == 0) {
        this.trackingUser(userRes.data);
        this.checkPhoneNumber(userRes.data);
        this.userService.setLoggedUser({ ...userRes.data, provider: 'email' });
        this.gotoBusinessLastest();
        this.isLoginFail = false;
      } else if (userRes.error === 312) {
        this.isNotActive = true;
      } else if (userRes.error === 314) {
        this.toastrService.error(userRes.message);
      } else {
        this.isLoginFail = true;
        if (userRes.data?.wrong_password_tried) {
          this.wrong_password_tried = userRes.data.wrong_password_tried;
          if (userRes.data.wrong_password_tried === 5) {
            this.toastrService.error(
              'Bạn đã nhập sai 5 lần. Tài khoản của bạn đã bị khóa. Vui lòng bấm Quên mật khẩu để mở lại tài khoản.'
            );
          }
        }
      }
    });
  }

  signInWithGoogle() {
    this.provider = 'google';

    this.authService.initState.subscribe((state) => {
      if (state) {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
          this.authToken = res.authToken;
          this.loginGoogle(res.authToken);
        });
      }
    });
  }

  loginGoogle(authToken, phone_number: string = null, ref_id: string = null) {
    this.userService
      .loginGoogle(authToken, this.device_id, phone_number, ref_id)
      .subscribe((userRes) => {
        this.shouldDisplaySpinner = false;
        if (userRes.error == 0) {
          this.trackingUser(userRes.data);
          this.checkPhoneNumber(userRes.data);
          this.userService.setLoggedUser({ ...userRes.data, provider: this.provider });
          this.gotoBusinessLastest();
        } else if (userRes.error === 301) {
          this.isRegister = true;
        } else {
          this.toastrService.error(userRes.message);
        }
      });
  }

  keyPress(event: any) {
    const pattern = /[0-9 ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  skip() {
    this.isRegister = false;
  }

  signInWithMicrosoft() {
    this.msalService.loginPopup(this.OAuthSettings).subscribe((data) => {
      this.provider = 'microsoft';
      this.getTokenMicrofost();
    });
  }

  async getTokenMicrofost() {
    this.msalService.acquireTokenSilent(this.OAuthSettings).subscribe(
      (data) => {
        this.authToken = data.accessToken;
        this.loginMicrosoft(data.accessToken);
      },
      (err) => {
        this.msalService.acquireTokenPopup(this.OAuthSettings).subscribe((data) => {
          this.authToken = data.accessToken;
          this.loginMicrosoft(data.accessToken);
        });
      }
    );
  }

  loginMicrosoft(authToken, phone_number: string = null, ref_id: string = null) {
    this.userService
      .loginMicrosoft(authToken, this.device_id, phone_number, ref_id)
      .subscribe((res) => {
        this.shouldDisplaySpinner = false;
        if (res.error == 0) {
          this.trackingUser(res.data);
          this.userService.setLoggedUser({ ...res.data, provider: this.provider });
          this.gotoBusinessLastest();
          this.checkPhoneNumber(res.data);
        } else if (res.error === 301) {
          this.isRegister = true;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  signUp() {
    this.shouldDisplaySpinner = true;
    switch (this.provider) {
      case 'google':
        this.loginGoogle(
          this.authToken,
          this.phoneForm.value['phone'],
          this.phoneForm.value['ref']
        );
        break;
      case 'microsoft':
        this.loginMicrosoft(
          this.authToken,
          this.phoneForm.value['phone'],
          this.phoneForm.value['ref']
        );
        break;
      default:
        break;
    }
  }

  gotoBusinessLastest() {
    if (this.userService.checkLoggedUser()) {
      if (this.userService.getLoggedUser().business_id_lastest) {
        this.router.navigate(['business/' + this.userService.getLoggedUser().business_id_lastest]);
      } else {
        this.router.navigate(['portfolio']);
      }
    }
  }

  checkPhoneNumber(user: User) {
    this.isPhoneNumber = !user.phone_number;
  }

  resendActivationEmail() {
    this.userService.resendActivation(this.loginForm.value.email).subscribe((res) => {
      if (res.error === 0) {
        this.isNotActive = false;
        this.toastrService.info(
          'Gửi mail kích hoạt thành công. Vui lòng kiểm tra hộp thư và tiến hành kích hoạt tài khoản.'
        );
      }
    });
  }

  trackingUser(userRes: User) {
    const userProperties = {
      email: userRes.email,
      zip: userRes.amount_bank_connect + 100
    };

    if (userRes.firstname && userRes.firstname.length > 0) {
      userProperties['firstname'] = userRes.firstname;
      userProperties['lastname'] = userRes.lastname;
    } else {
      userProperties['firstname'] = userRes.name;
    }

    if (userRes.phone_number && userRes.phone_number.toString().length > 1) {
      userProperties['phone'] = userRes.phone_number;
    }

    this.angulartics2.setUserProperties.next(userProperties);
  }
  signInWithOrther() {
    this.checkLogin = 0;
    this.userService.clearLoggedUser();
  }
  validateEmail(controls: AbstractControl) {
    const email = controls.value;
    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email) ? null : { emailInvalid: true };
  }
  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
