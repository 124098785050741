<div *ngIf="isMobile">
  <mat-grid-list cols="4" rowHeight="70px">
    <mat-grid-tile [colspan]="3" [rowspan]="1">
      <div class="transaction-info">
        <span class="mat-caption" style="color: gray">{{ transaction.tid }} </span><br />
        <span class="mat-caption" style="color: gray">{{ transaction.description }} </span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <div class="transaction-info">
        <div class="mat-caption" style="color: gray">
          <b>status</b>:
          {{
            transaction.status == 1 ? 'OK' : transaction.status == 2 ? 'Không khớp' : 'Chưa nhập'
          }}
        </div>
        <button mat-button (click)="openFileDialog()">
          {{
            transaction.status == 1
              ? 'Xem chi tiết'
              : transaction.status == 2
              ? 'Nhập lại'
              : 'Nhập mới'
          }}
        </button>
        <button *ngIf="transaction.status == 0" mat-button (click)="autoImport()">Tự nhập</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-list *ngIf="finRecords">
    <mat-list-item *ngFor="let finRecord of finRecords">
      <div class="mat-caption" style="color: gray">
        {{ lookupTypeName(finRecord.type) }} - {{ finRecord.sub_type }} - {{ finRecord.amount }} -
        {{ finRecord.month_report }} - {{ finRecord.partner_code }}
      </div>
    </mat-list-item>
  </mat-list>
</div>

<div *ngIf="isMobile === false" class="btn-action-desktop">
  <button
    mat-icon-button
    (click)="openFileDialog(transaction)"
    [matTooltip]="
      transaction.status == 1 ? 'Xem chi tiết' : transaction.status == 2 ? 'Nhập lại' : 'Nhập mới'
    "
    matTooltipPosition="above"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="Xem sự kiện"
    matTooltipPosition="above"
  >
    <mat-icon>view_timeline_outline</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="transaction.status == 0"
    (click)="autoImport()"
    matTooltip="Tự nhập"
    matTooltipPosition="above"
  >
    <mat-icon>control_point</mat-icon>
  </button>
</div>
