import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/modules/core/services/business.service';

export interface CongratsData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-congrats-dialog',
  templateUrl: './congrats-dialog.component.html',
  styleUrls: ['./congrats-dialog.component.css']
})
export class CongratsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CongratsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CongratsData,
    private router: Router,
    public businessService: BusinessService
  ) {}

  ngOnInit(): void {}

  gotoDashboard() {
    this.dialogRef.close();
    this.router.navigate(['business', this.businessService.currentBussiness.id, 'dashboard']);
  }
}
