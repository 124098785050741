<form [formGroup]="form" *ngIf="form" (submit)="$event.stopPropagation()">
  <div style="padding: 20px 0px 69px 0px">
    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Tên quy tắc</mat-label>
        <input
          matInput
          autocomplete="off"
          (keyup.enter)="onKey($event)"
          formControlName="memo"
          required
        />
      </mat-form-field>
    </div>

    <div class="d-md-flex">
      <span style="padding-top: 9px">Áp dụng cho</span>
      <mat-form-field appearance="outline" class="form-rule-scope">
        <mat-select formControlName="scope_money" required>
          <mat-option [value]="item.val" *ngFor="let item of rule_logic.scope">{{
            item.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <span style="padding-top: 9px">thuộc</span>
      <mat-form-field appearance="outline" class="form-rule-bank">
        <mat-select formControlName="scope_bank_acc" required>
          <mat-option value="all">Tất cả ngân hàng</mat-option>
          <mat-option [value]="item.account_number" *ngFor="let item of banks">
            {{ item.short_name }} - {{ item.account_number }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <div class="d-md-flex">
        <span style="padding-top: 9px">Khi một giao dịch ngân hàng thỏa</span>
        <mat-form-field appearance="outline" class="form-rule-bool-operation">
          <mat-select formControlName="codition_use_and" required>
            <mat-option [value]="1">{{ rule_logic.bool_operation.and.text1 }}</mat-option>
            <mat-option [value]="2">{{ rule_logic.bool_operation.or.text1 }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span style="padding-top: 9px">điều kiện sau : </span>
      </div>

      <div
        formArrayName="conditions"
        *ngFor="let item of conditionControls; let i = index"
        class="d-md-flex"
      >
        <spna *ngIf="i == 1 || i > 1" style="padding-top: 8px; padding-right: 10px">
          {{
            form.value['codition_use_and'] == '1'
              ? rule_logic.bool_operation.and.text2
              : rule_logic.bool_operation.or.text2
          }}
        </spna>
        <div [formGroupName]="i" class="w-100 d-md-flex">
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select
                formControlName="field"
                required
                (selectionChange)="changeSelect($event, i)"
              >
                <mat-option value="description">{{
                  rule_logic.codition.description.name
                }}</mat-option>
                <mat-option value="amount">{{ rule_logic.codition.amount.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select formControlName="comparer" required>
                <div *ngIf="form.value['conditions'][i]['field'] == 'description'">
                  <mat-option
                    [value]="item.val"
                    *ngFor="let item of rule_logic.codition.description.comparer"
                  >
                    {{ item.label }}
                  </mat-option>
                </div>
                <div *ngIf="form.value['conditions'][i]['field'] == 'amount'">
                  <mat-option
                    [value]="row.val"
                    *ngFor="let row of rule_logic.codition.amount.comparer"
                  >
                    {{ row.label }}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-100">
            <mat-form-field
              appearance="outline"
              class="w-100"
              *ngIf="
                form.value['conditions'][i]['field'] != 'amount' &&
                form.value['conditions'][i]['field'] != 'description'
              "
            >
              <input matInput autocomplete="off" formArrayName="val" required />
            </mat-form-field>

            <mat-form-field
              appearance="outline"
              class="w-100"
              *ngIf="form.value['conditions'][i]['field'] == 'amount'"
            >
              <input currencyMask matInput autocomplete="off" formControlName="val" required />
            </mat-form-field>

            <mat-form-field
              formArrayName="val"
              class="example-chip-list"
              appearance="outline"
              class="w-100"
              *ngIf="form.value['conditions'][i]['field'] == 'description'"
            >
              <mat-chip-list #chipList aria-label="Fruit selection" style="margin: 10px 0px">
                <mat-chip
                  *ngFor="
                    let item of form.value['conditions'][i]['val'];
                    let conditionIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeValCondition(item, i, conditionIndex)"
                >
                  {{ item }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  (keyup)="getFullValCondition($event)"
                  #inputChips
                  required
                  cdkTextareaAutosize
                  [matChipInputFor]="chipList"
                  [matAutocomplete]="auto"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addValCondition($event, i, conditionIndex)"
                />
                <mat-autocomplete
                  (opened)="(keyVal)"
                  #auto="matAutocomplete"
                  (optionSelected)="selected($event, i)"
                >
                  <mat-option *ngIf="keyVal" [value]="keyVal">Tạo {{ keyVal }}</mat-option>
                  <!-- <mat-option *ngIf='!keyVal' [value]="">Tạo value condition</mat-option> -->
                </mat-autocomplete>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div *ngIf="i >= 1">
            <button type="button" mat-icon-button (click)="removeCondition(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button
        mat-stroked-button
        type="button"
        class="bg-current text-white"
        (click)="addCondition()"
      >
        Thêm
      </button>
    </div>

    <div style="margin-top: 10px">
      <div style="margin-bottom: 30px">
        <span style="font-weight: 600">Thì thực hiện nhập liệu giao dịch tài chính</span>
      </div>
      <div *ngIf="ActionControls.length > 1">
        <span style="padding-top: 9px">Số tiền được phân bổ theo : </span>
        <mat-form-field appearance="outline" style="width: 20%">
          <mat-select required formControlName="action_split_use_percent">
            <mat-option [value]="1">{{ rule_logic.split_method.percent.text }}</mat-option>
            <mat-option [value]="2">{{ rule_logic.split_method.fix.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formArrayName="actions" *ngFor="let item of ActionControls; let i = index">
        <div [formGroupName]="i" class="w-100 d-md-flex">
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Ngày hạch toán</mat-label>
              <mat-select formControlName="date_modifier" required>
                <mat-option [value]="item.val" *ngFor="let item of rule_logic.date_modifier"
                  >{{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Loại</mat-label>
              <mat-select formControlName="type" required>
                <mat-option
                  [value]="item.val"
                  *ngFor="let item of rule_logic.types"
                  (click)="getDataText(item.val)"
                >
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Danh mục</mat-label>
              <input formControlName="sub_type" required matInput [matAutocomplete]="auto" />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of list_sub_types" [value]="option.sub_type">
                  {{ option.sub_type }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Đối tác</mat-label>
              <input matInput autocomplete="off" formControlName="partner_code" />
            </mat-form-field>
          </div>
          <div class="w-100" *ngIf="ActionControls.length > 1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Số tiền</mat-label>
              <input matInput autocomplete="off" formControlName="price" />
            </mat-form-field>
          </div>

          <div *ngIf="i == 1 || i > 1">
            <button type="button" mat-icon-button (click)="removeAction(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-stroked-button type="button" class="bg-current text-white" (click)="addAction()">
        Thêm
      </button>
    </div>

    <div class="flex items-center mt-2 ml-[-3px] text-sm">
      <mat-icon class="mr-1">info_outline</mat-icon>
      Danh mục không được chứa dấu phẩy
    </div>

    <div style="margin-top: 10px">
      <mat-checkbox (change)="changeApply($event)" [checked]="checked"
        >Áp dụng tất cả các giao dịch đã tải về mà chưa nhập danh mục</mat-checkbox
      >
    </div>
  </div>

  <div class="dialog-bottom">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 0 10px 0;
      "
    >
      <div class="btn-cancel">
        <button mat-stroked-button type="button" mat-dialog-close>HỦY</button>
      </div>
      <button
        style="flex-grow: 1"
        class="bg-current text-white font-medium"
        *ngIf="isInsert == true"
        mat-button
        type="button"
        keyboard-save
        [disabled]="form.invalid || isInsertDisabled"
        (click)="onInsertRule()"
      >
        {{ checked == true ? 'Xem thử' : 'Thêm' }}
      </button>
      <button
        style="flex-grow: 1"
        class="bg-current text-white font-medium"
        *ngIf="isInsert == false"
        mat-button
        type="button"
        keyboard-save
        [disabled]="form.invalid || isEditDisabled"
        (click)="onEditRule()"
      >
        {{ checked == true ? 'Xem thử' : 'Sửa' }}
      </button>
    </div>
  </div>
</form>
