<mat-nav-list [ngClass]="'background-color-brand'" class="sidenav-content">
  <div>
    <a
      mat-list-item
      [routerLink]="['portfolio', 'new']"
      [routerLinkActive]="['active']"
      #rla="routerLinkActive"
      (click)="closeSideNav()"
      [matTooltip]="isExpandedSideNav ? '' : 'Thêm mới'"
      matTooltipPosition="right"
    >
      <mat-icon matListIcon>add</mat-icon>
      <span *ngIf="isExpandedSideNav">Thêm mới</span>
    </a>
    <a
      mat-list-item
      [routerLink]="['portfolio', 'change-password']"
      [routerLinkActive]="['active']"
      #rla="routerLinkActive"
      (click)="closeSideNav()"
      [matTooltip]="isExpandedSideNav ? '' : 'Thay đổi mật khẩu'"
      matTooltipPosition="right"
    >
      <mat-icon matListIcon>vpn_key</mat-icon>
      <span *ngIf="isExpandedSideNav">Thay đổi mật khẩu</span>
    </a>
    <a
      mat-list-item
      (click)="this.logout.emit(true); closeSideNav()"
      [matTooltip]="isExpandedSideNav ? '' : 'Đăng xuất'"
      matTooltipPosition="right"
    >
      <mat-icon matListIcon>power_settings_new</mat-icon>
      <span *ngIf="isExpandedSideNav">Đăng xuất</span>
    </a>
  </div>
  <div>
    <a
      mat-list-item
      [routerLink]="['/business', businessId]"
      [matTooltip]="isExpandedSideNav ? '' : 'Quay lại'"
      matTooltipPosition="right"
      class="absolute bottom-0"
    >
      <mat-icon matListIcon>arrow_back</mat-icon>
      <span *ngIf="isExpandedSideNav">Quay lại</span>
    </a>
  </div>
</mat-nav-list>
