<div class="loader-container">
  <div class="loader-spinner align-item-center">
    <mat-spinner class="loader-spinner" [diameter]="data.widthSpinner" [mode]="mode"> </mat-spinner>
  </div>
  <div class="loader-content">
    <h4 class="title align-item-center">
      {{ data.title }}
    </h4>
    <div class="content">
      {{ data.content }}
    </div>
  </div>
</div>
