import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GetLinkResetPasswordComponent } from './pages/get-link-reset-password/get-link-reset-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterEmailComponent } from './pages/register-email/register-email.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register-email', component: RegisterEmailComponent },
  { path: 'forgot-password', component: GetLinkResetPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'portfolio',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Danh mục' },
    loadChildren: () =>
      import(`./modules/portfolio/portfolio.module`).then((m) => m.PortfolioModule)
  },
  {
    path: 'business/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import(`./modules/business/business.module`).then((m) => m.BusinessModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
