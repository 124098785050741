<div (keydown)="onKeyDown($event)">
  <input
    #option
    [(ngModel)]="selected"
    [typeahead]="availableSubtypes"
    [typeaheadScrollable]="true"
    [adaptivePosition]="true"
    [typeaheadIsFirstItemActive]="false"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    class="form-control"
  />
</div>
