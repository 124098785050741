export const FORMAT_DATE_DEFAULT = 'YYYY-MM-DD HH:mm:ss';

export const RESPONSE_ERROR = {
  SUCCESS: 0
};

export const BANK_ACCOUNT_CONNECT_STATUS = {
  PAUSED: 0,
  CONNECTED: 1,
  REVOKED: 2,
  INTERRUPTED: 3,
  UNPAID: 4,
  CLOSED: 5,
  OTP_REQUIRED: 6,
  TURN_OFF_LOGIN_FROM_WEB: 7
};

export const BANK_IDS = {
  VIETINBANK_OFFICIAL: 123,
  VIETCOMBANK_PERSONAL: 111,
  MBBANK_PERSONAL: 171,
  VIETCOMBANK_ENTERPRISE_DIGI: 116,
  VIETINBANK_EFAST_OFFICIAL: 124,
  VIETINBANK_EFAST: 125
};

export const BANK_IDS_SUPPORT_BALANCE_UPDATE = [
  123, // Vietinbank Official
  172, // MBBank Official
  177, // MBBank Biz Official
];

export const BANK_IDS_NOT_HAVE_BALANCE = [
  172, // MBBank Official
  177, // MBBank Biz Official
];

export const BANK_SYNC_METHOD = {
  OFFICIAL_API: 'Official API',
  RPA: 'RPA'
}

export const BANK_ACTIVE_STATUS = {
  DISABLE: 0, //Tắt
  FULL_ACTIVE: 1, //Bật hoàn toàn
  RESTRICTION_ACTIVE: 2, //truy cập hạn chế.
  WHITE_LIST_ACTIVE: 3 //phải white list mới link được
};

export const BANK_TYPE = {
  ENTERPRISE: 'enterprise',
  PERSONAL: 'personal',
  BIZ: 'biz'
};

export const PLAN_NAME = {
  FREE: 'Free',
  STARTER: 'Starter',
  PRO: 'Pro',
  TEAM: 'Team',
  COMPANY: 'Company',
  TRIAL: 'Trial'
};

export const PLAN_ID = {
  FREE: 1,
  STARTER: 2,
  PRO: 3,
  TEAM: 4,
  COMPANY: 5,
  TRIAL: 6
};

export const PLAN_OFFICIAL_NAME = {
  1: 'Free',
  2: 'Starter',
  3: 'Pro',
  4: 'Team',
  5: 'Company',
  6: 'Trial'
};

export const PAYMENT_SUBSCRIBE_TYPE = {
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
  EXTEND: 'extend'
};

export const PAYMENT_DURATION = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
  THREE_MONTH: 'threeMonth'
};

export const PAYMENT_DURATION_VALUE = {
  ANNUALLY: 12,
  MONTHLY: 1,
  THREE_MONTH: 3
};

export const TOP_5_BANKS_SYNC = [
  BANK_IDS.VIETCOMBANK_PERSONAL,
  BANK_IDS.VIETCOMBANK_ENTERPRISE_DIGI,
  BANK_IDS.MBBANK_PERSONAL,
  BANK_IDS.VIETINBANK_EFAST
];

export const INTEGRATION_STATE = {
  PAUSE: 0,
  CONNECT: 1,
  EXCEED: 2
};

export const HIDE_COLUMNS_DEFAULT = [
  'bank_sub_acc_id',
  'memo',
  'virtual_account',
  'virtual_account_name',
  'corresponsive_account',
  'corresponsive_name',
  'corresponsive_bank_name'
];
export const TRANSACTION_HIDE_COLUMNS_DEFAULT = [
  'bank_sub_acc_id',
  'virtual_account',
  'virtual_account_name',
  'corresponsive_account',
  'corresponsive_name',
  'corresponsive_bank_name'
];

export const HIDE_COLUMNS = 'columnsUnvisible';
export const TRANSACTION_COLUMNS_UNVISIBLE_CACHE_NAME = 'transactionColumnsUnvisible';

export const EXPAND_MENU = 'isExpandedMenu';

export const FIRST_PAGE = 'firstPage';
export const CONNECTIONS_PAGE = 'transactions';
export const DASHBOARD_PAGE = 'dashboard';

export const INVITE_STATUS = {
  PENDING: 0,
  ACCEPTED: 1
};

export const EVENT_HISTORY_PAGE_SIZE = 10;
export const PAGE_DEFAULT = 1;
export const BANK_ACC_RESPONSE_CODE = {
  BANK_ACC_OTP_CONFIMED: 203,
  BANK_ACC_RECONNECTED_SUCCESS: 204
};
export const PAYMENT_METHOD = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  ALEPAY: 'ALEPAY'
};

export const PAYOS_PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED'
};

export const BANKHUB_MESSAGE_TYPE = {
  STATUS: 'status',
  CREDENTIAL: 'credential'
};

export const BANK_PROVIDER = {
  BANKHUB: 'bankhub',
  BANKSHEET: 'banksheet'
};

export const PLAN_LIMIT_BANK_ACCOUNT = {
  FREE: 1,
  STARTER: 2,
  PRO: 5,
  TEAM: 10,
  COMPANY: 25,
  TRIAL: 5
};
