import {
  ActivitiesResponse,
  Bank,
  BankAcc,
  BankAccRegisterResponse,
  BankAccsCrudResponse,
  BasicResponse,
  CardLinkAlePayResponse,
  CashflowResponse,
  CreditCardResponse,
  ListCreditCardsResponse,
  OneClickPaymentAlepayResponse,
  OrderResponse,
  PredictReview,
  PricingResponse,
  ReportMonthsResponse,
  ReportResponse,
  Rule,
  RuleResponse,
  SuggestSavingResponse,
} from './../models/business';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  TransactionsResponse,
  TransactionResponse,
  FinancialRecord,
  FinancialRecordsResponse,
  SumFinancialRecordsResponse,
  membersResponse,
  lastSyncResponse,
  Business,
  BusinessResponse,
  AvailableSubtype,
  AvailablePartner,
  ImportingPredictResponse,
  CrudResponse,
  BankAccsResponse,
  LifeTimeReportResponse,
  CheckBankAccResponse,
  TaskSyncResponse,
  SyncBankAccsResponse,
  Response,
  BankResponse,
  SyncBankAccResponse,
  OnlyCrudResponse,
  ReorderRulesResponse,
  PredictRules,
  DeleteRule,
  RuleConfig,
  AddRuleResponse,
  EditRule
} from '../models/business';
import { UserExistsResponse } from '../models/user';
import * as moment from 'moment';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogConfig } from 'ng-dialog-animation';
import { EVENT_STATUS } from '../types/business';
import { FIRST_PAGE, PLAN_ID } from '../constant';

@Injectable()
export class BusinessService {
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private toastrService: ToastrService
  ) {}

  FINANCIAL_TYPES = {
    101: 'Income',
    102: 'Expense',
    103: 'Debit',
    104: 'Contribution',
    105: 'Dividend'
  };

  FINANCIAL_TYPES_VN = {
    101: 'Doanh thu',
    102: 'Chi phí',
    103: 'Ghi nợ',
    104: 'Góp vốn',
    105: 'Cổ tức'
  };
  CONFIG_MODIFIER = {
    happen_date: 'Ngày giao dịch',
    last_30_days: 'Lùi 30 ngày'
  };

  OPTIONS_PICK_RANGE_DATE = [
    { label: 'Tùy chỉnh', subtract: 0, value: 'custom', type: 'custom', isHighlight: true },
    { label: 'Toàn thời gian', subtract: 0, value: 'all', type: 'all', isHighlight: false },
    { label: 'Tuần này', subtract: 0, value: 'currWeek', type: 'week', isHighlight: true },
    { label: 'Tuần trước', subtract: 1, value: 'prevWeek', type: 'week', isHighlight: true },
    { label: 'Trong 30 ngày', subtract: 30, value: 'thirtyDays', type: 'day', isHighlight: false },
    { label: 'Tháng này', subtract: 0, value: 'currMonth', type: 'month', isHighlight: false },
    { label: 'Tháng trước', subtract: 1, value: 'prevMonth', type: 'month', isHighlight: false },
    {
      label: 'Tháng trước nữa',
      subtract: 2,
      value: 'doubprevMonth',
      type: 'month',
      isHighlight: false
    },
    { label: 'Quý này', subtract: 0, value: 'currQuarter', type: 'quarter', isHighlight: true },
    { label: 'Quý trước', subtract: 1, value: 'prevQuarter', type: 'quarter', isHighlight: true },
    { label: 'Năm này', subtract: 0, value: 'currYear', type: 'year', isHighlight: true },
    { label: 'Năm trước', subtract: 1, value: 'prevYear', type: 'year', isHighlight: true }
  ];

  pageGotoFirst = 'dashboard';
  currentBussiness: Business = null;
  availableMonths: number[] = [];
  availableYears: number[] = [];
  availableQuaters: number[] = [];

  getFirstPage(): string {
    let firstpage = this.pageGotoFirst;

    if (localStorage.getItem(FIRST_PAGE)) {
      firstpage = localStorage.getItem(FIRST_PAGE);
    }

    return firstpage;
  }

  private readonly _availableSubtypes = new BehaviorSubject<AvailableSubtype[]>([]);
  readonly availableSubtypes$ = this._availableSubtypes.asObservable();
  private set availableSubtypes(subtypes: AvailableSubtype[]) {
    this._availableSubtypes.next(subtypes);
  }
  private get availableSubtypes(): AvailableSubtype[] {
    return this._availableSubtypes.getValue();
  }
  setAvailableSubtypes(subtypes: AvailableSubtype[]) {
    this._availableSubtypes.next(subtypes);
  }
  updateAvailableSubtypes(subtypes: AvailableSubtype[]) {
    this.availableSubtypes = [...this.availableSubtypes, ...subtypes];
  }
  updateUseAvailableSubtype(subtype: AvailableSubtype, value: number) {
    const curSubtype = this.availableSubtypes.filter((s) => s.sub_type === subtype.sub_type)[0];
    const indexSubtype = this.availableSubtypes.indexOf(curSubtype);
    this.availableSubtypes[indexSubtype].uses = curSubtype.uses + value;
    this.availableSubtypes = [...this.availableSubtypes];
  }
  removeSubtype(sub_type: AvailableSubtype) {
    this.availableSubtypes = this.availableSubtypes.filter(
      (subtype) => subtype.sub_type !== sub_type.sub_type
    );
  }

  getAvaiableSubtypesByType(type): Observable<AvailableSubtype[]> {
    return this.availableSubtypes$.pipe(
      map((subtypes) => subtypes.filter((r: any) => r.type == type))
    );
  }

  private readonly _availablePartners = new BehaviorSubject<AvailablePartner[]>([]);
  readonly availablePartners$ = this._availablePartners.asObservable();
  private set availablePartners(partners: AvailablePartner[]) {
    this._availablePartners.next(partners);
  }
  private get availablePartners(): AvailablePartner[] {
    return this._availablePartners.getValue();
  }
  setAvailablePartners(partners: AvailablePartner[]) {
    this._availablePartners.next(partners);
  }
  updateAvailablePartners(partners: AvailablePartner[]) {
    this.availablePartners = [...this.availablePartners, ...partners];
  }
  updateUseAvailablePartners(partner: AvailablePartner, value: number) {
    const indexSubtype = this.availablePartners.indexOf(partner);
    this.availablePartners[indexSubtype].uses = partner.uses + value;
    this.availablePartners = [...this.availablePartners];
  }
  removePartner(partner: AvailablePartner) {
    this.availablePartners = this.availablePartners.filter(
      (p) => p.partner_code !== partner.partner_code
    );
  }

  getAvaiablePartnersByTypeAndSubtype(type, subtypes): Observable<AvailablePartner[]> {
    return this.availablePartners$.pipe(
      map((partners) =>
        partners.filter((r: any) => r.type == type && subtypes.includes(r.sub_type))
      )
    );
  }

  resetCurrentBussiness(): void {
    this.currentBussiness = null;

    this.availableMonths = [];
    this.availableYears = [];
    this.availableQuaters = [];

    this.setAvailableSubtypes([]);
    this.availablePartners = [];
    this.setBankAccs(null);
  }

  checkCurrentBussiness() {
    return this.currentBussiness != null;
  }

  getBussinessById(bid): Observable<BusinessResponse> {
    //TODO: write service here.
    const apiURL = '/business/' + bid;
    return this.http.get<BusinessResponse>(environment.api_host + apiURL);
  }

  createBusiness(name, website, segment_feature, invite_code): Observable<Response> {
    const apiURL = '/business';

    const data = { name, website, segment_feature, invite_code };

    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  deleteBusiness(businessId): Observable<Response> {
    const apiURL = '/business/' + businessId;

    return this.http.delete<Response>(environment.api_host + apiURL);
  }

  loadTransactions(
    businessId,
    fromDate,
    toDate,
    fromAmount,
    toAmount,
    statuses,
    bankErrors,
    page,
    bank_acc_id,
    eventStatus
  ): Observable<TransactionsResponse> {
    const data = {
      fromDate,
      toDate,
      fromAmount,
      toAmount,
      statuses,
      bankErrors,
      page,
      bank_acc_id,
      eventStatus
    };
    if (bankErrors != null && bankErrors.length > 0) {
      data['bankErrors'] = bankErrors.join('+');
    }
    const apiURL = '/business/' + businessId + `/transactions?` + this.serialize(data);
    // console.log(apiURL);
    return this.http.get<TransactionsResponse>(environment.api_host + apiURL);
  }

  serialize = function (obj) {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p) && obj[p] != null && obj[p] !== undefined) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };
  loadFinancialRecord(
    businessId,
    fromDate,
    toDate,
    type,
    subType,
    partners,
    page,
    bank_acc_id
  ): Observable<FinancialRecordsResponse> {
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      type: type,
      page: page,
      bank_acc_id: bank_acc_id
    };
    if (subType != null && subType.length > 0) {
      data['subType'] = subType.join(',');
    }
    if (partners != null && partners.length > 0) {
      data['partners'] = partners.join(',');
    }
    // var apiURL = `/business/${businessId}/financialRecords?fromDate=${fromDate}&toDate=${toDate}&fromAmount=${fromAmount}&toAmount=${toAmount}&subType=${subType}`;
    const apiURL = `/business/` + businessId + `/financialRecords?` + this.serialize(data);
    return this.http.get<FinancialRecordsResponse>(environment.api_host + apiURL);
  }

  httpGetTransactionRecords(businessId, transactionId) {
    //TODO: check if exist. not query.

    const apiURL = '/business/' + businessId + '/transactions/' + transactionId;

    return this.http.get<TransactionResponse>(environment.api_host + apiURL);
  }
  updateFinancialRecord(
    happen_date,
    amount,
    description,
    type,
    sub_type,
    partner_code,
    order_sale_id,
    financiaLRecordId,
    businessId
  ): Observable<CrudResponse> {
    if (happen_date != null) happen_date = moment(happen_date).format('YYYY-MM-DD');
    const data = {
      happen_date: happen_date,
      amount: amount,
      description: description,
      type: type,
      sub_type: sub_type,
      partner_code: partner_code,
      order_sale_id: order_sale_id,
      id: financiaLRecordId
    };
    // var apiURL = '/business/financialRecords';
    const apiURL = '/business/' + businessId + '/financialRecords/' + financiaLRecordId;

    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }
  insertFinancialRecord(
    businessId,
    transaction_id,
    happen_date,
    description,
    type,
    sub_type,
    partner_code,
    order_sale_id,
    amount,
    create_by,
    bank_sub_acc_id
  ): Observable<CrudResponse> {
    happen_date = moment(happen_date).format('YYYY-MM-DD');
    const data = {
      transaction_id: transaction_id,
      happen_date: happen_date,
      description: description,
      type: type,
      sub_type: sub_type,
      partner_code: partner_code,
      order_sale_id: order_sale_id,
      amount: amount,
      create_by: create_by,
      bank_sub_acc_id: bank_sub_acc_id
    };
    const apiURL = '/business/' + businessId + '/financialRecords';

    return this.http.post<CrudResponse>(environment.api_host + apiURL, data);
  }
  deleteFinancialRecord(businessId, financialId): Observable<CrudResponse> {
    const apiURL = '/business/' + businessId + '/financialRecords/' + financialId;

    return this.http.delete<CrudResponse>(environment.api_host + apiURL);
  }

  private transactionIdSource = new BehaviorSubject<number>(0);
  currentTransactionId = this.transactionIdSource.asObservable();
  setTransaction(transactionId: number) {
    this.transactionIdSource.next(transactionId);
  }
  private transactionSource = new BehaviorSubject<any>([]);
  currentTransaction = this.transactionSource.asObservable();
  transaction(transaction: any) {
    this.transactionSource.next(transaction);
  }
  private featuresOfBusiness = new BehaviorSubject<any>([]);
  currentFeatures = this.featuresOfBusiness.asObservable();
  setFeaturesOfBusiness(features: any) {
    this.featuresOfBusiness.next(features);
  }
  private statusOfTransIdUpdatedSource = new BehaviorSubject<any>(null);
  currentTransactionIdStatus = this.statusOfTransIdUpdatedSource.asObservable();
  setStatusOfTransaction(transactionIdUpStatus: any) {
    this.statusOfTransIdUpdatedSource.next(transactionIdUpStatus);
  }

  getReportMonths(businessId): Observable<ReportMonthsResponse> {
    const apiURL = `/business/${businessId}/reports/months`;
    return this.http.get<ReportMonthsResponse>(environment.api_host + apiURL);
  }

  loadSumFinancialRecordsByType(
    businessId,
    segmentType,
    segmentValue
  ): Observable<SumFinancialRecordsResponse> {
    // var data = {
    //   "monthReport": monthReport,
    //   "yearReport": yearReport
    // }
    const apiURL = `/business/${businessId}/reports/${segmentType}/${segmentValue}`;
    return this.http.get<SumFinancialRecordsResponse>(environment.api_host + apiURL);
  }

  // Kiet add report-publish
  getPublishReport(businessId): Observable<any> {
    const apiURL = `/business/${businessId}/report-publish`;
    return this.http.get<any>(environment.api_host + apiURL);
  }
  getReportHTML(businessId, segmentType, segmentValue): Observable<any> {
    const apiURL = `/business/${businessId}/report-html/${segmentType}/${segmentValue}`;
    return this.http.get<any>(environment.api_host + apiURL);
  }
  sendMail(businessId, segmentType, segmentValue, type) {
    const data = { type };

    const apiURL = `/business/${businessId}/report-html/${segmentType}/${segmentValue}/sendmail`;
    return this.http.post<any>(environment.api_host + apiURL, data);
  }
  getBusinessMembers(businessId): Observable<membersResponse> {
    const apiURL = '/business/' + businessId + '/shares';
    return this.http.get<membersResponse>(environment.api_host + apiURL);
  }
  deleteMember(businessId, memberId) {
    const apiURL = '/business/' + businessId + '/shares/' + memberId;

    return this.http.delete<membersResponse>(environment.api_host + apiURL);
  }
  checkMemberExist(email) {
    const data = {
      email: email
    };
    const apiURL = '/user/search';

    return this.http.post<UserExistsResponse>(environment.api_host + apiURL, data);
  }
  addBusinessMember(memberId, amount, businessId) {
    const data = { memberId, amount };
    const apiURL = '/business/' + businessId + '/shares';

    return this.http.post<UserExistsResponse>(environment.api_host + apiURL, data);
  }
  syncBank(force) {
    const businessId = this.currentBussiness.id;

    const apiURL = `/business/${businessId}/bankAccs/sync`;
    const data = {
      force: force
    };

    return this.http.post<SyncBankAccsResponse>(environment.api_host + apiURL, data);
  }

  //sync two step
  syncBank2GenTask(force) {
    const businessId = this.currentBussiness.id;

    const apiURL = `/business/${businessId}/bankAccs/sync2`;
    const data = {
      force: force
    };

    return this.http.post<TaskSyncResponse>(environment.api_host + apiURL, data);
  }

  syncBank2GetTransactions(bankAccs) {
    const businessId = this.currentBussiness.id;

    const apiURL = `/business/${businessId}/bankAccs/sync2/rs`;
    const data = {
      bankAccs: bankAccs
    };

    return this.http.post<SyncBankAccsResponse>(environment.api_host + apiURL, data);
  }

  private tasksForSync = new BehaviorSubject<any>([]);
  getTasksForSync = this.tasksForSync.asObservable();
  setTasksForSync(tasks: any) {
    this.tasksForSync.next(tasks);
  }

  getLastSyncBank(id) {
    const apiURL = '/business/' + id + '/bankAccs';

    return this.http.get<lastSyncResponse>(environment.api_host + apiURL);
  }
  updateStatusTransaction(transactionId, bankAccId, businessId) {
    const data = {
      id: transactionId,
      bankAccId: bankAccId
    };
    const apiURL = '/business/' + businessId + '/transactions/' + transactionId;
    // console.log("HTTP", apiURL);
    return this.http.put<TransactionsResponse>(environment.api_host + apiURL, data);
  }

  predictTransactionImporting(transactionId) {
    const apiURL =
      '/business/' + this.currentBussiness.id + '/transactions/' + transactionId + '/predict';
    // console.log("HTTP", apiURL);
    return this.http.get<ImportingPredictResponse>(environment.api_host + apiURL);
  }

  updateFeaturesBusiness(
    businessId,
    feature_enable_subtype,
    feature_enable_partner,
    feature_enable_order_sale
  ) {
    const apiURL = '/business/' + businessId + '/features/update';
    const data = {
      feature_enable_subtype: feature_enable_subtype,
      feature_enable_partner: feature_enable_partner,
      feature_enable_order_sale: feature_enable_order_sale
    };
    // console.log("HTTP", apiURL);
    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }

  getBankAccs(businessId) {
    const apiURL = '/business/' + businessId + '/bankAccs';
    // console.log("HTTP", apiURL);
    return this.http.get<BankAccsResponse>(environment.api_host + apiURL);
  }

  getLifetimeReport(segmentx, segmenty, filterUrl, fromDate, toDate) {
    const businessId = this.currentBussiness.id;
    const data = { fromDate, toDate };
    const apiURL =
      `/business/${businessId}/reports/${segmentx}/by/${segmenty}?${filterUrl}&` +
      this.serialize(data);
    //http://{{HOST}}/business/4/reports/type/by/quarter?type=101,102,103&
    // console.log("HTTP", apiURL);
    return this.http.get<LifeTimeReportResponse>(environment.api_host + encodeURI(apiURL));
  }

  getBusinessValuation() {
    const businessId = this.currentBussiness.id;
    const apiURL = `/business/${businessId}/valuation`;
    // console.log("HTTP", apiURL);
    return this.http.get<any>(environment.api_host + apiURL);
  }

  //check account and get sub acc from bank account
  checkBankAcc(data, businessId) {
    const apiURL = `/business/${businessId}/bankAcc/check`;
    return this.http.post<CheckBankAccResponse>(environment.api_host + apiURL, data);
  }
  // Get bank accounts available
  getCheckBankAccByBankCredentialId(bankCredentialId, businessId) {
    const apiURL = `/business/${businessId}/bankAcc/check/${bankCredentialId}`;
    return this.http.get<CheckBankAccResponse>(environment.api_host + apiURL);
  }

  confirmOtp(businessId, data): Observable<any> {
    const apiURL = `/business/${businessId}/bankAcc/confirm-otp`;
    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  insertBankAcc(businessId, data) {
    const apiURL = `/business/${businessId}/bankAcc/add`;
    return this.http.post<BankAccsCrudResponse>(environment.api_host + apiURL, data);
  }

  private _bankAccs = new BehaviorSubject<BankAcc[]>(null);
  readonly getAllBankAcc = this._bankAccs.asObservable();
  private set bankAccs(bankAccs: BankAcc[]) {
    this._bankAccs.next(bankAccs);
  }
  private get bankAccs(): BankAcc[] {
    return this._bankAccs.getValue();
  }
  setBankAccs(bankAccs: BankAcc[]): void {
    this._bankAccs.next(bankAccs);
  }
  addBankAcc(bankAcc: BankAcc): void {
    const index = this.bankAccs.findIndex((bankAccount) => bankAccount.id === bankAcc.id);
    if (index === -1) {
      this.bankAccs = [...this.bankAccs, bankAcc];
    }
  }
  updateBankAccStats(bankAcc: BankAcc) {
    const index = this.bankAccs.findIndex((item) => item.id === bankAcc.id);
    if (index !== -1) {
      this.bankAccs[index] = bankAcc;
    }
  }
  removeBankAcc(bankAccId: number): void {
    this.bankAccs = this.bankAccs.filter((bankAccount) => bankAccount.id !== bankAccId);
  }

  deleteBankAcc(bankAccId, businessId) {
    const apiURL = `/business/${businessId}/bankAcc/${bankAccId}`;

    return this.http.delete<Response>(environment.api_host + apiURL);
  }

  getLinkBankhub(businessId: number, bankName: string): Observable<Response> {
    const apiURL = `/business/${businessId}/bankAcc/bankhub/link?bankName=${bankName}`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  getUpdateLinkBankhub(bankAccId: number, businessId: number): Observable<Response> {
    const apiURL = `/business/${businessId}/bankAcc/bankhub/update/${bankAccId}`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  updateBankAccByBankhub(bankAccId: number, businessId: number): Observable<Response> {
    const apiURL = `/business/${businessId}/bankAcc/bankhub/update/${bankAccId}`;
    return this.http.put<Response>(environment.api_host + apiURL, null);
  }

  exchangeToken(businessId: number, publicToken: string): Observable<Response> {
    const apiURL = `/business/${businessId}/bankAcc/bankhub/exchange`;
    return this.http.post<Response>(environment.api_host + apiURL, { publicToken });
  }

  private notiEmails = new BehaviorSubject<any>([]);
  getNotiEmails = this.notiEmails.asObservable();
  setNotiEmail(emails: string[]) {
    this.notiEmails.next(emails);
  }

  private readonly _financialRecords = new BehaviorSubject<FinancialRecord[]>([]);
  readonly financialRecords$ = this._financialRecords.asObservable();

  private get financialRecords(): FinancialRecord[] {
    return this._financialRecords.getValue();
  }
  private set financialRecords(finRecords: FinancialRecord[]) {
    this._financialRecords.next(finRecords);
  }

  addFinRecord(finRecord: FinancialRecord[], isAbove: boolean) {
    if (isAbove) {
      this.financialRecords = [...finRecord, ...this.financialRecords];
      this.financialRecords = this.financialRecords.sort(function (
        a: FinancialRecord,
        b: FinancialRecord
      ) {
        return Number(new Date(b.happen_date)) - Number(new Date(a.happen_date));
      });
    } else {
      this.financialRecords = [...this.financialRecords, ...finRecord];
    }
  }

  removeFinRecord(id: number) {
    this.financialRecords = this.financialRecords.filter((finRecord) => finRecord.id !== id);
  }

  setFinRecords(finRecords: FinancialRecord[]) {
    this._financialRecords.next(finRecords);
  }

  updateValueEachFieldRecord(finRecordId: number, field: string, value: any) {
    const record = this.financialRecords.find((record) => record.id == finRecordId);
    if (record) {
      const indexRecord = this.financialRecords.indexOf(record);
      this.financialRecords[indexRecord][field] = value;
      this.financialRecords = [...this.financialRecords];
    }
  }

  getBanks(businessId): Observable<BankResponse> {
    const apiURL = `/business/${businessId}/banks`;

    return this.http.get<BankResponse>(environment.api_host + apiURL);
  }

  private readonly _banks = new BehaviorSubject<Bank[]>([]);
  getBanksCache$ = this._banks.asObservable();
  setBanks(banks: any | Bank[]) {
    this._banks.next(banks);
  }

  updateBankAcc(bankAccId, businessId, data) {
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}`;
    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }

  updatePassBankAcc(bankAccId, newPassBankAcc, businessId) {
    const data = { newPassBankAcc };
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}`;
    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }

  updateFirstBalanceBankAcc(businessId, bankAccId, firstBalance) {
    const data = { firstBalance };
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}`;
    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }

  updateBeginDateBankAcc(businessId, bankAccId, bankBeginDate) {
    const data = { bankBeginDate };
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}`;
    return this.http.put<CrudResponse>(environment.api_host + apiURL, data);
  }

  getBankAcc(bankAccId, businessId): Observable<any> {
    const apiURL = `/business/${businessId}/bankAcc/${bankAccId}`;
    return this.http.get<BankAccsResponse>(environment.api_host + apiURL).pipe(
      map((bankAccResponse) => {
        return bankAccResponse;
      })
    );
  }

  syncBankAcc(businessId, bankAccId, force, isMulti = false) {
    const data = { force, isMulti };
    const apiURL = `/business/${businessId}/bankAcc/sync/${bankAccId}`;
    return this.http.post<SyncBankAccResponse>(environment.api_host + apiURL, data);
  }

  revokeBankAcc(businessId, bankAccId): Observable<BasicResponse> {
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}/revoke`;
    return this.http.put<BasicResponse>(environment.api_host + apiURL, {});
  }

  registerBalanceChangeBankAcc(businessId, bankAccId): Observable<BankAccRegisterResponse> {
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}/registerAlert`;
    return this.http.put<BankAccRegisterResponse>(environment.api_host + apiURL, {});
  }

  updateLogo(businessId, logo): Observable<Response> {
    const apiURL = `/business/${businessId}/profile/logo`;
    const data = { logo };

    return this.http.put<Response>(environment.api_host + apiURL, data);
  }

  updateFullName(businessId, fullName): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/profile`;
    const data = { fullName };
    return timer(1000).pipe(
      switchMap(() => {
        return this.http.put<OnlyCrudResponse>(environment.api_host + apiURL, data);
      })
    );
  }

  saveFullName(businessId): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.updateFullName(businessId, control.value).pipe(
        map((res) => {
          if (res.data.changedRows === 1) this.currentBussiness.full_name = control.value;
          return res.error === 0
            ? null
            : this.toastrService.error('Tên đầy đủ của doanh nghiệp không được trống!');
        })
      );
    };
  }

  updateName(businessId, name): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/profile`;
    const data = { name };

    return timer(1000).pipe(
      switchMap(() => {
        return this.http.put<OnlyCrudResponse>(environment.api_host + apiURL, data);
      })
    );
  }

  saveName(businessId): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.updateName(businessId, control.value).pipe(
        map((res) => {
          if (res.data.changedRows === 1) this.currentBussiness.name = control.value;
          return res.error === 0
            ? null
            : this.toastrService.error('Tên gọi doanh nghiệp không được trống!');
        })
      );
    };
  }
  updateWebsite(businessId, website): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/profile`;
    const data = { website };

    return timer(1000).pipe(
      switchMap(() => {
        return this.http.put<OnlyCrudResponse>(environment.api_host + apiURL, data);
      })
    );
  }
  saveWebsite(businessId): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.updateWebsite(businessId, control.value).pipe(
        map((res) => {
          if (res.data.changedRows === 1) this.currentBussiness.website = control.value;
          return res.error === 0
            ? null
            : this.toastrService.error('Tên website của doanh nghiệp không được trống!');
        })
      );
    };
  }

  getPredictRules(businessId): Observable<PredictRules> {
    const apiURL = `/business/${businessId}/predict-rules`;
    return this.http.get<PredictRules>(environment.api_host + apiURL);
  }

  getRuleConfig(businessId): Observable<RuleConfig> {
    const apiURL = `/business/${businessId}/rule-config`;
    return this.http.get<RuleConfig>(environment.api_host + apiURL);
  }

  deleteRule(ruleId, businessId): Observable<DeleteRule> {
    const apiURL = `/business/${businessId}/predict-rule/${ruleId}`;
    return this.http.delete<DeleteRule>(environment.api_host + apiURL);
  }

  updatePredictRules(currentIndex, ruleId, businessId): Observable<ReorderRulesResponse> {
    const apiURL = `/business/${businessId}/predict-rules`;
    const data = { currentIndex, ruleId };
    return timer(1000).pipe(
      switchMap(() => {
        return this.http.put<ReorderRulesResponse>(environment.api_host + apiURL, data);
      })
    );
  }
  addRule(data, businessId): Observable<AddRuleResponse> {
    const apiURL = `/business/${businessId}/predict-rule`;
    return timer(1000).pipe(
      switchMap(() => {
        return this.http.post<AddRuleResponse>(environment.api_host + apiURL, data);
      })
    );
  }
  editRule(data, businessId, ruleId): Observable<EditRule> {
    const apiURL = `/business/${businessId}/predict-rule/${ruleId}`;
    return timer(1000).pipe(
      switchMap(() => {
        return this.http.put<EditRule>(environment.api_host + apiURL, data);
      })
    );
  }

  getRule(businessId, ruleId): Observable<RuleResponse> {
    const apiUrl = `/business/${businessId}/predict-rule/${ruleId}`;
    return this.http.get<RuleResponse>(environment.api_host + apiUrl);
  }

  getPredictReview(businessId): Observable<PredictReview> {
    const apiURL = `/business/${businessId}/predict-review`;
    return this.http.get<PredictReview>(environment.api_host + apiURL);
  }

  getPredictReviewByRuleId(businessId, ruleId, apply, data): Observable<PredictReview> {
    const params = { apply: apply };
    const apiURL = `/business/${businessId}/predict-preview/${ruleId}?${this.serialize(params)}`;

    return this.http.put<PredictReview>(environment.api_host + apiURL, data);
  }

  private ruleConfigSource = new BehaviorSubject<any>(null);
  ruleConfig = this.ruleConfigSource.asObservable();
  setRuleConfig(ruleConfig: any) {
    this.ruleConfigSource.next(ruleConfig);
  }

  private readonly _rules = new BehaviorSubject<Rule[]>([]);
  readonly rules$ = this._rules.asObservable();

  private get rules(): Rule[] {
    return this._rules.getValue();
  }
  private set rules(rules: Rule[]) {
    this._rules.next(rules);
  }

  extendRules(rule: Rule[], isAbove: boolean) {
    if (isAbove) {
      this.rules = [...rule, ...this.rules];

      this.rules = this.rules.sort(function (a: Rule, b: Rule) {
        return Number(new Date(b.order)) - Number(new Date(a.order));
      });
    } else {
      this.rules = [...this.rules, ...rule];
    }
  }

  removeRule(id: number) {
    this.rules = this.rules.filter((rule) => rule.id !== id);
  }

  setRules(rules: Rule[]) {
    this._rules.next(rules);
  }

  updateRuleBasedOnFieldNameByRuleId(ruleId: number, field: string, value: any) {
    const rule = this.rules.find((rule) => rule.id == ruleId);
    if (rule) {
      const indexRule = this.rules.indexOf(rule);
      this.rules[indexRule][field] = value;
      this.rules = [...this.rules];
    }
  }

  updateRuleRealTime(curRule) {
    const rule = this.rules.find((rule) => rule.id == curRule.id);
    if (rule) {
      const indexRule = this.rules.indexOf(rule);
      this.rules[indexRule] = { ...curRule };
      this.rules = [...this.rules];
    }
  }

  updateMemoBankAcc(businessId: number, bankAccId, memo): Observable<PredictReview> {
    const apiURL = `/business/${businessId}/bankacc/${bankAccId}`;
    const data = { memo };
    return this.http.put<PredictReview>(environment.api_host + apiURL, data);
  }

  getReportSumFinancialRecordByTypeAndGroupByTime(
    businessId: number,
    type: number,
    segmentType: string,
    segmentValue: string,
    queryParams: Object = {}
  ): Observable<ReportResponse> {
    const apiURL = `/business/${businessId}/reports/type/${type}/${segmentType}/${segmentValue}?${this.serialize(
      queryParams
    )}`;
    return this.http.get<ReportResponse>(environment.api_host + apiURL);
  }

  getCashflow(
    businessId: number,
    segmentType: string,
    segmentValue: string
  ): Observable<CashflowResponse> {
    const apiURL = `/business/${businessId}/report/cashflow/${segmentType}/${segmentValue}`;

    return this.http.get<CashflowResponse>(environment.api_host + apiURL);
  }

  getSuggestSaving(businessId: number): Observable<SuggestSavingResponse> {
    const apiURL = `/business/${businessId}/suggest/saving/`;

    return this.http.get<SuggestSavingResponse>(environment.api_host + apiURL);
  }

  getAffiliate(businessId: number): Observable<Response> {
    const apiURL = `/business/${businessId}/affiliate`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  getEstimatedChargesThisMonth(businessId: number): Observable<Response> {
    const apiUrl = `/business/${businessId}/usage-billing/estimated-charges`;
    return this.http.get<Response>(environment.api_host + apiUrl);
  }

  getUsageAndBillingHistory(businessId: number): Observable<Response> {
    const apiUrl = `/business/${businessId}/usage-billing`;
    return this.http.get<Response>(environment.api_host + apiUrl);
  }

  getInvoiceDetailBasedOnMonth(businessId: number, monthId: number): Observable<Response> {
    const apiUrl = `/business/${businessId}/invoices/month/${monthId}`;
    return this.http.get<Response>(environment.api_host + apiUrl);
  }

  getPaymentTransaction(businessId: number, transactionId: number): Observable<Response> {
    const apiUrl = `/business/${businessId}/payments/transaction/${transactionId}`;
    return this.http.get<Response>(environment.api_host + apiUrl);
  }
  getPaymentHtml(businessId: number, paymentId: number): Observable<any> {
    const apiUrl = `/business/${businessId}/payments/${paymentId}/html`;
    return this.http.get<any>(environment.api_host + apiUrl);
  }
  getInvoiceById(businessId: number, invoiceId: number): Observable<Response> {
    const apiURL = `/business/${businessId}/invoices/${invoiceId}`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }
  getInvoiceHtml(businessId: number, invoiceId: number): Observable<any> {
    const apiURL = `/business/${businessId}/invoices/${invoiceId}/html`;
    return this.http.get<any>(environment.api_host + apiURL);
  }
  updateEmbbed(businessId: number, embedded_enable): Observable<BusinessResponse> {
    const apiURL = `/business/${businessId}/embedded`;
    const data = { embedded_enable };
    return this.http.put<BusinessResponse>(environment.api_host + apiURL, data);
  }
  updateEmbbedSelectedBankAcc(businessId: number, bankAccs): Observable<BusinessResponse> {
    const apiURL = `/business/${businessId}/embedded/bank-account`;
    const data = { bankAccs };
    return this.http.put<BusinessResponse>(environment.api_host + apiURL, data);
  }
  updateBalanceAffiliate(businessId, balance, userId, partner_type): Observable<Response> {
    const apiURL = `/business/${businessId}/affiliate`;
    const data = {
      balance: balance,
      userId: userId,
      partner_type: partner_type
    };
    return this.http.put<Response>(environment.api_host + apiURL, data);
  }

  getDialogConfig(data, panelClass = 'medium-right-side-panel') {
    const dialogConfig: MatDialogConfig = {};

    dialogConfig['data'] = { ...data };
    dialogConfig.disableClose = false;
    dialogConfig.animation = {
      to: 'left',
      incomingOptions: {
        keyframeAnimationOptions: {
          duration: 300,
          easing: 'ease-in-out',
          endDelay: 300
        }
      },
      outgoingOptions: {
        keyframeAnimationOptions: { easing: 'ease-in-out', duration: 300 }
      }
    };
    dialogConfig.panelClass = panelClass;
    dialogConfig.position = { rowEnd: '0' };

    return dialogConfig;
  }

  getActivities(businessId: number, queries: Object): Observable<ActivitiesResponse> {
    const apiURL = `/business/${businessId}/activities?${this.serialize(queries)}`;
    return this.http.get<ActivitiesResponse>(environment.api_host + apiURL);
  }

  getPricingByPlanNameAndPlanOption(
    businessId,
    planName,
    planOption,
    type
  ): Observable<PricingResponse> {
    const apiURL = `/business/${businessId}/usage-billing/${planName}/${planOption}?type=${type}`;
    return this.http.get<PricingResponse>(environment.api_host + apiURL);
  }

  getQRCodePayment(
    bankName: string,
    accountNumber: string,
    amount: number,
    description: string,
    options = {}
  ): Observable<HttpEvent<Blob>> {
    const urlParams = `/${bankName}/${accountNumber}/${amount}/${description}/vietqr_net_2.jpg?${this.serialize(
      options
    )}`;
    return this.httpBackend.handle(new HttpRequest('GET', environment.api_vietqr + urlParams));
  }

  createOrder(businessId: number, data: any): Observable<OrderResponse> {
    const { planId, planOption, ...payload } = data;
    const apiUrl = `/business/${businessId}/plans/${planId}/upgrade/${planOption}`;
    return this.http.put<OrderResponse>(environment.api_host + apiUrl, payload);
  }

  checkDowngrade(newPlan) {
    const currentPlan = {
      plan_id: this.currentBussiness.plan_id,
      plan_option: this.currentBussiness.plan_option
    };
    // If usage period plan Paid but current business plan Free, use usage period plan info
    if (
      ![PLAN_ID.FREE, PLAN_ID.TRIAL].includes(this.currentBussiness.usage.plan_id) &&
      [PLAN_ID.FREE, PLAN_ID.TRIAL].includes(this.currentBussiness.plan_id)
    ) {
      currentPlan.plan_id = this.currentBussiness.usage.plan_id;
      currentPlan.plan_option = this.currentBussiness.usage.plan_option;
    }

    if (currentPlan.plan_id == newPlan.plan_id && currentPlan.plan_option > newPlan.plan_option) {
      return true;
    }

    if (
      currentPlan.plan_id != newPlan.plan_id &&
      currentPlan.plan_id > newPlan.plan_id &&
      currentPlan.plan_id != PLAN_ID.TRIAL
    ) {
      return true;
    }

    return false;
  }

  private expanedMenu = new BehaviorSubject<boolean>(false);
  getExpanedMenu = this.expanedMenu.asObservable();
  setExpandedMenu(isExpanded: boolean) {
    this.expanedMenu.next(isExpanded);
  }

  getSInvoicePdf(businessId, paymentId): Observable<any> {
    const apiURL = `/business/${businessId}/payments/${paymentId}/pdfInvoice`;
    return this.http.get<any>(environment.api_host + apiURL);
  }

  updateInvoiceInfo(businessId, invoiceInfo): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/profile`;

    return this.http.put<OnlyCrudResponse>(environment.api_host + apiURL, invoiceInfo);
  }

  cardLinkAlePay(businessId): Observable<CardLinkAlePayResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/cardlink`;
    return this.http.get<CardLinkAlePayResponse>(environment.api_host + apiURL);
  }

  getCardLinkAlePay(businessId): Observable<ListCreditCardsResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/list-cardlink`;
    return this.http.get<ListCreditCardsResponse>(environment.api_host + apiURL);
  }

  getCardById(businessId, cardId): Observable<CreditCardResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/get-card/${cardId}`;
    return this.http.get<CreditCardResponse>(environment.api_host + apiURL);
  }

  getDefaultCard(businessId): Observable<CreditCardResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/get-default-card`;
    return this.http.get<CreditCardResponse>(environment.api_host + apiURL);
  }

  updateDefaultCreditCard(businessId, creditCardId): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/set-default-card`;
    return this.http.put<OnlyCrudResponse>(environment.api_host + apiURL, { creditCardId });
  }

  deleteCreditCard(businessId: number, creditCardId: number): Observable<OnlyCrudResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/cancel-profile/${creditCardId}`;
    return this.http.delete<OnlyCrudResponse>(environment.api_host + apiURL);
  }

  oneClickPaymentAlepay(businessId, data): Observable<OneClickPaymentAlepayResponse> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/one-click-payment`;
    return this.http.post<OneClickPaymentAlepayResponse>(environment.api_host + apiURL, data);
  }

  getPaymentByTransactionCode(businessId, transactionCode): Observable<Response> {
    const apiURL = `/business/${businessId}/usage-billing/payment/alepay/get-payment/${transactionCode}`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  getBusinessInfoByTaxCode(businessId, taxCode): Observable<Response> {
    const apiURL = `/business/${businessId}/tax-code/${taxCode}`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  getUsagePeriodUnuse(businessId: number): Observable<Response> {
    const apiURL = `/business/${businessId}/usage-period?state=PENDING`;
    return this.http.get<Response>(environment.api_host + apiURL);
  }

  openOfficialAccount(businessId, data) {
    const apiURL = `/business/${businessId}/open-official-account-form`;
    return this.http.post<Response>(environment.api_host + apiURL, data);
  }

  allowBussinessUseProduct(businessId, inviteCode) {
    const apiURL = `/business/${businessId}/allow-use-product`;
    const data = { inviteCode };
    return this.http.put<Response>(environment.api_host + apiURL, data);
  }
}
