<div class="send-mail-container">
  <div class="send-mail-content card-white-shadow">
    <div class="logo">
      <img src="../../../assets/img/icon.png" style="height: 64px" alt="casso logo png" />
      <img src="../../../assets/img/casso-logo-new.png" style="width: 64px" />
    </div>

    <div class="form-send-mail">
      <div *ngIf="!isSendMailSuccess" class="title title-forgot-pass">
        <h4 style="font-weight: 400; text-align: center">Đặt lại mật khẩu</h4>
        <p>Vui lòng cung cấp email đăng nhập để đặt lại mật khẩu.</p>
      </div>

      <form
        *ngIf="!isSendMailSuccess"
        class="form-email"
        [formGroup]="sendMailForm"
        (ngSubmit)="sendMailResetPass()"
      >
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            type="email"
            formControlName="email"
            class="form-control"
            matInput
            autocomplete="email"
          />
          <mat-error *ngIf="sendMailForm.invalid">
            <mat-error *ngIf="sendMailForm.hasError('required', ['email'])"
              >Email không được để trống</mat-error
            >
            <mat-error
              *ngIf="
                !sendMailForm.hasError('required', ['email']) &&
                sendMailForm.hasError('emailInvalid', ['email'])
              "
              >Email không hợp lệ!</mat-error
            >
          </mat-error>
        </mat-form-field>

        <br />
        <div
          [ngClass]="'msg-error'"
          *ngIf="
            sendMailForm.hasError('emailNotExists', ['email']) && sendMailForm.get('email').touched
          "
        >
          Email chưa được đăng ký! Vui lòng sử dụng email khác. Về trang
          <a routerLink="/register">đăng ký</a>.
        </div>
        <button [disabled]="sendMailForm.invalid" mat-raised-button color="primary">Gửi</button>
        <br />
      </form>
    </div>

    <div *ngIf="isSendMailSuccess" class="send-mail-success">
      Gửi thành công. Vui lòng kiểm tra hộp thư để lấy link và đặt lại mật khẩu.
    </div>

    <div class="nav-login" style="margin-top: 10px; width: 100%">
      <a routerLink="/login">Về trang đăng nhập</a>
    </div>
  </div>
</div>
