import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// type == undefined: basic dialog
// type === 'delete': delete bank account
// type === 'stop': stop bank account
// type === 'warning': activity uncompleted
// type === 'maintenance': notify bank maintenance
export interface DialogData {
  title: string;
  data: any;
  type: string;
  content: string;
  isCancel: boolean;
  isBasic: boolean;
  isValidate: boolean;
}

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.css']
})
export class BasicDialogComponent implements OnInit {
  formConfirmDelete: FormGroup;
  keyValueCompare: string;

  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formConfirmDelete = this.formBuilder.group({
      confirm: ['', Validators.compose([Validators.required, this.validateKeyValue.bind(this)])]
    });

    this.keyValueCompare = 'DELETE ' + this.data.data.keyValue;
  }

  validateKeyValue(controls: AbstractControl) {
    const value = controls.value;
    return value === this.keyValueCompare ? null : { invalid: true };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
