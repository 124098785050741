import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { BusinessService } from 'src/app/modules/core/services/business.service';
import { Predict, Rule } from './../../../../../../core/models/business';

@Component({
  selector: 'app-rule-panel-apply',
  templateUrl: './rule-panel-apply.component.html',
  styleUrls: ['./rule-panel-apply.component.css']
})
export class RulePanelApplyComponent implements OnInit {
  public businessId: number;
  isProgressBar: boolean;
  check = false;
  rule: Rule;

  public gridApi;
  columnDefs;
  getRowHeight;
  rowData: Predict[] = [];

  constructor(
    private businessService: BusinessService,
    public dialogRef: MatDialogRef<RulePanelApplyComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private toastrService: ToastrService,
    public dialog: NgDialogAnimationService
  ) {}

  ngOnInit(): void {
    this.isProgressBar = true;
    this.businessId = this.businessService.currentBussiness.id;

    forkJoin([
      this.businessService.getRule(this.businessId, this.item.data),
      this.businessService.getPredictReviewByRuleId(this.businessId, this.item.data, this.check, {})
    ]).subscribe((result) => {
      this.isProgressBar = false;

      if (result[0].error === 0) {
        this.rule = result[0].data;

        if (this.item.event) {
          this.handleEventOpenDialog(this.item.event);
        }
      }

      if (result[1].error == 0) {
        this.rowData = result[1].data;
      }
    });
  }

  onApplyRule() {
    this.businessService
      .getPredictReviewByRuleId(this.businessId, this.item.data, this.item.check, {})
      .subscribe((res) => {
        if (res.error == 0) {
          this.toastrService.success(`${this.rowData.length} giao dịch đã được áp dụng quy tắc`);

          let financialRecords;
          this.businessService.financialRecords$.subscribe((records) => {
            financialRecords = records;
          });

          res.data.forEach((predict) => {
            const record = financialRecords.find(
              (item) => item.transaction_id === predict.transaction.id
            );

            if (record) {
              const actions = predict.matches[0].actions[0];

              for (const [key, value] of Object.entries(actions)) {
                if (value) {
                  this.businessService.updateValueEachFieldRecord(record.id, key, value);
                }
              }
            }
          });

          this.dialogRef.close();
        }
      });
  }

  handleEventOpenDialog(event) {
    if (event === 'update') {
      this.businessService.updateRuleRealTime(this.rule);
    }
    if (event === 'insert') {
      this.businessService.extendRules([this.rule], false);
    }
  }

  back() {
    this.businessService.getRuleConfig(this.businessId).subscribe((res) => {
      if (res.error == 0) {
        this.dialogRef.close({ event: 'back', data: { data: this.rule, rule: res.data } });
      }
    });
  }
}
