<div
  class="main-container"
  [ngClass]="
    isBusiness || isPortfolio || isAlepayCallback
      ? 'background-color-none'
      : 'background-image-brand'
  "
>
  <div class="app-container">
    <mat-toolbar *ngIf="isPortfolio && isSizeMobile" color="primary" class="sticky z-50 top-0">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon class="iconToggle" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{ businessName }}</span>
    </mat-toolbar>

    <mat-toolbar *ngIf="isBusiness && isSizeMobile" color="primary" class="sticky z-50 top-0">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon class="iconToggle" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{ businessName }}</span>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container" autosize>
      <mat-sidenav
        class="sidenav background-color-brand border-right-none"
        [class.mat-elevation-z0]="true"
        #drawer
        [mode]="optionSideNav.mode"
        [disableClose]="optionSideNav.isDisableClose"
        [opened]="optionSideNav.isOpened"
        [fixedInViewport]="optionSideNav.isFixed"
      >
        <div
          *ngIf="(isBusiness || isPortfolio) && !isSizeMobile"
          class="div-portfolio d-flex-row-around p-top-8"
          color="primary"
        >
          <ng-container *ngTemplateOutlet="headerLogo"></ng-container>
        </div>

        <app-sidebar-portfolio
          *ngIf="isPortfolio"
          [sidenav]="sidenav"
          [isMobile]="isSizeMobile"
          [isExpandedSideNav]="isExpandedSideNav"
          (logout)="logOut()"
        >
        </app-sidebar-portfolio>

        <app-sidebar-bussiness
          *ngIf="isBusiness"
          [business]="business"
          [menuList]="menuList"
          [businessId]="businessId"
          [businessName]="businessName"
          [businessLogo]="businessLogo"
          [sidenav]="sidenav"
          [isMobile]="isSizeMobile"
          [isExpandedSideNav]="isExpandedSideNav"
          [menuSelectedIndex]="menuSelectedIndex"
          (logout)="logOut()"
        >
        </app-sidebar-bussiness>
      </mat-sidenav>

      <mat-sidenav-content>
        <div
          class="mat-app-background basic-container relative"
          [ngClass]="{ 'px-6': !isSizeMobile, 'px-2': isSizeMobile }"
          #appContainer
        >
          <app-header
            [show]="showHeader"
            [businessName]="businessName"
            [businessLogo]="businessLogo"
            [business]="business"
            [isMobile]="isSizeMobile"
            [businessId]="businessId"
            (logout)="logOut()"
          ></app-header>
          <router-outlet></router-outlet>
          <app-footer
            [show]="showFooter"
            [version]="version"
            [buildDate]="buildDate"
            style="margin-top: auto"
          ></app-footer>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #headerLogo>
  <div *ngIf="isExpandedSideNav" class="flex flex-row items-center pl-8">
    <img
      class="h-4 pr-10 cursor-pointer"
      src="../../../assets/img/casso-logo-new.png"
      (click)="router.navigate(['/business', userService.getLoggedUser().business_id_lastest])"
      alt="logo casso.vn"
    />
  </div>
  <button class="!mx-2" mat-icon-button (click)="expandSideNavAndFitWidth()">
    <mat-icon>menu</mat-icon>
  </button>
</ng-template>
