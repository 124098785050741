<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs; let i = index; last as isLast">
    <span
      [ngClass]="{ active: isLast }"
      routerLink="{{ breadcrumbs | getLinkGoBack: i }}"
      [queryParams]="breadcrumbs | getLinkGoBack: i:'tab-index'"
      routerLinkActive="router-link-active"
    >
      {{ breadcrumb.label }}
    </span>
  </li>
</ol>
